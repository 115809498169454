import React from "react";
import ConnectedSettingsData from "./BandwidthTools/ConnectedSettingsData";
import LaunchApprovalTools from "./LaunchApproval/LaunchApprovalTools";
import { TextTools } from "./TextTools/TextTools";
import ScriptTools from "./ScriptTools/ScriptTools";

export const SystemAdminTools = ({ selectedCampaignData, userPermissions }) => {
	const isActiveCampaign = selectedCampaignData.active !== "deactivated";

	return (
		<div>
			{isActiveCampaign && userPermissions.includes("VIEW_CAMPAIGN_LAUNCH_APPROVAL_TOOLS") && (
				<>
					<LaunchApprovalTools
						active={selectedCampaignData.active}
						selectedCampaignData={selectedCampaignData}
					/>
					<hr />
				</>
			)}

			<TextTools
				campaignid={selectedCampaignData.campaignid}
				active={selectedCampaignData.active}
				assignOpenRecipients={selectedCampaignData.assign_open_recipients}
				recipientbatchsize={selectedCampaignData.recipientbatchsize}
				actionablethreshold={selectedCampaignData.actionablethreshold}
				limitNewConversationsPerHour={selectedCampaignData.limit_new_first_attempts_hourly}
				hourlyNewConversationLimit={selectedCampaignData.hourly_maximum_first_attempts}
				dailyTargetComplete={selectedCampaignData.daily_target_complete}
			/>

			<hr />

			<ScriptTools
				active={selectedCampaignData.active}
				partialScriptID={selectedCampaignData.partial_complete_script_id}
				partialAnswerList={selectedCampaignData.partial_complete_answer_list}
				script={selectedCampaignData.script}
			/>

			{isActiveCampaign && userPermissions.includes("VIEW_BANDWIDTH_REGISTRATION_TOOLS") && (
				<>
					<hr />
					<ConnectedSettingsData
						campaignid={selectedCampaignData.campaignid}
						pnpercopilot={selectedCampaignData.pnpercopilot}
						bw_subaccount_id={selectedCampaignData.bw_subaccount_id}
						bw_location_id={selectedCampaignData.bw_location_id}
						bw_registration_id={selectedCampaignData.bw_registration_id}
						has_unassigned_recipients={selectedCampaignData.has_unassigned_recipients}
						has_unregistered_outgoing_lines={selectedCampaignData.has_unregistered_outgoing_lines}
					/>
				</>
			)}
		</div>
	);
};
