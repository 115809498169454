import React from "react";

export const AddQuestionTypeSelectOverlay = ({ questionTypeList, addQuestionAndMakeListInvisible, qid }) => (
	<div style={{ position: "relative", display: "block" }}>
		<ul
			className="menu-list notification"
			style={{
				position: "absolute",
				"z-index": "999",
				display: "block",
				right: "1vw",
				border: "1px solid #8c8c8c",
				background: "#fff",
				padding: "6px 0",
				margin: "0",
			}}
		>
			{questionTypeList.map((item) => (
				<li value={item.value}>
					{item.value === "panel_close" ? (
						<a disabled value={item.value} className="disabled" id={`add-question-${item.value}-${qid}`}>
							{item.text}
						</a>
					) : (
						<a
							value={item.value}
							onClick={addQuestionAndMakeListInvisible}
							id={`add-question-${item.value}-${qid}`}
						>
							{item.text}
						</a>
					)}
				</li>
			))}
		</ul>
	</div>
);
