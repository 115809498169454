import React from "react";
import { getMediaList } from "../../redux/Media/actions";
import { withAsyncCall } from "../higher-order/AsyncWrapper";

/**
 * This is technically an empty component that is in charge of loading the
 * media objects for the campaign
 * @param {*} props
 * @returns
 */
export const MediaListContainer = (props) => null;

const mapStateToProps = (state) => ({
	media: state.media,
});

const retreiveMediaObjects = (campaignid) => (dispatch) => {
	dispatch(getMediaList(campaignid));
};

export default withAsyncCall(MediaListContainer, retreiveMediaObjects, mapStateToProps);
