import React, { useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getAnalyticsData, clearAnalyticsData } from "../../redux/Analytics/actions";

import AnalyticsOverview from "./AnalyticsOverview.component";

const AnalyticsOverviewContainer = ({ scope, data, campaignid, getAnalyticsData, loading, error }) => {
	useEffect(() => {
		getAnalyticsData("campaign", scope, campaignid);
	}, []);

	return <AnalyticsOverview data={data} loading={loading} error={error} />;
};

function mapStateToProps(state) {
	const data = state.analytics.current.data.campaign ? state.analytics.current.data.campaign[0] : null;
	if (data) {
		// Spend limit is stored in cents, but we want to display it in dollars
		data.spendLimit = parseFloat(state.selectedCampaign.selectedCampaign.selectedCampaignData.spendlimit) / 100;
		data.currentSpend = parseFloat(state.analytics.current.data.campaign[0].currentspend);
	}

	return {
		data,
		// Assume loading is true if the data is not present
		loading: state.analytics.current.loading || !data,
		error: state.analytics.current.error,
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAnalyticsData,
			clearAnalyticsData,
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsOverviewContainer);
