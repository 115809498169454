import { updateJWTToken } from "../redux/Auth/actions";
import { setCookie } from "./helper";

/**
 * We're using the BroadcastChannel to keep JWT access tokens synced up between tabs.
 *
 * Problem: An old tab with a very stale access token would not be recognized by the
 * API. The API would then (correctly) wipe out all JWT tokens and force that user to
 * log in again.
 *
 * Solution: Use the BroadcastChannel API to broadcast JWT access token updates to
 * all tabs. Any token that is different from what is currently saved in redux state
 * is saved. This should ensure that when an access token changes, that change is
 * reflected in all tabs.
 */

// For debugging, set a 10sec ttl for the jwt_token in restpoint/webtool.py (line ~835)
const debugMe = false;

// See: https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
const tokenChannel = new BroadcastChannel("access_token_channel");

// An id that will be unique for each tab/context (avoids sending messages to self)
const mySenderId = Math.random().toString(36).substring(2);

/**
 * Initialize the tokenChannel listener
 *
 * @param {object} store - The store is used to update the JWT token in redux.
 */
export const initializeTokenListener = (store) => {
	tokenChannel.onmessage = (event) => tokenChannelMessageHandler(event, store);

	// close the channel on tab close
	window.addEventListener("beforeunload", () => {
		closeTokenChannel();
	});

	debugMe && console.log(`BroadcastChannel listener initialized (mySenderId=${mySenderId.slice(-2)})`);
};

/**
 * Broadcasts a token update to the channel.
 *
 * @param {string} token - The token to be broadcasted.
 */
export const broadcastTokenUpdate = (newToken) => {
	tokenChannel.postMessage({
		token: newToken,
		senderId: mySenderId.slice(-2),
	});
};

// Handler for tokenChannel messages
function tokenChannelMessageHandler(event, store) {
	const { token: newToken, senderId } = event.data;

	// Ignore messages sent by self
	if (senderId === mySenderId) {
		return;
	}

	// Dispatch new tokens to redux and update cookie
	debugMe && console.log(`>>> Receiving token`, { token: newToken.slice(-6) });
	if (newToken) {
		const currentToken = store.getState().auth.jwt;
		if (newToken !== currentToken) {
			debugMe &&
				console.log(`... Saving new token`, {
					newToken: newToken.slice(-6),
					oldToken: currentToken.slice(-6),
				});
			store.dispatch(updateJWTToken(newToken));
			setCookie("jwt", newToken, { path: "/", expires: 1 });
		}
	}
}

// Debugging wrapper for BroadcastChannel.prototype.postMessage
const originalPostMessage = BroadcastChannel.prototype.postMessage;
BroadcastChannel.prototype.postMessage = function (message) {
	debugMe &&
		console.log(`<<< Broadcasting token`, {
			token: message.token.slice(-6),
		});
	originalPostMessage.call(this, message);
};

// Close the BroadcastChannel
const closeTokenChannel = () => {
	tokenChannel.close();
};
