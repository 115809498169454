import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { confirmUpload, cancelUpload } from "../../redux/Recipients/actions";
import { BulmaButton } from "../generic/Buttons/BulmaButton";

export const ConfirmUpload = ({
	selectedCampaignId,
	uploadID,
	confirmUploadCallback,
	cancelUploadCallback,
	cancelUpload,
	confirmUpload,
}) => (
	<>
		<BulmaButton
			customClass="is-success"
			onClick={() => confirmUploadCallback(selectedCampaignId, uploadID)}
			{...confirmUpload}
		>
			Confirm and complete list upload
		</BulmaButton>

		<BulmaButton
			customClass="is-danger"
			onClick={() => cancelUploadCallback(selectedCampaignId, uploadID)}
			{...cancelUpload}
		>
			Cancel
		</BulmaButton>
	</>
);

ConfirmUpload.propTypes = {
	change: PropTypes.func,
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			confirmUploadCallback: (campaignid, upload_id) => confirmUpload(campaignid, upload_id),
			cancelUploadCallback: (campaignid, upload_id) => cancelUpload(campaignid, upload_id),
		},
		dispatch,
	);

export default connect(undefined, mapDispatchToProps)(ConfirmUpload);
