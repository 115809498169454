import React, { Component } from "react";
import "../styles/all.css";

import Modal from "react-modal";
import AuthContainer from "../containers/AuthContainer";

class App extends Component {
	render() {
		return (
			<div className="app">
				<AuthContainer {...this.props} />
			</div>
		);
	}
}

Modal.setAppElement("body");

export default App;
