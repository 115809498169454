import { FETCH_ADD_ERROR_MSG, FETCH_CLEAR_ERROR_MESSAGE } from "../redux/FetchResponseManager/actions";

/**
 * Use to create standard behaviour for async actions
 */
export function loadStateReducer(actionStatuses) {
	return (state = { loading: false, error: false, data: null }, action) => {
		// The action types are mapped to the standard values of : "loading", "error", "success"
		// The type (USER_IS_LOADING) is the key and the above ("loading") are the values, so you can get
		// them with the code below (actionStatuses["USER_IS_LOADING"] returns "loading")
		const actionStatus = actionStatuses[action.type];
		switch (actionStatus) {
			case "loading":
				return {
					...state.data,
					loading: true,
					error: false,
					errorMessage: undefined,
					errorCode: undefined,
				};
			case "error":
				return {
					...state.data,
					loading: false,
					error: true,
					errorMessage: action.message,
					errorCode: action.status,
				};
			case "success":
				return {
					loading: false,
					error: false,
					errorMessage: undefined,
					errorCode: undefined,
					data: action.data,
				};
			default:
				return state;
		}
	};
}

/**
 * Reducer function creator that returns a reducer for adding error msg to a state

    usage could look like: 

    const msgReducer = createAddErrorMsgReducer("ADD_ERR_TO_GET_CAMPAIGN");
    const campaignReducerWithMessage = reduceReducers(campaignReducer, msgReducer);

 * @param {string} action_name The action that will trigger this reducer
 * @returns The new state
 */
export const createAddErrorMsgReducer =
	(requestName) =>
	(state = {}, action) => {
		if (requestName !== action.requestName) {
			return state;
		}

		switch (action.type) {
			case FETCH_ADD_ERROR_MSG:
				return {
					...state,
					errMsg: action.msg,
				};
			case FETCH_CLEAR_ERROR_MESSAGE:
				return {
					...state,
					errMsg: undefined,
				};
			default:
				return state;
		}
	};
