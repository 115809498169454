export const BANDWIDTH_SETTINGS_STATES = {
	BLANK: "BLANK",
	REGISTRATION_AVAILABLE: "REGISTRATION_AVAILABLE",
	REGISTRATION_PENDING: "REGISTRATION_PENDING",
	REGISTRATION_READY: "REGISTRATION_READY",
	ACCOUNTS_CREATED: "ACCOUNTS_CREATED",
	NUMBER_PURCHASE_PENDING: "NUMBER_PURCHASE_PENDING",
	NUMBER_PURCHASE_COMPLETE: "NUMBER_PURCHASE_COMPLETE",
	NUMBER_REG_PENDING: "NUMBER_REG_PENDING",
	NUMBER_REG_COMPLETE: "NUMBER_REG_COMPLETE",

	REGISTRATION_AVAILABLE_PARTIAL: "REGISTRATION_AVAILABLE_PARTIAL",
	REGISTRATION_CAMPAIGN_UNLINKED: "REGISTRATION_CAMPAIGN_UNLINKED",
	REGISTRATION_CAMPAIGN_ACTIVE: "REGISTRATION_CAMPAIGN_ACTIVE",

	NUMBER_UNREG_PENDING: "NUMBER_UNREG_PENDING",
	NUMBER_UNREG_COMPLETE: "NUMBER_UNREG_COMPLETE",
	NUMBER_RELEASE_PENDING: "NUMBER_RELEASE_PENDING",
	NUMBER_RELEASE_COMPLETE: "NUMBER_RELEASE_COMPLETE",
	REG_REMOVE_PENDING: "REG_REMOVE_PENDING",
	ERROR: "ERROR",
};

export const USER_ROLES_LOOKUP_TABLE = [
	"SYSTEM_ADMIN",
	"ORGANIZATION_ADMIN",
	"CAMPAIGN_ADMIN",
	"SENDER",
	"CALL_CENTER_ADMIN",
];

export const USER_ROLES = {
	systemAdmin: USER_ROLES_LOOKUP_TABLE[0],
	organizationAdmin: USER_ROLES_LOOKUP_TABLE[1],
	campaignAdmin: USER_ROLES_LOOKUP_TABLE[2],
	sender: USER_ROLES_LOOKUP_TABLE[3],
	callCenterAdmin: USER_ROLES_LOOKUP_TABLE[4],
};

export const analyticsEmpty = {
	name: "",

	//conversations
	open: 0,
	closed: 0,
	assigned: 0,

	// # of contacts
	contact: 0,

	start: 0, // Got first text
	engaged: 0, // Responded
	terminated: 0, // Quit or self terminated
	completed: 0, // Received final text

	// Dates
	firstsms: "-",
	lastsms: "-",

	// Error counts by type
	4700: 0,
	4720: 0,
	4750: 0,
	4770: 0,
	percentstarted: "0%",
	percentcomplete: "0%",
	percentcompleteengaged: "0%",

	minutes: 0,
	currentspend: 0,
	outgoing_segments: 0,
	incoming_segments: 0,
	sent_messages: 0,
	total_segments: 0,
	text_costs: 0,
	agent_hour_costs: 0,
	lastsmsoutgoing: "-",
	lastsmsincoming: "-",
	smsperagenthour: 0,

	optedin: 0,
	optedout: 0,
};

export const NGROK_ENABLED = process.env.REACT_APP_NGROK_ENABLED;
export const SERVER_URLROOT = NGROK_ENABLED ? "https://[your-tunnel-domain]" : process.env.REACT_APP_S160_API_BASEURL;
export const DEFAULT_TWILIOSID = process.env.REACT_APP_TWILIO_SID;
export const DEFAULT_TWILIOTOKEN = process.env.REACT_APP_TWILIO_TOKEN;

// TODO: Check if this is used
export const DEFAULT_CAMPAIGN_DATA = {
	twiliosid: DEFAULT_TWILIOSID,
	twiliotoken: DEFAULT_TWILIOTOKEN,
	numberofcopilots: 0,
	pnpercopilot: 0,
	costperagenthour: 0,
	totalagenthours: 0,
	smssurcharge: 0,
};

export const PANEL_QUESTION_IDS = ["panel_opt_in", "panel_close", "panel_refuse"];

export const ANALYTICS_ERROR_CODE_LIST = ["4700", "4720", "4750", "4770"];

export const UPLOAD_STATUS_MAP = {
	NOTSTARTED: "notStarted",
	PROCESSING: "processing",
	PROCESSING_COMPLETE: "processing_complete",
	IMPORTING_PROCESSED: "importing_processed",
	COMPLETE: "complete",
	FAILED: "failed",
};

export const UPLOAD_STATUS_VALUE_COLOR_MAP = {
	[UPLOAD_STATUS_MAP.NOTSTARTED]: { value: 0, color: "light" },
	[UPLOAD_STATUS_MAP.PROCESSING]: { value: 1, color: "link" },
	[UPLOAD_STATUS_MAP.PROCESSING_COMPLETE]: { value: 2, color: "warning" },
	[UPLOAD_STATUS_MAP.IMPORTING_PROCESSED]: { value: 3, color: "primary" },
	[UPLOAD_STATUS_MAP.COMPLETE]: { value: 4, color: "success" },
	[UPLOAD_STATUS_MAP.FAILED]: { value: 0, color: "error" },
};

// These debug checkboxes will be removed before the full PR is made, but leaving them here for testing purposes
export const QUOTAS_DEBUG_CHECKBOXES_1 = [
	{
		name: "clearoutphonecarrier",
		value: false,
	},
	{
		name: "clearoutphonevalidationstatus",
		value: false,
	},
	{
		name: "clearoutphonelinetype",
		value: false,
	},
	{
		name: "clearoutphonelocation",
		value: false,
	},
	{
		name: "clearoutphonecountryname",
		value: false,
	},
	{
		name: "clearoutphonecountrytimezone",
		value: false,
	},
	{
		name: "clearoutphonecountrycode",
		value: false,
	},
	{
		name: "clearoutphoneinternationalformat",
		value: false,
	},
	{
		name: "clearoutphonelocalformat",
		value: false,
	},
	{
		name: "clearoutphonee164format",
		value: false,
	},
	{
		name: "clearoutphonecanbeinternationall",
		value: false,
	},
	{
		name: "clearoutphonevalidatedatutc",
		value: false,
	},
	{
		name: "clearoutphonetimetakenms",
		value: false,
	},
	{
		name: "v16",
		value: false,
	},
	{
		name: "carrier",
		value: false,
	},
	{
		name: "_merge",
		value: false,
	},
	{
		name: "length",
		value: false,
	},
	{
		name: "name",
		value: false,
	},
	{
		name: "opt_out_reason_num",
		value: false,
	},
	{
		name: "opt_out_reason",
		value: false,
	},
	{
		name: "reason_rand",
		value: false,
	},
	{
		name: "reason_num",
		value: false,
	},
	{
		name: "intro_timing_rand",
		value: false,
	},
	{
		name: "intro_timing_num",
		value: false,
	},
	{
		name: "intro_timing",
		value: false,
	},
	{
		name: "state",
		value: false,
	},
	{
		name: "rid",
		value: false,
	},
	{
		name: "sid",
		value: false,
	},
	{
		name: "opt_out_reason_ra",
		value: false,
	},
	{
		name: "reason",
		value: false,
	},
	{
		name: "quota_group",
		value: false,
	},
];
export const QUOTAS_DEBUG_CHECKBOXES_2 = [
	{
		name: "survey_id",
		value: false,
	},
	{
		name: "voterbase_id",
		value: false,
	},
	{
		name: "phone",
		value: false,
	},
	{
		name: "first_name",
		value: false,
	},
	{
		name: "last_name",
		value: false,
	},
	{
		name: "suffix",
		value: false,
	},
	{
		name: "phone_type",
		value: false,
	},
	{
		name: "quota_1",
		value: false,
	},
	{
		name: "quota_2",
		value: false,
	},
	{
		name: "quota_3",
		value: false,
	},
	{
		name: "quota_4",
		value: false,
	},
	{
		name: "quota_5",
		value: false,
	},
	{
		name: "quota_6",
		value: false,
	},
	{
		name: "split_1",
		value: false,
	},
	{
		name: "split_2",
		value: false,
	},
	{
		name: "split_3",
		value: false,
	},
	{
		name: "language",
		value: false,
	},
	{
		name: "vendor",
		value: false,
	},
	{
		name: "url",
		value: false,
	},
	{
		name: "rand",
		value: false,
	},
	{
		name: "id",
		value: false,
	},
	{
		name: "dup_phone",
		value: false,
	},
];
