import { sortByObjectField } from "../../util/sortFunctions";

// The checkboxes are from the headers.
export const HEADERS = [
	{ header: "Name", title: "Name", accessor: "name" },
	{
		header: "Completes (Text)",
		title: "# Sent Final Text",
		accessor: "closed",
	},
	{
		header: "Completes (Web)",
		title: "# Web Completes Received",
		accessor: "web_closed",
	},
	{ header: "Agent Hours", title: "# Agent Hours", accessor: "agentHours" },
	{ header: "Attempted", title: "# Sent First Text", accessor: "start" },
	{
		header: "Total Segments",
		title: "# of total Segments",
		accessor: "total_segments",
	},
	{
		header: "# Sent Segments",
		title: "# of Segments sent",
		accessor: "outgoing_segments",
	},
	{
		header: "Agent hour costs",
		title: "Agent hour related costs total",
		accessor: "agent_hour_costs",
	},
	{ header: "Open", title: "Open Conversations", accessor: "open" },
	{ header: "Assigned", title: "Assigned", accessor: "assigned" },
	{ header: "Total Recipients", title: "# of Contacts", accessor: "contact" },
	{
		header: "Engaged",
		title: "# Responded to First Text",
		accessor: "engaged",
	},
	{
		header: "Terminated",
		title: "# Quit or Self Terminated",
		accessor: "terminated",
	},
	{ header: "4700 Error", title: "# of 4700 Errors", accessor: "4700" },
	{ header: "4720 Error", title: "# of 4720 Errors", accessor: "4720" },
	{ header: "4750 Error", title: "# of 4750 Errors", accessor: "4750" },
	{ header: "4770 Error", title: "# of 4770 Errors", accessor: "4770" },
	{
		header: "Text costs",
		title: "Text related costs total",
		accessor: "text_costs",
	},
	{ header: "Total Spend", title: "Total Spend", accessor: "currentspend" },
	{
		header: "% Strt",
		title: "% Sent First Text",
		accessor: "percentstarted",
	},
	{
		header: "% Complete (text)",
		title: "% Sent Final Text",
		accessor: "percentcomplete",
	},
	{
		header: "% Complete (web)",
		title: "% Received Web Confirmation",
		accessor: "percentwebcomplete",
	},
	{
		header: "% Converted (text > web)",
		title: "% Converted from Text to Web",
		accessor: "percentt2wconversion",
	},
	{
		header: "% Comp Eng",
		title: "% Responded and Received Final Text",
		accessor: "percentcompleteengaged",
	},
	{ header: "% Engage", title: "% Engage", accessor: "percentengaged" },
	{
		header: "% Terminate",
		title: "% Terminate",
		accessor: "percentterminated",
	},
	{
		header: "% 4770",
		title: "% 4770 errors of started",
		accessor: "percent4770",
	},
	{
		header: "# Received segments",
		title: "# of Segments received",
		accessor: "incoming_segments",
	},
	{ header: "1st SMS Sent", title: "First SMS Date", accessor: "firstsms" },
	{ header: "Last SMS Sent", title: "Last SMS Date", accessor: "lastsms" },
	{
		header: "Last SMS sent",
		title: "Last SMS sent",
		accessor: "lastsmsoutgoing",
	},
	{
		header: "Last SMS received",
		title: "Last SMS received",
		accessor: "lastsmsincoming",
	},
	{
		header: "# Sent SMS",
		title: "# of Texts sent",
		accessor: "sent_messages",
	},
	{
		header: "SMS / Agent Hour",
		title: "Sms sent per agent hour",
		accessor: "smsperagenthour",
	},
	{
		header: "Cost / complete",
		title: "Cost per complete",
		accessor: "cost_per_complete",
	},
	{
		header: "4700 / start",
		title: "4700 errors per started",
		accessor: "4700_per_started",
	},
	{
		header: "4720 / start",
		title: "4720 errors per started",
		accessor: "4720_per_started",
	},
	{
		header: "4750 / start",
		title: "4750 errors per started",
		accessor: "4750_per_started",
	},
	{
		header: "4770 / start",
		title: "4770 errors per started",
		accessor: "4770_per_started",
	},
	{
		header: "4770 / complete",
		title: "4770 errors per complete",
		accessor: "4770_per_complete",
	},
	{ header: "# Opted in", title: "# Opted in", accessor: "optedin" },
	{
		header: "# Permanent opt-outs",
		title: "# Permanent opt-outs",
		accessor: "optedout",
	},
];

export const CAMPAIGNID_HEADER = {
	header: "ID",
	title: "Campaign ID",
	accessor: "campaignid",
	sort: sortByObjectField("campaignid", false),
};

export const CHECKBOX_VISIBILITY_TABLE_DEFAULTS = {
	name: true,
	open: true,
	assigned: true,
	contact: true,
	start: true,
	engaged: true,
	terminated: true,
	closed: true,
	web_closed: true,
	percentstarted: true,
	percentcomplete: true,
	percentwebcomplete: true,
	percentcompleteengaged: true,
	firstsms: true,
	lastsms: true,
	agenthours: true,
	currentspend: true,
	4700: true,
	4720: true,
	4750: true,
	4770: true,
	outgoing: true,
	incoming: true,
	total_segments: true,
	text_costs: true,
	agent_hour_costs: true,
	lastsmsoutgoing: true,
	lastsmsincoming: true,
	smsperagenthour: true,
	cost_per_complete: true,
	optedin: true,
	optedout: true,
};

export const CHECKBOX_ROWS_PER_COLUMN = 8;

export const ANALYTICS_TAB_VALUES = [
	{ name: "campaigns", dataName: "campaign" },
	{ name: "organization", dataName: "organization" },
	{ name: "users", dataName: "user" },
];
