import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalContainer, { ModalBody, ModalHeader, ModalFooter } from "./ModalContainer";

import { renameOrganization } from "../../actions/vitals";

class RenameOrganizationModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newName: "",
			valid: true,
		};

		this.onChange = this.onChange.bind(this);
	}

	validateName(newName, organizations, id) {
		var valid = true;

		// Empty string is invalid
		if (newName === "") {
			valid = false;
		}

		// Check for identical name in campaigns
		organizations.map((org) => {
			if (newName === org.organizationname && id !== org.organizationid) {
				valid = false;
			}
		});

		return valid;
	}

	onChange(e) {
		this.setState({
			valid: this.validateName(e.target.value, this.props.organizations, this.props.id),
			newName: e.target.value,
		});
	}

	render() {
		const { id, loading, buttonClass } = this.props;

		return (
			<ModalContainer name="Rename Organization" customButtonClass={buttonClass}>
				{(modalState) => (
					<React.Fragment>
						<ModalHeader>Rename Organization</ModalHeader>
						<ModalBody>
							<div className="field">
								<label className="label">New Name</label>
								<div className="control">
									<input
										className="input"
										type="text"
										onChange={this.onChange}
										value={this.state.newName}
									/>
								</div>
								<p className={"help" + (this.state.valid ? " is-success" : " is-danger")}>
									{this.state.valid ? "OK" : "invalid name"}
								</p>
							</div>
						</ModalBody>
						<ModalFooter {...modalState}>
							<a
								className={"button is-info" + (loading ? " is-loading" : "")}
								onClick={() => {
									this.props
										.renameOrganization(this.props.id, this.state.newName)
										.then(() => this.props.vitalsDataPost());
									modalState.toggleOpen();
								}}
							>
								Confirm
							</a>
						</ModalFooter>
					</React.Fragment>
				)}
			</ModalContainer>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			renameOrganization: (orgid, newName) => renameOrganization(orgid, newName),
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(RenameOrganizationModal);
