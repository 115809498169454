import { TOGGLE_SIDEBAR } from "../actions/sidebar";

const initialState = {
	open: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return {
				...state,
				open: !state.open,
			};

		default:
			return state;
	}
};
