import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { copyCampaign } from "../../actions/NAID";
import ModalContainer, { ModalBody, ModalHeader, ModalFooter } from "./ModalContainer";

/**
 * Copy Campaign Modal
 * Modal for the options associated to copying the campaign.
 * The copy campaign modal receives the following props:

 * open {bool}        - Whether the modal is visible or not.
 * toggleOpen {function} toggles the modal open/closed
 * copyCampaign            - To copy the campaign.
 */
class CopyCampaignModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: this.props.initOpen || false,
			loading: false,
		};
		this.toggleOpen = this.toggleOpen.bind(this);
		this.copyCampaign = this.copyCampaign.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	toggleOpen() {
		this.setState({ open: !this.state.open });
	}
	copyCampaign(toggleOpen) {
		this.props
			.copyCampaign(
				this.copyScript.checked,
				this.copyPrompt.checked,
				this.copyList.checked,
				this.moveToOrg.checked ? parseInt(this.selectedOrganization.value) : undefined,
			)
			.then(() => {
				toggleOpen();
			});

		this.setState({ loading: true });
	}

	onChange(e) {
		this.setState({
			moveToOrg: this.moveToOrg.checked,
		});
	}

	render() {
		const { campaignName, buttonClass, organizations } = this.props;
		return (
			<ModalContainer name={"Copy"} customButtonClass={buttonClass}>
				{(modalState) => (
					<React.Fragment>
						<ModalHeader>
							Copy Campaign: &nbsp;<strong>{campaignName}</strong>
						</ModalHeader>
						<ModalBody>
							<div className="field">
								<label className="checkbox">
									<input
										name="copyScript"
										type="checkbox"
										ref={(copyScript) => {
											this.copyScript = copyScript;
										}}
									/>
									&nbsp;Copy Script
								</label>
							</div>
							<div className="field">
								<label className="checkbox">
									<input
										name="copyPrompt"
										type="checkbox"
										ref={(copyPrompt) => {
											this.copyPrompt = copyPrompt;
										}}
									/>
									&nbsp;Copy Prompts
								</label>
							</div>
							<div className="field">
								<label className="checkbox">
									<input
										name="copyList"
										type="checkbox"
										ref={(copyList) => {
											this.copyList = copyList;
										}}
									/>
									&nbsp;Copy Phone List
								</label>
							</div>
							<div className="field">
								<label className="checkbox">
									<input
										name="moveToOrg"
										type="checkbox"
										onChange={this.onChange}
										ref={(moveToOrg) => {
											this.moveToOrg = moveToOrg;
										}}
									/>
									&nbsp;Change Organization
								</label>
							</div>
							{this.moveToOrg && this.moveToOrg.checked && (
								<select ref={(input) => (this.selectedOrganization = input)}>
									{organizations.map((item, ind) => (
										<option key={ind} value={item.organizationid}>
											{item.organizationname}
										</option>
									))}
								</select>
							)}
						</ModalBody>
						<ModalFooter {...modalState}>
							<a
								className={"button is-info " + (this.state.loading && "is-loading")}
								onClick={() => this.copyCampaign(modalState.toggleOpen)}
							>
								Copy
							</a>
						</ModalFooter>
					</React.Fragment>
				)}
			</ModalContainer>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			copyCampaign: (copyScript, copyPrompt, copyList, newOrgId) =>
				copyCampaign(copyScript, copyPrompt, copyList, newOrgId),
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(CopyCampaignModal);
