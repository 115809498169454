import React, { Component } from "react";
import { faWarning, faExclamationTriangle, faExclamationCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import List from "../List";

const headers = [
	{
		header: "",
		title: "",
		accessor: "icon",
	},
	{
		header: "Notifications",
		title: "",
		accessor: "description",
	},
	{
		header: "Date and Time",
		title: "",
		accessor: "timestamp",
	},
];

const icons = {
	warning: {
		icon: faExclamationTriangle,
		color: "warning",
		value: 1,
	},
	info: {
		icon: faInfoCircle,
		color: "primary",
		value: 0,
	},
	error: {
		icon: faExclamationCircle,
		color: "danger",
		value: 2,
	},
};

class Warnings extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	static getDerivedStateFromProps(props, state) {
		const { loading, data } = props.warnings;
		if (!loading && data && !state.data) {
			return {
				warnings: data
					.filter((item) => item.status === "active")
					.sort((a, b) => icons[b.type].value - icons[a.type].value)
					.map((item) => ({
						...item,
						icon: (
							<span className={`has-text-${icons[item.type].color}`}>
								<FontAwesomeIcon icon={icons[item.type].icon} />
							</span>
						),
					})),
			};
		}

		return null;
	}

	render() {
		const { warnings } = this.state;
		const { loading, error } = this.props.warnings;
		console.log(warnings);
		return <div>{!loading && !error && warnings && <List headers={headers} list={warnings} />}</div>;
	}
}

export default Warnings;
