import { SERVER_URLROOT } from "./constants";
import FileSaver from "file-saver";

export function createDebouncer(waitTime) {
	return (callback) => {
		let timer = null;
		return function () {
			clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				callback.apply(this, arguments);
			}, waitTime);
		};
	};
}

export function deepCopy(a) {
	return JSON.parse(JSON.stringify(a));
}

// TODO: remove this if not used (we should be using dollars everywhere now)
/**
 * Turns integer cents into float dollars
 * @param {int} value cent value
 */
export function centsToDollars(value) {
	return parseFloat(value) / 100;
}
/**
 * Turns float dollars into int cents
 * @param {float} value dollar value
 */
export function dollarsToCents(value) {
	return parseInt(value * 100);
}
/**
 * Turns floats and dollars into currency format
 * @param {number} value dollar value
 */
export function asUsd(value) {
	if (value === null || value === undefined || isNaN(value)) {
		return "$0.00";
	}
	return value.toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	});
}
/**
 * Turns integer millicents into float dollars with 0.001 level of precision
 * @param {int} value millicent value
 */
export function asUsdMillicents(value) {
	if (value === null || value === undefined || isNaN(value)) {
		return "$0.000";
	}
	return `\$${parseFloat(value).toFixed(3)}`;
}
/**
 * Turns integer into a string with commas and (optionally) 2 decimal places
 * @param {int} value any number (or null/undefined/NaN)
 * @param {boolean} forceDecimals force 2 decimal places
 * @returns {string} string representation of number (eg 1,000 or 200.00)
 */
export function asFormattedNumber(value, forceDecimals = false) {
	let num = value;
	let minDecimals = forceDecimals ? 2 : 0;
	if (value === null || value === undefined || isNaN(value)) {
		num = 0;
	}
	return num.toLocaleString("en-US", {
		minimumFractionDigits: minDecimals,
		maximumFractionDigits: 2,
	});
}

// It might be fun to run this on keystroke and give instant feedback
export function validateNameString(newName, campaigns, campaignid) {
	var valid = true;
	// Empty string is invalid
	if (newName === "") {
		valid = false;
	}

	// Check for identical name in campaigns
	campaigns.map(function (h, i) {
		if (newName === h.name && campaignid != h.campaignid) {
			valid = false;
		}
	});

	return valid;
}

export function createPassword() {
	var text = "";
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	for (var i = 0; i < 10; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}

export function filterSample(s, misc) {
	Object.keys(misc).map(function (ky, i) {
		var re = new RegExp("{" + ky + "}", "g");
		s = s.replace(re, misc[ky]);
	});
	return s;
}

// Put in util.
// Use this here and in DCMContainer when the fields are blank
export function getCookies() {
	//console.log('getting cookies');
	var h = {};
	var j = document.cookie.split("; ").map(function (cook) {
		var k = cook.split("=");
		h[k[0]] = k[1];
	});
	if (h.jwt === undefined || h.jwt === "") {
		h.jwt = "crap";
	}
	if (h.userid === undefined || h.userid === "") {
		h.userid = "crap";
	}
	return h;
}

export function setCookie(name, value, days) {
	let expires = "";
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
	document.cookie = name + "=" + (value || "") + expires + "; path=/dashboard/"; // why are we doing this?
}

export function removeCookie(name) {
	document.cookie = name + "=; expires=" + new Date(0).toUTCString() + "; path=/;";
	document.cookie = name + "=; expires=" + new Date(0).toUTCString() + "; path=/dashboard/;";
}

export function removeCookieList(list) {
	//console.log("Remove Cookies List");

	list.forEach((item) => {
		removeCookie(item);
	});
}

export function removeCookies() {
	document.cookie = "jwt=; expires=" + new Date(0).toUTCString() + "; path=/;";
	document.cookie = "userid=; expires=" + new Date(0).toUTCString() + "; path=/;";

	document.cookie = "jwt=; expires=" + new Date(0).toUTCString() + "; path=/dashboard/;";
	document.cookie = "userid=; expires=" + new Date(0).toUTCString() + "; path=/dashboard/;";
}

export function getPercentValue(total, value) {
	const result = (value / total) * 100;
	return isNaN(result) ? 0 : result;
}

export function downloadFile(filename, data) {
	const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
	FileSaver.saveAs(blob, filename);
}

export function createJSONFetchRequest(url, params = {}, method = "POST", jwt) {
	const myHeaders = new Headers();

	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("accept", "application/json");
	myHeaders.append("Authorization", jwt);

	const init = {
		method: method,
		headers: myHeaders,
		body: JSON.stringify(params),
	};

	return fetch(SERVER_URLROOT + url, init);
}

/**
 * Not really a validation. Just tells us if string has the email format.
 * @param {String} email
 */
export function isEmail(email) {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

/**
 * Matches clean phone numbers with no symbbols
 * @param {string} phoneStr
 * @returns True if matches
 */
export function isCleanPhone(phoneStr) {
	phoneStr = phoneStr.replace(" ", "");
	if (phoneStr.length > 10) {
		return false;
	}
	const re = /^(\d{10})/;
	return re.test(String(phoneStr));
}

/**
 * Simple wrapper to return get a FETCH request creator, without executing.
 * The request can then be run by calling the returned function.
 *
 * @param {string} url
 * @param {object} params
 * @returns {function} The promise creator for the request
 */
export const getJSONRequestCreator = (url, params) => () => createJSONFetchRequest(url, params);

/**
 * Executes a list of requests one at the time.
 * @param {array} requestData List of {} containing the request data
 * @returns {boolean} Did the requests go out without a hitch.
 */
export const executeAsyncActionSequence = async (actions) => {
	let smooth = true;

	actions.forEach(async (a) => {
		await a().catch((e) => {
			console.error(e);
			smooth = false;
		});
	});

	return smooth;
};

/**
 * Executes a list of requests one at the time.
 * @param {array} requestData List of {} containing the request data
 * @returns {boolean} Did the requests go out without a hitch.
 */
export async function executeRequestSequence(requestData) {
	let smooth = true;
	for (const data in requestData) {
		await createJSONFetchRequest(data.url, data.params).catch((e) => {
			console.error(e);
			smooth = false;
		});
	}

	return smooth;
}

/**
 * Sort function for name property of items, with simple
 * string comparison.
 * @param {*} a
 * @param {*} b
 * @returns 1, 0, -1 for order of items
 */
export function sortObjByName(a, b) {
	if (a.name < b.name) {
		return -1;
	}
	if (a.name > b.name) {
		return 1;
	}
	return 0;
}

/**
 * Determines the readiness states of a selected campaign.
 * @param {Object} selectedCampaignData - An object containing campaign data (script, login, & listlength at minimum).
 * @returns {Array} - An array indicating whether the selected campaign is ready for "launch", "sandbox", or neither.
 */
export function campaignReadyStates(selectedCampaignData) {
	const { script, login, listlength } = selectedCampaignData;
	let campaignReady = [];

	if (script && script.length > 0 && login && login.length > 0) {
		campaignReady.push("sandbox");
	}
	if (campaignReady.includes("sandbox") && listlength) {
		campaignReady.push("launch");
	}
	return campaignReady;
}
