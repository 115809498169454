import React, { Component } from "react";
import PropTypes from "prop-types";
import UpdateCampaignCostsModal from "../modals/UpdateCampaignCostsModal";
import { asUsd, asUsdMillicents, asFormattedNumber } from "../../util/helper";

class Billing extends Component {
	constructor(props) {
		super(props);
		// see PropTypes below
	}

	render() {
		const { billingData } = this.props;
		const campaignIsClosed = this.props.selectedCampaignData.active === "deactivated";
		let hasEditBillingPermission = this.props.userPermissions.includes("EDIT_BILLING_ALL_FIELDS");
		let campaignSpendStatus;
		if (billingData) {
			campaignSpendStatus =
				billingData.campaignCurrentTotalSpend > billingData.campaignSpendLimit ? "is-danger" : "is-success";
		}

		return (
			<div class="">
				{!campaignIsClosed && (
					<div class="is-full">
						<div className="buttons is-left">
							{!this.props.loading && billingData && (
								<UpdateCampaignCostsModal
									campaignStatus={this.props.selectedCampaignData.active}
									smssurcharge={billingData.smsDollarsPerSegment}
									campaigncosts={billingData.campaignCharges}
									spendlimit={billingData.campaignSpendLimit}
									totalagenthours={billingData.agentHoursTotalHours}
									costperagenthour={billingData.agentHoursCostPerHour}
									campaignid={this.props.selectedCampaignData.campaignid}
									userPermissions={this.props.userPermissions}
									buttonClass="is-info"
									buttonText="Edit Billing"
									buttonIsDisabled={!hasEditBillingPermission}
								/>
							)}
						</div>
					</div>
				)}

				<div class="content columns">
					<div class="mt-5 column is-one-third">
						<h3 className="is-4 ">Campaign Status</h3>
						{this.props.loading && <div>Loading...</div>}
						{!this.props.loading && billingData && (
							<div className="billing-status">
								<dl className="billing-status-text">
									<dt className="label">Spend Limit:</dt>
									<dd>{asUsd(billingData.campaignSpendLimit)}</dd>
									<dt className="label">Current Spend:</dt>
									<dd>{asUsd(billingData.campaignCurrentTotalSpend)}</dd>
								</dl>
								<progress
									class={`progress ${campaignSpendStatus}`}
									value={billingData.campaignCurrentTotalSpend}
									max={billingData.campaignSpendLimit}
								></progress>
							</div>
						)}
					</div>

					<div className="mt-5 column is-two-thirds">
						<h3 className="is-4 ">Campaign Costs</h3>
						{this.props.loading && <div className="tile is-child">Loading...</div>}
						{!this.props.loading && billingData && (
							<table className="table is-full is-hoverable billing-table has-text-right">
								<thead>
									<tr>
										<th className="has-text-left">Description</th>
										<th>Quantity</th>
										<th>Cost</th>
										<th>Sub-Total</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="has-text-left">Agent Hours</td>
										<td className="has-text-center">
											{asFormattedNumber(billingData.agentHoursTotalHours, true)}
										</td>
										<td>{asUsd(billingData.agentHoursCostPerHour)}</td>
										<td>{asUsd(billingData.agentHoursTotalCost)}</td>
									</tr>
									<tr>
										<td className="has-text-left">Total SMS Segments</td>
										<td className="has-text-center">
											{asFormattedNumber(billingData.smsTotalSegments)}
										</td>
										<td>{asUsdMillicents(billingData.smsDollarsPerSegment)}</td>
										<td>{asUsd(billingData.smsTotalCost)}</td>
									</tr>

									{(billingData.campaignCharges || []).map((item, index) => (
										<tr key={index}>
											<td className="has-text-left">{item.description}</td>
											<td></td>
											<td>{asUsd(item.charge)}</td>
											<td>{asUsd(item.charge)}</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td className="has-text-right">
											<strong>Total:</strong>
										</td>
										<td className="has-text-right">
											<strong>{asUsd(billingData.campaignCurrentTotalSpend)}</strong>
										</td>
									</tr>
								</tfoot>
							</table>
						)}
					</div>
				</div>
			</div>
		);
	}
}

Billing.propTypes = {
	loading: PropTypes.bool,
	billingData: PropTypes.object,
	userPermissions: PropTypes.array,
	selectedCampaignData: PropTypes.object.isRequired,
};

export default Billing;
