import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updateTextSettings } from "../../redux/SelectedCampaign/actions";
import ModalContainerFree, { ModalBody, ModalFooter, ModalHeader } from "../modals/ModalContainerFree";

class AddPhoneLinesModal extends Component {
	state = {
		loading: false,
		numberOfLines: 20,
	};

	linkNewLines = (toggleOpen, registrationId) => {
		if (this.state.loading) return;

		this.setState({ error: "", loading: true });
		console.log("id:", registrationId, "state:", this.state);
		this.props
			.linkNewLines(registrationId, this.state.numberOfLines)
			.then((result) => {
				toggleOpen();
				this.setState({
					dirty: false,
					loading: false,
				});
			})
			.catch((err) => {
				this.setState({
					error: "Could not complete operation",
					loading: false,
				});
			});
	};

	onChangeField = (e) => {
		console.log("va", e.target.value);
		this.setState({
			numberOfLines: e.target.value,
			dirty: true,
		});
	};

	toggleOpen = () => {
		this.props.closeAddLinesModal();
	};

	render() {
		const { registrationId, open } = this.props;
		return (
			<ModalContainerFree
				open={open}
				toggleModal={this.toggleOpen}
				name="+ Add Phones"
				customButtonClass={"button is-link"}
			>
				{(modalState) => (
					<React.Fragment>
						<ModalHeader>Link New Lines</ModalHeader>
						<ModalBody>
							<div className="field">
								<label className="label">
									How many new lines do you want to purchase for registration <b>{registrationId}</b>?
								</label>
								<div className="control">
									<input
										className="input"
										type="number"
										name="numberOfLines"
										onBlur={this.onChangeField}
										defaultValue={20}
									/>
								</div>
								<p className={"help is-danger"}>{this.state.error}</p>
							</div>
						</ModalBody>
						<ModalFooter {...modalState}>
							<button
								className={"button is-info " + (this.state.loading && "is-loading")}
								disabled={this.state.loading}
								onClick={() => this.linkNewLines(modalState.toggleOpen, registrationId)}
							>
								Submit
							</button>
						</ModalFooter>
					</React.Fragment>
				)}
			</ModalContainerFree>
		);
	}
}
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateTextSettings: (campaignid, batchAmount) => updateTextSettings(campaignid, batchAmount),
		},
		dispatch,
	);
export default connect(null, mapDispatchToProps)(AddPhoneLinesModal);
