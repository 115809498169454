import {
	NAID_POST_SUCCESS,
	NAID_HAS_ERROR,
	NAID_IS_LOADING,
	SET_SELECTED_CAMPAIGN_INDEX,
	UPDATE_CAMPAIGN_NAME,
	UPDATE_CAMPAIGN_STATUS,
	DELETE_CAMPAIGN,
	ADD_CAMPAIGN,
} from "../actions/NAID";

const initialState = {
	loading: false,
	error: false,
	selectedCampaignIndex: 0,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case NAID_IS_LOADING:
			return {
				...state,
				loading: true,
			};
		case NAID_HAS_ERROR:
			return {
				...state,
				loading: false,
				error: true,
			};
		case NAID_POST_SUCCESS:
			const campaigns = action.data.data;
			const selectedCampaignIndex = campaigns.length ? campaigns[0].campaignid : 0;
			return {
				...state,
				loading: false,
				error: false,
				campaigns: [...campaigns],
				selectedCampaignIndex: selectedCampaignIndex,
			};
		case SET_SELECTED_CAMPAIGN_INDEX:
			return {
				...state,
				selectedCampaignIndex: action.id,
			};
		case ADD_CAMPAIGN: {
			const newCampaigns = [...state.campaigns, action.campaign];
			return {
				...state,
				campaigns: newCampaigns,
				// Works because the new campaigns doesn't exist
				selectedCampaignIndex: action.campaign.campaignid,
			};
		}
		case "ADD_CAMPAIGN_IS_LOADING":
			return {
				...state,
				addCampaign: {
					loading: true,
				},
			};
		case "ADD_CAMPAIGN_HAS_ERROR":
			return {
				...state,
				addCampaign: {
					loading: false,
					error: true,
				},
			};
		case "ADD_CAMPAIGN_POST_SUCCESS":
			return {
				...state,
				addCampaign: {
					loading: false,
					error: false,
					success: true,
				},
			};
		case DELETE_CAMPAIGN:
			const filteredCampaigns = state.campaigns.filter((item) => item.campaignid !== action.index);
			return {
				...state,
				campaigns: filteredCampaigns,
				selectedCampaignIndex: filteredCampaigns[0].campaignid,
			};
		case UPDATE_CAMPAIGN_NAME:
			let newCampaigns = state.campaigns.slice();
			newCampaigns.find((item) => item.campaignid === action.id).name = action.newName;
			return {
				...state,
				campaigns: newCampaigns,
				selectedCampaignIndex: action.id,
			};
		case UPDATE_CAMPAIGN_STATUS:
			newCampaigns = state.campaigns.slice();
			newCampaigns.find((item) => item.campaignid === action.id).active = action.status;
			return {
				...state,
				campaigns: newCampaigns,
			};
		case "CLEAR_DATA":
			return initialState;
		default:
			return state;
	}
};
