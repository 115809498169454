import React from "react";
import List from "../List";
import { ChargesPaginationHeader } from "./ChargesPaginationHeader";

// Should be a simple list which takes the charges as a prop and displays them
const headers = [
	{
		header: "Charge ID",
		title: "Charge ID",
		accessor: "id",
	},
	{
		header: "Amount",
		title: "Amount",
		accessor: "amount",
	},
	{
		header: "Memo",
		title: "Memo",
		accessor: "memo",
	},
	{
		header: "Campaign ID",
		title: "Campaign ID",
		accessor: "campaignid",
	},
	{
		header: "Campaign",
		title: "Campaign",
		accessor: "campaignname",
	},
	// {
	//     header: "Organization ID",
	//     title: "Organization ID",
	//     accessor: "organizationid"
	// },
	// {
	//     header: "Organization",
	//     title: "Organization",
	//     accessor: "organizationname"
	// },
	{
		header: "Charged At",
		title: "Charged At",
		accessor: "timestamp",
	},
	{
		header: "Type",
		title: "Type",
		accessor: "type",
	},
];

export const Charges = ({ charges, currentPage, totalPages, flipPage, download }) => (
	<>
		<a className="button is-info" onClick={download}>
			Download{" "}
		</a>
		<List headers={headers} list={charges} />
		<ChargesPaginationHeader currentPage={currentPage} totalPages={totalPages} flipPage={flipPage} />
	</>
);
