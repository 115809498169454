import React from "react";

export const Welcome = ({ ...props }) => {
	return (
		<div>
			Welcome to Survey160.
			<br />
			<br />
			Need help? Contact <a href="mailto:kcollins@survey160.com">kcollins@survey160.com</a>.
		</div>
	);
};
