// Going to try and make this reusable somehow...
import React from "react";

import ModalContainer, { ModalBody, ModalHeader, ModalFooter } from "../modals/ModalContainer";

function ModalWrapper({ modalName, header, body, footer }) {
	return (
		<ModalContainer name={modalName} customButtonClass="button is-success">
			{(modalState) => (
				<React.Fragment>
					<ModalHeader>{header}</ModalHeader>
					<ModalBody>{body}</ModalBody>
					<ModalFooter {...modalState}>{footer}</ModalFooter>
				</React.Fragment>
			)}
		</ModalContainer>
	);
}

export default ModalWrapper;
