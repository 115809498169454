import React from "react";

import { Labels } from "./generic/Labels";
import { Label } from "./generic/Label";

export const UserFilterLabels = ({ items }) => {
	return (
		<Labels>
			{items.map((item) => (
				<Label className="is-rounded is-info">{item}</Label>
			))}
		</Labels>
	);
};
