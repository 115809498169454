import React from "react";
import PropTypes from "prop-types";

export const BulmaButton = ({ loading, error, onClick, customClass, children, ...props }) => (
	<>
		<a className={`button ${customClass} ${loading ? "is-loading" : ""}`} onClick={onClick} {...props}>
			{children}
		</a>
		{error && <p className="help">An error occurred trying to complete this action</p>}
	</>
);

BulmaButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	customClass: PropTypes.string,
	loading: PropTypes.bool,
	error: PropTypes.bool,
};
