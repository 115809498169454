import { UPLOAD_STATUS_MAP } from "../../util/constants";
import { loadStateReducer } from "../../util/reusableReducers";
import { combineReducers } from "redux";

const initialState = {
	processingResultsChanged: false,
};

function mainReducer(state = initialState, action) {
	switch (action.type) {
		// This is pretty much a catch all for when we need to reload the processing results
		case "SET_PROCESSING_RESULTS_CHANGED":
			return {
				...state,
				processingResultsChanged: action.value,
			};
		case "SET_PROCESSING":
			return {
				...state,
				processing: action.value,
			};
		case "SET_IMPORTING_FINAL":
			return {
				...state,
				importingFinal: action.value,
			};
		case "FETCH_PROCESSING_RESULTS_POST_SUCCESS":
			const processed = reduceProcessingResults(action.data);
			return {
				...state,
				rawResults: action.data,
				results: processed,
				status: processed.status,
			};
		case "SET_UPLOAD_STATUS":
			return {
				status: action.value,
				errorMessage: action.value === "failed" && action.error ? action.error : undefined,
			};
		case "DIRECT_UPLOAD_IS_LOADING":
			return {
				directUploadingInProgress: true,
			};
		case "DIRECT_UPLOAD_FINISHED_IS_LOADING":
		case "DIRECT_UPLOAD_FINISHED_HAS_ERROR":
		case "DIRECT_UPLOAD_FINISHED_POST_SUCCESS":
			return {
				directUploadingInProgress: false,
			};
		default:
			return state;
	}
}

/**
 * Turns a list of processing_results
 * @param {list} data the list of results
 */
const reduceProcessingResults = (data) => {
	const reduced = data
		// .sort() // eventually sort this so that the unprocessed ones come first.
		.sort((a, b) => b.id - a.id)
		.reduce((tot, item, i) => {
			let result = { ...tot };

			if (!item.results) return result;

			if (result.id === undefined || item.id > result.id) {
				result.id = item.id;
			}

			if (item.status === UPLOAD_STATUS_MAP.COMPLETE) {
				result.status = !tot.status ? item.status : tot.status;
				result.actualRowsAdded = (tot.actualRowsAdded || 0) + item.results.actual_rows_added;
				result.duplicatesRemoved = (tot.duplicatesRemoved || 0) + item.results.duplicates_removed;
				result.optOuts = (tot.optOuts || 0) + item.results.opt_outs;
				result.totalRowsInFile = (tot.totalRowsInFile || 0) + item.results.total_rows_in_file;
			} else if (item.status === UPLOAD_STATUS_MAP.FAILED) {
				// Don't want to show errors from previous uploads
				if (result.id !== undefined && result.id > item.id) {
					return result;
				}
				result.status = item.status;
				result.errorType = item.results.error;
				result.errorMessage = item.results.message;
			} else {
				result.status = item.status;
				result.newActualRowsAdded = item.results.actual_rows_added;
				result.newDuplicatesRemoved = item.results.duplicates_removed;
				result.newOptOuts = item.results.opt_outs;
				result.newTotalRowsInFile = item.results.total_rows_in_file;
				result.options = item.options;

				// Reset errors so the user can move on
				result.errorType = undefined;
				result.errorMessage = undefined;
			}

			return result;
		}, {});
	console.log(reduced);
	return reduced;
};

const getUploadURL = loadStateReducer({
	GET_UPLOAD_URL_IS_LOADING: "loading",
	GET_UPLOAD_URL_HAS_ERROR: "error",
	GET_UPLOAD_URL_POST_SUCCESS: "success",
});

const confirmUpload = loadStateReducer({
	CONFIRM_UPLOAD_IS_LOADING: "loading",
	CONFIRM_UPLOAD_HAS_ERROR: "error",
	CONFIRM_UPLOAD_POST_SUCCESS: "success",
});

const cancelUpload = loadStateReducer({
	CANCEL_UPLOAD_IS_LOADING: "loading",
	CANCEL_UPLOAD_HAS_ERROR: "error",
	CANCEL_UPLOAD_POST_SUCCESS: "success",
});

const uploadProcessingResults = loadStateReducer({
	FETCH_PROCESSING_RESULTS_IS_LOADING: "loading",
	FETCH_PROCESSING_RESULTS_HAS_ERROR: "error",
	FETCH_PROCESSING_RESULTS_POST_SUCCESS: "success",
});

export default combineReducers({
	main: mainReducer,
	getUploadURL,
	uploadProcessingResults,
	confirmUpload,
	cancelUpload,
});
