// Displays and manages orgs.
// See note in TwilioContainer.js - same applies here.

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AddOrganizationModal from "../components/modals/AddOrganizationModal";
import ConfirmActionModal from "../components/modals/ConfirmActionModal";
import RenameOrganizationModal from "../components/modals/RenameOrganizationModal";

import { deleteOrganization, vitalsDataPost } from "../actions/vitals";

class OrganizationsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOrganization: null,
		};

		this.selectOrg = this.selectOrg.bind(this);
	}

	selectOrg(e, orgid) {
		this.setState({
			selectedOrganization: orgid,
		});
	}

	render() {
		// Figure out which campaigns go with which organizations for display purposes.
		const { showEditOrganizationButtons = false } = this.props;

		var orgs = {};
		this.props.organizationarray.map((org, i) => {
			orgs[org.organizationid] = {
				organizationname: org.organizationname,
				campaigns: [],
			};
		});
		this.props.campaigns
			.filter((c) => c.organizationid in orgs)
			.map((camp, i) => {
				orgs[camp.organizationid].campaigns.push(camp.name);
			});

		const selectedOrg = orgs[this.state.selectedOrganization];

		let selectedOrganizationName = "";
		let orgCampaigns = [];

		if (selectedOrg) {
			selectedOrganizationName = selectedOrg.organizationname;
			orgCampaigns = selectedOrg.campaigns;
		}

		return (
			<div>
				<div className="tile is-ancestor">
					<div className="tile is-parent is-vertical">
						<h1 className="title">ORGANIZATIONS DATA</h1>
						{this.props.organizationarray.map((org, i) => (
							<div key={i} value={i}>
								<a
									className={
										this.state.selectedOrganization === org.organizationid
											? "has-text-white has-background-primary"
											: ""
									}
									style={{
										display: "inline-block",
										width: "100%",
										paddingLeft: "5px",
									}}
									onClick={(e) => this.selectOrg(e, org.organizationid)}
								>
									<b>{org.organizationname}</b>
								</a>
								<ul>
									{orgs[org.organizationid].campaigns.map((camp, j) => (
										<li key={j}>&nbsp;&nbsp;&#8226;&nbsp;{camp}</li>
									))}
								</ul>
							</div>
						))}
						<div className="control ">
							{showEditOrganizationButtons && (
								<div className="field is-grouped">
									<AddOrganizationModal
										userid={this.props.userid}
										organizationarray={this.props.organizationarray}
										refreshOrganizationData={this.props.refreshOrganizationData}
									/>
									<ConfirmActionModal
										actionName="Delete Organization"
										message={
											<>
												Delete organization: <b> {selectedOrganizationName} </b> ? <br />
												This will also delete <b>{orgCampaigns.length} </b> campaigns assigned
												to this organization!
											</>
										}
										actionCallback={() => {
											// Don't delete org with campain
											if (orgs[this.state.selectedOrganization].campaigns.length > 0) {
												return;
											}

											this.props
												.deleteOrganization(this.state.selectedOrganization)
												.then(() => this.props.vitalsDataPost());
										}}
										buttonClass={
											"button is-danger " +
											((!selectedOrg || selectedOrg.campaigns.length > 0) && "is-static")
										}
									/>
									<RenameOrganizationModal
										actionName="Rename Organization"
										organizations={this.props.organizationarray}
										id={this.state.selectedOrganization}
										userid={this.props.userid}
										buttonClass={"button is-light " + (!selectedOrg && "is-static")}
										vitalsDataPost={this.props.vitalsDataPost}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			vitalsDataPost,
			deleteOrganization: (orgid) => deleteOrganization(orgid),
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(OrganizationsContainer);
