import React from "react";

import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FilterListToggleShowClosedCampaignsButton = ({
	clearFilterCallback,
	filterValue,
	setFilterValueCallback,
}) => {
	const status = "deactivated";
	const toggleShowClosed = () => {
		if (filterValue === status) {
			clearFilterCallback();
		} else {
			setFilterValueCallback({ target: { value: status } });
		}
	};
	return (
		<a onClick={toggleShowClosed}>
			<p className="help">
				<FontAwesomeIcon icon={filterValue !== status ? faEye : faEyeSlash} />
				&nbsp;&nbsp;
				{filterValue !== status ? "Hide" : "Show"} {status} campaigns
			</p>
		</a>
	);
};
