import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { logAgentOut } from "../../redux/Agents/actions";

const Button = ({ logAgentOut, userid, selectedCampaignid, refreshCallback, disabled }) => (
	<a
		className="button is-info"
		onClick={() => {
			logAgentOut(selectedCampaignid, userid).then(() => refreshCallback([selectedCampaignid]));
		}}
		disabled={disabled}
	>
		Log Out
	</a>
);

const mapStateToProps = (state) => ({
	agentLogout: state.agentLogout,
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			logAgentOut: (campaignid, userid) => {
				return logAgentOut(campaignid, userid);
			},
		},
		dispatch,
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Button);
