import { ORG_IS_LOADING, ORG_HAS_ERROR, ORG_GET_SUCCESS } from "../actions/organizations";
import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";
import { loadStateReducer } from "../util/reusableReducers";

const initialState = {
	loading: false,
	error: false,
	organizations: [],
};

const mainReducer = (state = initialState, action) => {
	switch (action.type) {
		case ORG_IS_LOADING:
			return {
				...state,
				loading: action.isLoading,
			};
		case ORG_HAS_ERROR:
			return {
				...state,
				error: action.hasError,
				loading: false,
			};
		case ORG_GET_SUCCESS:
			return {
				...state,
				loading: false,
				organizations: action.organizations,
			};
		case "CLEAR_DEFAULTS_DATA":
		case "DEL_CAMPAIGN_DATA_DEFAULTS_DELETE_SUCCESS":
			return {
				...state,
				campaignDefaults: {
					loading: false,
					error: false,
				},
			};
		case "UPDATE_CAMPAIGN_DATA_DEFAULTS_POST_SUCCESS":
			return {
				...state,
				updateCampaignDefaults: {
					...state.updateCampaignDefaults,
					loading: false,
				},
			};
		case "CLEAR_DATA":
			return initialState;
		default:
			return state;
	}
};

const campaignDataDefaultsReducer = loadStateReducer({
	CAMPAIGN_DATA_DEFAULTS_IS_LOADING: "loading",
	CAMPAIGN_DATA_DEFAULTS_HAS_ERROR: "error",
	CAMPAIGN_DATA_DEFAULTS_GET_SUCCESS: "success",
});

const updateCampaignDataDefaultsReducer = loadStateReducer({
	UPDATE_CAMPAIGN_DATA_DEFAULTS_IS_LOADING: "loading",
	UPDATE_CAMPAIGN_DATA_DEFAULTS_HAS_ERROR: "error",
});

export default reduceReducers(
	combineReducers({
		campaignDefaults: campaignDataDefaultsReducer,
		updateCampaignDefaults: updateCampaignDataDefaultsReducer,
	}),
	mainReducer,
);
