import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";

import { CurrencyInputField } from "../generic/CurrencyInputField";
import { updateCampaignCosts } from "../../redux/SelectedCampaign/actions";

class UpdateCampaignCostsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};
		this.toggleOpen = this.toggleOpen.bind(this);
		this.addCost = this.addCost.bind(this);
		this.updateCosts = this.updateCosts.bind(this);
		this.onChangeList = this.onChangeList.bind(this);
		// this.changeSms = this.changeSms.bind(this);
		this.removeCost = this.removeCost.bind(this);
		this.inputChanged = this.inputChanged.bind(this);

		// Create refs to pass them to CurrencyInput
		this.newSurcharge = React.createRef();
	}

	static getDerivedStateFromProps(props, state) {
		if (!state.localCampaignCosts) {
			return {
				smssurcharge: props.smssurcharge,
				localCampaignCosts: props.campaigncosts,
				spendlimit: props.spendlimit,
				costperagenthour: props.costperagenthour,
				changed: false,
			};
		}
		return null;
	}

	toggleOpen() {
		this.setState({ open: !this.state.open });
	}

	inputChanged(e) {
		if (parseFloat(e.target.value) < 0) {
			e.target.value = 0;
		}
		this.setState({
			changed: true,
			[e.target.name]: e.target.value,
		});
	}

	addCost() {
		this.setState({
			localCampaignCosts: [...this.state.localCampaignCosts, { charge: 0, description: "", interval: "once" }],
			changed: true,
		});
	}

	removeCost(index) {
		const newList = [...this.state.localCampaignCosts];
		newList.splice(index, 1);

		this.setState({
			changed: true,
			localCampaignCosts: newList,
		});
	}

	onChangeList(e, index) {
		const newList = [...this.state.localCampaignCosts];

		// enforce non-negative values for charges
		if (e.target.name === "charge") {
			newList[index]["charge"] = parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : 0;
		} else {
			newList[index][e.target.name] = e.target.value;
		}
		this.setState({
			localCampaignCosts: newList,
			changed: true,
		});
	}

	updateCosts() {
		let sms, limit, costperagenthour;

		if (this.state.smssurcharge === 0 || this.state.smssurcharge) {
			sms = parseFloat(this.state.smssurcharge);
		}

		if (this.state.spendlimit || this.state.spendlimit === "0") {
			limit = parseFloat(this.state.spendlimit);
		}

		if (this.state.costperagenthour || this.state.costperagenthour === "0") {
			costperagenthour = parseFloat(this.state.costperagenthour);
		}

		this.props.updateCampaignCosts(sms, this.state.localCampaignCosts, limit, costperagenthour);

		this.setState({
			changed: false,
			localCampaignCosts: undefined,
		});
	}

	render() {
		const campaignWasLaunched = ["deactivated", "active"].includes(this.props.campaignStatus);
		const hasBillingEditPermissions =
			this.props.userPermissions && this.props.userPermissions.includes("EDIT_BILLING_ALL_FIELDS");

		return (
			<React.Fragment>
				<button
					className={"button " + this.props.buttonClass}
					title={this.props.buttonTitleText || this.props.buttonText}
					onClick={this.toggleOpen}
					disabled={this.props.buttonIsDisabled}
				>
					{this.props.buttonText}
				</button>
				<Modal isOpen={this.state.open} className={"modal" + (this.state.open ? " is-active" : "")}>
					<div className="modal-background" onClick={this.toggleOpen} />
					<div className="modal-card">
						<header className="modal-card-head">Edit Billing Settings</header>
						<section className="modal-card-body">
							<label className="label mt-3">Cost Per Agent Hour</label>
							<CurrencyInputField
								className="input"
								style={{ width: "30%" }}
								name="costperagenthour"
								type="number"
								defaultValue={this.props.costperagenthour}
								onChange={this.inputChanged}
								min="0"
							/>

							<label className="label mt-3">Spend Limit</label>
							<CurrencyInputField
								className="input"
								style={{ width: "30%" }}
								name="spendlimit"
								type="number"
								defaultValue={this.state.spendlimit}
								onChange={this.inputChanged}
								min="0"
							/>

							{hasBillingEditPermissions && (
								<>
									<label className="label mt-3">Cost per SMS</label>
									<CurrencyInputField
										className="input"
										name="smssurcharge"
										type="number"
										style={{ width: "30%" }}
										defaultValue={this.state.smssurcharge}
										onChange={this.inputChanged}
										min="0"
									/>
									<br />

									<label className="label">Campaign Charges</label>
									{(this.state.localCampaignCosts || []).map((item, index) => {
										return (
											<div key={index} className="field is-grouped">
												<p className="control">
													<input
														className="input"
														value={item.description}
														name="description"
														type="text"
														onChange={(e) => this.onChangeList(e, index)}
														disabled={campaignWasLaunched}
													/>
												</p>
												<p
													className="control"
													style={{
														maxWidth: "100px",
													}}
												>
													<p className="control has-icons-left">
														<CurrencyInputField
															className="input"
															value={item.charge}
															name="charge"
															type="number"
															onChange={(e) => this.onChangeList(e, index)}
															min="0"
															disabled={campaignWasLaunched}
														/>
													</p>
												</p>
												<p className="control">
													<div className="select">
														<select
															value={item.interval}
															onChange={(e) => this.onChangeList(e, index)}
															name="interval"
															disabled={campaignWasLaunched}
														>
															{/* TODO: remove the time interval entirely */}
															<option>Once</option>
															<option value="once">Once</option>
														</select>
													</div>
												</p>
												{!campaignWasLaunched && (
													<p className="control">
														<a
															className="button is-danger"
															onClick={() => this.removeCost(index)}
														>
															Remove
														</a>
													</p>
												)}
											</div>
										);
									})}

									{!campaignWasLaunched ? (
										<a className="button is-success" onClick={this.addCost}>
											Add Cost
										</a>
									) : (
										<p className="mb-3 is-size-6">
											<em>Campaign charges cannot be edited after a campaign is launched</em>
										</p>
									)}
								</>
							)}
						</section>
						<footer className="modal-card-foot">
							<button
								className="button is-info"
								onClick={this.updateCosts}
								disabled={!this.state.changed}
							>
								Update Campaign
							</button>
						</footer>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateCampaignCosts,
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(UpdateCampaignCostsModal);
