import React, { Component } from "react";

class ScriptErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h1>There is a problem with the script object. Please check your syntax.</h1>;
		}

		return this.props.children;
	}
}

export default ScriptErrorBoundary;
