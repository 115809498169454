export const STRATA_ID_HEADER = {
	header: "Strata ID",
	title: "strata id",
	accessor: "strata_id",
};

export const DEFAULT_HEADERS = [
	{
		header: "Tgt. Completes",
		title: "target completes",
		accessor: "target_complete",
	},
	{
		header: "Cur. Completes",
		title: "current completes",
		accessor: "closed",
	},
	{
		header: "Tgt. Proportion",
		title: "assigned weight",
		accessor: "assignment_weight",
	},
	{
		header: "Cur. Proportion",
		title: "Current Proportion",
		accessor: "currProp",
	},
	{ header: "Avail.", title: "available", accessor: "available" },
	{ header: "Assigned", title: "Assigned", accessor: "assigned" },
	{ header: "Started", title: "Started", accessor: "started" },
	{ header: "Engaged", title: "Engaged", accessor: "engaged" },
	{ header: "Terminated", title: "Terminated", accessor: "terminated" },
	{
		header: "Next Batch",
		title: "Next Batch Amount",
		accessor: "next_batch_amount",
	},
	{
		header: "List Total",
		title: "Total of all matching numbers in List",
		accessor: "total",
	},
];

/**
 * Returns a function that
 * @param {string} accessor the accessor to sort by
 * @return {function} sorting function that uses the accessor
 */
export const getSortFunction = (accessor) => (a, b) =>
	a[accessor] < b[accessor] ? -1 : a[accessor] > b[accessor] ? 1 : 0;
