import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalContainer, { ModalBody, ModalHeader, ModalFooter } from "../modals/ModalContainer";

import { updateCampaignFetchData } from "../../redux/SelectedCampaign/actions";

/**
 * Text tools modal
 * @param {number} props.campaignid
 * @param {boolean} props.loading
 * @param {boolean} props.error - indicates http fetch error (not the form errors represented by `err`)
 * @param {string|undefined} props.errorMessage - user-facing error message if the update fails.
 * @param {string} props.headerText - modal header text
 * @param {number} props.recipientbatchsize - how many new recipients should be loaded
 * @param {number} props.actionablethreshold - threshold value that triggers loading more recipients
 * @param {number} props.hourlyNewConversationLimit - limit for initiating new conversations each hour
 * @param {number} props.dailyTargetComplete - target of completes for the day
 * @param {function} props.updateCampaignFetchData - callback fn that updates campaign settings with new data
 * @returns {React.Component}
 */
function TextToolsModal({
	campaignid,
	loading,
	error,
	errorMessage,
	headerText,
	recipientbatchsize,
	actionablethreshold,
	hourlyNewConversationLimit,
	dailyTargetComplete,
	updateCampaignFetchData,
}) {
	const [fields, setFields] = useState({});
	const [dirty, setDirty] = useState(false);
	const [err, setErr] = useState("");

	function setFieldsFromEvent(e) {
		setDirty(true);
		let val = parseInt(e.target.value);

		if (isNaN(val)) {
			setErr(`Failed to parse field: ${e.target.name}`);
			console.error(`Failed to parse value: ${e.target.name}`);
			return;
		}
		setErr("");
		setFields({ ...fields, [e.target.name]: val });
	}

	function updateTextSettings(e) {
		// Prevent default to stop page from reloading
		// on Form submit!
		e.preventDefault();
		if (dirty && !loading && Object.keys(fields).length !== 0) {
			console.log(fields);
			updateCampaignFetchData(campaignid, fields).catch((err) => {
				console.error(err);
			});
		}
	}

	return (
		<ModalContainer name="Edit Text Settings" customButtonClass={"button is-light is-fullwidth"}>
			{(modalState) => (
				<React.Fragment>
					<ModalHeader>
						Text Settings: &nbsp;<strong>{headerText}</strong>
					</ModalHeader>
					<ModalBody>
						<form id="text-settings-form" onSubmit={updateTextSettings}>
							<div className="field">
								<label className="label" htmlFor="input-recipientbatchsize">
									New Recipient Batch Amount
								</label>
								<div className="control">
									<input
										id="input-recipientbatchsize"
										className="input"
										type="number"
										name="recipientbatchsize"
										onBlur={setFieldsFromEvent}
										defaultValue={recipientbatchsize}
										step={1}
									/>
								</div>
							</div>
							<div className="field">
								<label className="label" htmlFor="input-actionablethreshold">
									Threshold for loading more conversations
								</label>
								<div className="control">
									<input
										id="input-actionablethreshold"
										className="input"
										type="number"
										name="actionablethreshold"
										onBlur={setFieldsFromEvent}
										defaultValue={actionablethreshold}
										step={1}
									/>
								</div>
							</div>
							<div className="field">
								<label className="label" htmlFor="daily-target-complete">
									Daily completes target
								</label>
								<div className="control">
									<input
										id="daily-target-complete"
										className="input"
										type="number"
										name="daily_target_complete"
										onBlur={setFieldsFromEvent}
										defaultValue={dailyTargetComplete}
										step={1}
										min={0}
									/>
								</div>
								<p className="help">
									{"If the target is > 0, the campaign will pause after reaching this target."}
								</p>
							</div>
							<div className="field">
								<label className="label" htmlFor="input-hourly_maximum">
									Hourly new conversation limit
								</label>
								<div className="control">
									<input
										id="input-hourly_maximum"
										className="input"
										type="number"
										name="hourly_maximum_first_attempts"
										onBlur={setFieldsFromEvent}
										defaultValue={hourlyNewConversationLimit}
										step={4}
										min={0}
									/>
								</div>
								<p className="help">
									The hourly new conversation limit must be divisible by 4: the limit is applied every
									15 minutes to spread out new intros.
								</p>
							</div>
						</form>
						{err && <p className={"help is-danger"}>{err}</p>}
						{error && (
							<p className={"help is-danger"}>
								There was a problem saving the text options: <b>{errorMessage}</b>
							</p>
						)}
					</ModalBody>
					<ModalFooter {...modalState}>
						<button
							className={"button is-info " + (loading && "is-loading")}
							disabled={loading || Object.keys(fields).length === 0 || err}
							type="submit"
							form="text-settings-form"
						>
							Save
						</button>
					</ModalFooter>
				</React.Fragment>
			)}
		</ModalContainer>
	);
}

function mapStateToProps(state) {
	return {
		loading: state.selectedCampaign.updateCampaignData.loading,
		error: state.selectedCampaign.updateCampaignData.error,
		errorMessage: state.selectedCampaign.updateCampaignData.errorMessage,
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateCampaignFetchData: (campaignid, ncd) => updateCampaignFetchData(campaignid, ncd),
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(TextToolsModal);
