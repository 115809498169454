import { withAsyncCall } from "./higher-order/AsyncWrapper";
import { getCurrentSpend } from "../actions/billing";
import React from "react";
import { LoadingSpinner } from "./LoadingSpinner";
import { centsToDollars } from "../util/helper";

const CurrentSpendField = ({ setChanged, changeParams, currentSpend, ...props }) => {
	return currentSpend.loading ? (
		<LoadingSpinner loading={currentSpend.loading} />
	) : (
		<>{centsToDollars(currentSpend.value || 0)}</>
	);
};

export default withAsyncCall(
	CurrentSpendField,
	(orgid, campid) => getCurrentSpend(orgid, campid),
	(state) => ({ currentSpend: state.billing.currentSpend }),
);
