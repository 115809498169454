import { analyticsEmpty, ANALYTICS_ERROR_CODE_LIST } from "../../util/constants";
import { fetchRequest } from "../../middleware/fetchMiddleware";
import { getPercentValue, centsToDollars } from "../../util/helper";

export const ANALYTICS_PREFIX = "ANALYTICS";
export const ANALYTICS_IS_LOADING = "ANALYTICS_IS_LOADING";
export const ANALYTICS_HAS_ERROR = "ANALYTICS_HAS_ERROR";
export const ANALYTICS_DATA_SUCCESS = "ANALYTICS_DATA_SUCCESS";
export const CLEAR_ANALYTICS_DATA = "CLEAR_ANALYTICS_DATA";

/// The reason I use this is because the default fetch success dispatch,
/// is not adequate in this case
export function analyticsDataSuccess(data, key) {
	return (dispatch) =>
		dispatch({
			type: ANALYTICS_DATA_SUCCESS,
			data: data,
			key: key,
		});
}

export function clearAnalyticsData() {
	return (dispatch) =>
		dispatch({
			type: "CLEAR_ANALYTICS_DATA",
		});
}

/**
 * TODO: This only works for a single campaign. We should expand it to allow multiple campaign snapshots at the same time.
 * @param {String} start The timestamp for where to start counting data. "start" means as far back as the campaign data goes.
 * @param {String} end The timestamp for where to stop counting data. "current" means use the current values.
 */
export function selectSnapshotRange(start, end) {
	return (dispatch, getState) => {
		const { snapshots, current } = getState().analytics;

		const startData = start === "start" ? undefined : snapshots.data.find((item) => item.timestamp === start);
		const endData =
			end === "current" ? current.data.campaign[0] : snapshots.data.find((item) => item.timestamp === end);

		// Start with the end data
		let final = endData;

		// If we have both subtract start from end
		if (startData && endData) {
			// Use unprocessed startData because we have to reprocess % values anyway.
			final = createSnapshotFromCumulativeDataPoints(startData.data_snapshot, endData.data_snapshot);
		} else {
			final = final.data_snapshot;
		}

		return dispatch(analyticsDataSuccess(processData([final]), "campaign"));
	};
}

/**
 * Create analytics data from two snapshots by subtracting cumulative data and displaying latest non-cumulative data.
 * @param {object} start The earlier of the two snapshots
 * @param {object} end The latter of the two snapshots
 */
function createSnapshotFromCumulativeDataPoints(start, end) {
	const result = {
		...end,

		// Non cumulative data
		firstsms: start.firstsms,
		lastsms: end.lastsms,
		lastsmsincoming: end.lastsmsincoming,
		lastsmsoutgoing: end.lastsmsoutgoing,
		assigned: end.assigned,
		contact: end.contact, // TOTAL

		// Ascending cumulative values
		start: end.start - start.start,
		engaged: end.engaged - start.engaged,
		terminated: end.terminated - start.terminated,
		closed: end.closed - start.closed,

		currentspend: end.currentspend - start.currentspend,
		minutes: end.minutes - start.minutes,
		incoming_segments: end.incoming_segments - start.incoming_segments,
		outgoing_segments: end.outgoing_segments - start.outgoing_segments,
		sent_messages: end.sent_messages - start.sent_messages,
		total_segments: end.total_segments - start.total_segments,
		text_costs: end.text_costs - start.text_costs,
		agent_hour_costs: end.agent_hour_costs - start.agent_hour_costs,

		optedin: end.optedin - start.optedin,
		optedout: end.optedout - start.optedout,

		// Descending values need to be inverse!
		open: start.open - end.open,
	};
	ANALYTICS_ERROR_CODE_LIST.forEach((e) => {
		result[e] = end[e] - start[e];
	});
	return result;
}

export function retrieveAnalyticsSnapshots() {
	return (dispatch, getState) =>
		dispatch(
			fetchRequest("SNAPSHOTS", "POST", "/retrieveAnalyticsSnapshots", {
				campaignid: getState().NAID.selectedCampaignIndex,
			}),
		);
}

/**
 * Get's  the analytics data grouped by org, campaign or userid and scoped by org or campaign.
 *
 * @param {String} groupby Group the analytics data (campaign, org, userid)
 * @param {String} scope What is the scope to pull the data from
 * @param {String} value The value to filter the scope by (if scope=campaign the campaignid value=92)
 */
export function getAnalyticsData(groupby, scope, value) {
	return (dispatch, getState) => {
		return dispatch(
			fetchRequest(ANALYTICS_PREFIX, "POST", "/getAnalyticsData", {
				groupby: groupby,
				scope: scope,
				value: value,
			}),
		).then((data) => {
			if (!data) return;

			dispatch(analyticsDataSuccess(data.data, groupby));
		});
	};
}

function processData(raw) {
	return raw.map((item) => {
		Object.keys(item).forEach((key) => {
			if (!item[key]) {
				delete item[key];
			}
		});
		const newObj = {
			...analyticsEmpty,
			...item,
		};

		const firstsms = new Date(item.firstsms);
		const lastsms = new Date(item.lastsms);
		const lastsmsoutgoing = new Date(item.lastsmsoutgoing);
		const lastsmsincoming = new Date(item.lastsmsincoming);

		newObj.firstsms = isNaN(firstsms)
			? "-"
			: firstsms.toLocaleTimeString("en-US", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				});
		newObj.lastsms = isNaN(lastsms)
			? "-"
			: lastsms.toLocaleTimeString("en-US", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				});
		newObj.lastsmsoutgoing = isNaN(lastsmsoutgoing)
			? "-"
			: lastsmsoutgoing.toLocaleTimeString("en-US", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				});
		newObj.lastsmsincoming = isNaN(lastsmsincoming)
			? "-"
			: lastsmsincoming.toLocaleTimeString("en-US", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				});

		// - % started: Among the people who are included in the list, the % of people of are sent a first text (uses \# starts and \#contacts)
		// - % complete: Among those who are sent a first text, the percent who are also sent the closing (uses \# starts and \# terminated)
		// - % complete | engage: Among those who respond in any way to the first text, the percent who complete
		newObj.percentstarted = getPercentValue(newObj.contact, newObj.start).toFixed(1) + "%";
		newObj.percentcomplete = getPercentValue(newObj.start, newObj.closed).toFixed(1) + "%";
		newObj.percentcompleteengaged = getPercentValue(newObj.engaged, newObj.closed).toFixed(1) + "%";
		newObj.percentengaged = getPercentValue(newObj.start, newObj.engaged).toFixed(1) + "%";
		newObj.percentterminated = getPercentValue(newObj.start, newObj.terminated).toFixed(1) + "%";
		newObj.percent4770 = getPercentValue(newObj.start, newObj[4770]).toFixed(1) + "%";

		const hours = newObj.minutes ? newObj.minutes / 60 : 0;
		newObj.agentHours = hours.toFixed(2);

		newObj.currentspend = `$ ${(item.currentspend ? centsToDollars(newObj.currentspend) : 0).toFixed(2)}`;
		newObj.cost_per_complete = `$ ${(item.currentspend && newObj.closed ? centsToDollars(item.currentspend) / newObj.closed : 0).toFixed(2)}`;

		// SMS / agenthour is be the amount of sends / hour
		newObj.smsperagenthour = newObj.sent_messages ? (newObj.sent_messages / hours).toFixed(2) : "0.00";

		newObj.text_costs = `$ ${centsToDollars(newObj.text_costs).toFixed(2)}`;
		newObj.agent_hour_costs = `$ ${centsToDollars(newObj.agent_hour_costs).toFixed(2)}`;

		ANALYTICS_ERROR_CODE_LIST.forEach((e) => {
			newObj[`${e}_per_started`] =
				`${newObj[e] && newObj.start ? getPercentValue(newObj.start, newObj[e]).toFixed(1) : "0.0"}%`;
		});

		newObj["4770_per_complete"] =
			`${newObj[4770] && newObj.closed ? getPercentValue(newObj.closed, newObj[4770]).toFixed(1) : "0.0"}%`;

		return newObj;
	});
}
