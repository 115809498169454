import React, { Component } from "react";

import { LoadingSpinner } from "../LoadingSpinner";

import RecipientUploadOptions from "./RecipientUploadOptions";
import ConfirmUpload from "./ConfirmUpload";
import RecipientsSampleTable from "./RecipientsSampleTable";
import { ProcessedResults } from "./ProcessedResults";
import { UploadStatusProgressBar } from "./UploadStatusProgressBar";
import { BulmaButton } from "../generic/Buttons/BulmaButton";
import Tabs from "../tabs/NewTabs";

class RecipientsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showOptions: false,
		};
	}

	toggleShowOptions = () => {
		this.setState({
			showOptions: !this.state.showOptions,
		});
	};

	componentDidUpdate() {
		const { setChanged, processingResultsChanged, status, setCampaignDataUpdated } = this.props;
		const { previousStatus } = this.state;

		if (status !== previousStatus) {
			this.setState({
				previousStatus: status,
			});
		}

		if (status === "complete" && previousStatus && previousStatus !== "complete") {
			// Reload campaignData to get the new recipient list
			setCampaignDataUpdated(true);
		}

		// If we get the processingResultsChanged flag just set the wrapper to changed and it should reload
		if (processingResultsChanged) {
			if (this.state.showOptions) {
				this.toggleShowOptions();
			}
			setChanged();
		}
	}

	render() {
		const {
			selectedCampaignData,
			status,
			errorMessage,
			getUploadURL,
			rawResults,
			confirmUpload,
			cancelUpload,
			results,
			directUploadingInProgress,
			userPermissions,
		} = this.props;

		const { showOptions } = this.state;
		const phones = Object.keys(selectedCampaignData.list);

		return (
			<div className="">
				<Tabs
					size="medium"
					// eslint-disable-next-line react/style-prop-object
					style="toggle"
					rounded={true}
					disabled={this.props.active === "deactivated"}
					defaultTab={phones.length > 0 ? "current list" : "upload new list"}
				>
					<div tabName="upload new list">
						<UploadStatusProgressBar status={status} />
						<br />
						{rawResults && rawResults.length > 0 && (
							<ProcessedResults selectedCampaignId={this.props.selectedCampaignId} {...results} />
						)}
						{status === "processing_complete" && rawResults && (
							<ConfirmUpload
								selectedCampaignId={this.props.selectedCampaignId}
								uploadID={rawResults.filter((item) => item.status !== "complete")[0].id}
								confirmUpload={confirmUpload}
								cancelUpload={cancelUpload}
							/>
						)}
						{showOptions && status !== "processing" && (
							<RecipientUploadOptions
								selectedCampaignId={this.props.selectedCampaignId}
								cancel={this.toggleShowOptions}
								directUploadingInProgress={directUploadingInProgress}
								{...getUploadURL}
								status={status}
								errorMessage={errorMessage}
								userPermissions={userPermissions}
							/>
						)}
						{status === "processing" && (
							<>Your list is being processed. You will be notified when that is done.</>
						)}

						{/* TODO: the only thing we need to do to interrupt is delete the not-complete status row in the DB
              this should reset us to upload start.

              If we want cancelling during operations that's a bit more complicated
            */}

						{phones.length === 0 && !showOptions && !status && (
							<>
								<h4 class="title is-5">No recipient list uploaded</h4>
								<hr />
								<BulmaButton customClass="button is-success" onClick={this.toggleShowOptions}>
									Get started
								</BulmaButton>
							</>
						)}
						{phones.length > 0 && !showOptions && (status === "complete" || !status) && (
							<BulmaButton customClass="is-success" onClick={this.toggleShowOptions}>
								Upload more records / replace existing
							</BulmaButton>
						)}
						{status === "failed" && results && (
							<div>
								<br />
								<div className="notification is-danger">
									Failed to process the list: <br />
									<br />
									<b>{results.errorType}</b>: {results.errorMessage}
								</div>
								{!showOptions && (
									<BulmaButton customClass="button is-success" onClick={this.toggleShowOptions}>
										Try again
									</BulmaButton>
								)}
							</div>
						)}
					</div>
					<div tabName="current list">
						{/* doesn't need state since it's just the phones length */}
						<RecipientsSampleTable
							selectedCampaignData={this.props.selectedCampaignData}
							selectedCampaignId={this.props.selectedCampaignId}
							updateList={this.props.updateList}
						/>
					</div>
				</Tabs>

				{this.state.loading && <LoadingSpinner loading={this.state.loading} />}
			</div>
		);
	}
}

export default RecipientsPage;
