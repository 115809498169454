import React, { Component } from "react";
import { connect } from "react-redux";
import { Notification } from "../Notification";
import { bindActionCreators } from "redux";
import { getUrlAndDownloadFile } from "../../../redux/GlobalNotifications/actions";

class DownloadReadyNotification extends Component {
	render() {
		const { campaignid, type, campaigns } = this.props;
		const campaign = campaigns.find((c) => c.campaignid === campaignid);

		return (
			<Notification {...this.props} className="is-success" fadeOutTimer={0}>
				The campaign results for <b>{campaign.name}</b> are ready.{" "}
				<a onClick={() => this.props.getUrlAndDownloadFile(campaignid)}>Click here to start download.</a>
			</Notification>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUrlAndDownloadFile: (campaignid) => getUrlAndDownloadFile(campaignid),
		},
		dispatch,
	);

export default connect(undefined, mapDispatchToProps)(DownloadReadyNotification);
