import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TextToWeb, TextToWebAnalytics } from "./TextToWeb";
import { startTextToWebResultsDownload, getT2WUrlAndDownloadFile } from "../../redux/GlobalNotifications/actions";
import { getTextToWebData } from "../../redux/TextToWeb/actions";
import List from "../List";

class TextToWebContainer extends Component {
	constructor(props) {
		super(props);

		this.startTextToWebExport = this.startTextToWebExport.bind(this);
		// This binds the event handlers defined below to the Component proper
		this.getTextToWebAnalytics = this.getTextToWebAnalytics.bind(this);
	}

	startTextToWebExport() {
		this.props.startTextToWebExport();
	}

	getTextToWebAnalytics() {
		this.props.getTextToWebAnalytics();
	}

	componentDidMount() {
		this.getTextToWebAnalytics();
	}

	render() {
		function returnZero(el) {
			if (el === null) {
				return "0";
			}
			return el;
		}

		// Convert array of arrays into a properly formatted object for <List />
		const source = transformT2WData(this.props.analytics);
		let analytics = [];
		let line = [];
		if (source) {
			source.map((item) => {
				line = {
					campaignid: item[0],
					date1: returnZero(item[1]),
					date2: returnZero(item[2]),
					date3: returnZero(item[3]),
					date4: returnZero(item[4]),
					date5: returnZero(item[5]),
					date6: returnZero(item[6]),
					date7: returnZero(item[7]),
					total: returnZero(item.slice(1, 7).reduce((a, b) => a + b, 0)),
				};
				analytics.push(line);
			});
		}

		// Right now I've hard-coded the # days. The API endpoint can return a variety of # days -
		//    alter this (& the mapping of source to analytics above) if you do that
		let headers = [
			{
				header: "Campaign ID",
				title: "Campaign ID",
				accessor: "campaignid",
			},
		];
		let dataDate = new Date();
		dataDate.setDate(dataDate.getDate() - 7);
		let fmtDate = new String();
		for (let i = 1; i <= 7; i++) {
			dataDate.setDate(dataDate.getDate() + 1);
			fmtDate = dataDate.toLocaleDateString();
			headers.push({
				header: fmtDate,
				title: fmtDate,
				accessor: `date${i}`,
			});
		}
		headers.push({ header: "Total", title: "Total", accessor: "total" });

		return (
			<>
				<h3 className="title has-text-centered">Text-to-Web Tools</h3>
				<div>
					<header>Recent Text-to_Web Analytics</header>
					<List headers={headers} list={analytics} />
					<TextToWeb download={this.startTextToWebExport} />
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		analytics: state.text_to_web.getTextToWebData.data,
		loading: state.text_to_web.getTextToWebData.loading,
		error: state.text_to_web.getTextToWebData.error,
	};
};

function transformT2WData(analytics) {
	if (analytics && analytics.data) {
		return JSON.parse(analytics.data);
	}
	return null;
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			startTextToWebExport: () => startTextToWebResultsDownload(),
			getTextToWebAnalytics: () => getTextToWebData(),
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(TextToWebContainer);
