import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

import CardItem, { CardText, CardImage, CardFooter } from "../generic/Cards/CardItem";

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});

function generateRingContentFooterLabels(footerItems = []) {
	return () => (
		<CardFooter
			items={footerItems.map(({ text, icon = faSquare, iconClassname = "has-text-danger" }, index) => (
				<p>
					<strong>{text}</strong>
					<span className="ml-2">
						<FontAwesomeIcon icon={icon} className={iconClassname} />
					</span>
				</p>
			))}
		/>
	);
}

/*
 * This function generates a div with a ring graphic
 * @param {string} textToDisplay - The text to display in the center of the ring
 * @param {string} color - The color of the ring
 * @returns {JSX.Element}
 */

function generateRingImageContent(textToDisplay = "", color = "blue") {
	return () => (
		<CardImage>
			<div className={`circle-wrapper ${color}`}>
				<div className="circle-inner">{textToDisplay}</div>
			</div>
		</CardImage>
	);
}

/*
 * This function generates a div with a segmented vertical bar
 * @param {string} textToDisplay - The text to display in the card
 * @param {object} options - Options for the bar
 * @param {number} options.activeSegments - The number of active segments
 * @param {number} options.totalSegments - The total number of segments
 * @param {object} options.colors - The colors for the bar segments
 * @param {string} options.colors.inactive - The color for inactive segments
 * @param {string} options.colors.active - The color for active segments
 * @returns {JSX.Element}
 */

function generateBarImageContent(
	textItems = "",
	{ activeSegments = 0, colors = { active: "green", outline: "blue" }, totalSegments = 10 },
) {
	return () => (
		<CardText>
			<div className={`bar-wrapper columns is-mobile fullheight`}>
				<div className={`column is-two-thirds fullheight`}>{textItems}</div>
				<div className="bar column p-2 px-5">
					{Array.from({ length: totalSegments }, (_, index) => (
						<div
							key={index}
							className={`segment cell ${colors.outline}-outline ${index < activeSegments ? `${colors.active}` : ""}`}
						/>
					))}
				</div>
			</div>
		</CardText>
	);
}

/**
 *@typedef {Object} TabItem
 * @prop {string} tabTitle - The title of the tab
 * @prop {JSX.Element | function} content - The content to display in the tab
 * @prop {JSX.Element | function} footer - The footer to display in the tab
 */

/**
 * This function generates a multi-tab text content component. However, since footers are usually placed at the bottom of the card, we inject the footer into the main content as a sibling
 * @param {TabItem[]} tabItems - The items to display in the tabs
 * @param {object} options - Options for the tabs
 * @param {string} options.tabSizes - The size of the tabs. Can be "small", "medium", or "large"
 * @returns {JSX.Element}
 */

function generateMultiTabContent(tabItems, { tabSizes = null } = {}) {
	const [activeTabIndex, setActiveTabIndex] = React.useState(0);
	const activeTab = tabItems[activeTabIndex];

	const { content: Content, footer: Footer } = activeTab;
	return () => (
		<CardText>
			<div className="multi-tab-content">
				<div className={`tabs is-centered is-toggle ${tabSizes ? "is-" + tabSizes : ""}`}>
					<ul>
						{tabItems.map(({ tabTitle }, index) => (
							<li key={index} className={`tab-item ${activeTabIndex == index ? "is-active" : ""}`}>
								<a onClick={() => setActiveTabIndex(index)}>{tabTitle}</a>
							</li>
						))}
					</ul>
				</div>
				<Content />
				{Footer && <Footer />}
			</div>
		</CardText>
	);
}

export default function AnalyticsOverviewCards({
	isTextToWeb,
	currentSpend,
	percentcomplete,
	percentwebcomplete,
	percentstarted,
	percentengaged,
	percenterrors,
	spendLimit,
}) {
	const currentSpendFormatted = CURRENCY_FORMATTER.format(currentSpend);
	const spendLimitFormatted = spendLimit ? CURRENCY_FORMATTER.format(spendLimit) : null;

	// `parseFloat` will convert a string to a float even as long as the string starts with a number, so the "%" at the end will be ignored.
	// The api returns "--%" for no data, so we need to handle that case (NaN). We shortcircuit to 0
	const completionRate = parseFloat(0.8) || 0;
	const percentErrosParsed = parseFloat(percenterrors) || 0;
	const percentStartedParsed = parseFloat(percentstarted) || 0;
	const engageRateParsed = parseFloat(percentengaged) || 0;

	const cardsData = [
		{
			title: `Completion Rate ${isTextToWeb ? "(Web)" : "(Text)"}`,
			titleTooltip: "Completion Rate is based on the number of attempted texts vs closed conversations.",
			Footer: generateRingContentFooterLabels([
				{ text: ">0.7%", iconClassname: "has-text-success" },
				{ text: "0.5% - 0.7%", iconClassname: "has-text-warning" },
				{ text: "<0.5%", iconClassname: "has-text-danger" },
			]),
			Image: generateRingImageContent(
				`${isTextToWeb ? percentwebcomplete : percentcomplete}`,
				completionRate > 0.7 ? "green" : completionRate > 0.49 ? "yellow" : "red",
			),
		},
		{
			title: "Cost",
			size: "is-one-quarter-desktop is-half-tablet",
			Text: generateBarImageContent(
				<div className="has-text-left fullheight is-flex is-flex-direction-column">
					<p className="is-size-5 strong-emphasis-indent">
						Budget Cap: <strong>{spendLimit ? spendLimitFormatted : "None Set"}</strong>
					</p>
					<p className="is-size-5 strong-emphasis-indent">
						Current spend: <strong>{currentSpendFormatted}</strong>
					</p>
				</div>,
				{
					activeSegments: spendLimit ? Math.round((currentSpend / spendLimit) * 10) : 0,
				},
			),
		},
		{
			title: "Message Health",
			size: "is-half-desktop",
			Text: generateMultiTabContent([
				{
					tabTitle: "Error Rate",
					content: generateRingImageContent(
						`${percenterrors}`,
						percentErrosParsed < 10 ? "green" : percentErrosParsed >= 15 ? "red" : "yellow",
					),
					footer: generateRingContentFooterLabels([
						{ text: "<10%", iconClassname: "has-text-success" },
						{
							text: "10% - 14.99%",
							iconClassname: "has-text-warning",
						},
						{ text: "15% +", iconClassname: "has-text-danger" },
					]),
				},
				{
					tabTitle: "Started Rate",
					content: generateRingImageContent(
						`${percentstarted}`,
						percentStartedParsed < 50 ? "green" : percentStartedParsed < 75 ? "yellow" : "red",
					),
					footer: generateRingContentFooterLabels([
						{ text: "<50%", iconClassname: "has-text-success" },
						{
							text: "50% - 74.99%",
							iconClassname: "has-text-warning",
						},
						{ text: "75% +", iconClassname: "has-text-danger" },
					]),
				},
				{
					tabTitle: "Engagement Rate",
					content: generateRingImageContent(
						`${percentengaged}`,
						engageRateParsed >= 8 ? "green" : engageRateParsed >= 4 ? "yellow" : "red",
					),
					footer: generateRingContentFooterLabels([
						{ text: "8% +", iconClassname: "has-text-success" },
						{
							text: "4% - 7.99%",
							iconClassname: "has-text-warning",
						},
						{ text: "<4%", iconClassname: "has-text-danger" },
					]),
				},
			]),
		},
	];

	return (
		<div className="columns is-multiline is-centered cards">
			{cardsData.map(({ Image = null, Text = null, Footer = null, ...card }, index) => {
				return (
					<div className={`column ${card.size ? card.size : "is-two-fifths"}`} key={index}>
						<CardItem className="analytics-card fullheight" {...card}>
							{Image && <Image />}
							{Text && <Text />}
							{Footer && <Footer />}
						</CardItem>
					</div>
				);
			})}
		</div>
	);
}
