import React, { Component } from "react";
import { connect } from "react-redux";
import { Notification } from "../Notification";

class CampaignPausedNotification extends Component {
	render() {
		const { campaignid, type, campaigns } = this.props;
		const campaign = campaigns.find((c) => c.campaignid === campaignid);

		return (
			<Notification {...this.props}>
				The campaign <b>{campaign.name}</b> has been paused.
			</Notification>
		);
	}
}

const mapStateToProps = (state) => ({
	campaigns: state.NAID.campaigns,
});

export default connect(mapStateToProps)(CampaignPausedNotification);
