import React, { useState } from "react";

import { Labels } from "../generic/Labels";
import { Label } from "../generic/Label";
/**
 * Sorts a list of objects by one or more fields, in the order selected.
 * Also displays them as bubbles with a x bubble at the end to clear.
 * @param {object} props
 * @param {array} props.list
 * @param {Array<string>} props.fieldList
 * @param {function} props.render
 * @param {string} props.defaultField
 * @returns {JSX.Element}
 */
function MultiSortingDisplay({ list, fieldList, render, defaultField }) {
	const [fields, setFields] = useState([]);

	function addField(f) {
		if (!fields.includes(f) && fieldList.includes(f)) {
			setFields([...fields, f]);
		}
	}

	const len = fields.length;

	return (
		<div>
			<label className="label">Sort strata by:</label>
			<Labels>
				{fields.map((f) => (
					<Label className="is-rounded" key={`key-${f}`}>
						{f}
					</Label>
				))}
				{len > 0 && (
					<span onClick={() => setFields([])} className={"is-rounded is-clickable is-size-7 pl-3 pb-2 "}>
						<b>clear all</b>
					</span>
				)}
				{len === 0 && <Label className={"is-rounded"}>{defaultField}(default)</Label>}
			</Labels>
			{render(
				list.sort((a, b) => {
					// Default sorting if none is selected
					if (len === 0) {
						return a[defaultField] - b[defaultField];
					}

					for (let f of fields) {
						// Compare each field until unequal, in order
						if (a[f].localeCompare(b[f])) {
							return a[f].localeCompare(b[f]);
						}
					}
					return 0;
				}),
				addField,
			)}
		</div>
	);
}

export default MultiSortingDisplay;
