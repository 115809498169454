import { SERVER_URLROOT } from "../../util/constants";
import { globalHTTPResponseHandler } from "../FetchResponseManager/actions";
import { setCampaignDataUpdated } from "../SelectedCampaign/actions";
import {
	GENERATE_STRATA_FROM_FILE_HAS_ERROR,
	GENERATE_STRATA_FROM_FILE_IS_LOADING,
	GENERATE_STRATA_FROM_FILE_POST_SUCCESS,
} from "./types";

export function generateStrataTableFromFile(file, campaignid) {
	return (dispatch, getState) => {
		let myHeaders = new Headers();
		myHeaders.append("accept", "application/json");
		myHeaders.append("Content-Disposition", "form-data;");

		let formData = new FormData();
		formData.append("file", file);

		// NOTE: This won't work
		// formData.append("str", jsonBody);
		const state = getState();

		formData.append("campaignid", state.NAID.selectedCampaignIndex);
		formData.append("jwt", state.auth.jwt);

		const init = {
			method: "POST",
			headers: myHeaders,
			body: formData,
		};

		dispatch(generateStrataFromFileLoading(true));

		fetch(SERVER_URLROOT + "/generateStrataTableFromFile", init)
			.then((response) => {
				dispatch(globalHTTPResponseHandler(response, "generateStrataFromFile"));

				if (!response.ok) {
					// response.json().then(data => {
					//   let msg = response.statusText;
					//   if (data.message) {
					//     msg = data.message;
					//   } else if (data.error) {
					//     msg = data.error;
					//   }

					// });
					dispatch(generateStrataFromFileHasError(response.status, response.statusText));
					throw Error(response.statusText);
				}
				return response;
			})
			.then((response) => response.json())
			.then((data) => {
				dispatch(generateStrataFromFilePostSuccess(data));
				return;
			})
			.then(() => {
				dispatch(setCampaignDataUpdated(true));
			})
			.catch((e) => {
				if (e.name === "TypeError" && e.message === "Failed to fetch") {
					dispatch(generateStrataFromFileHasError(500, "Unexpected error"));
				}
				console.log(e);
			});
	};
}

export function generateStrataFromFileLoading(loading) {
	return (dispatch) =>
		dispatch({
			type: GENERATE_STRATA_FROM_FILE_IS_LOADING,
			loading,
		});
}

export function generateStrataFromFileHasError(status, message) {
	return (dispatch) =>
		dispatch({
			type: GENERATE_STRATA_FROM_FILE_HAS_ERROR,
			status,
			message,
		});
}

export function generateStrataFromFilePostSuccess(data) {
	return (dispatch) =>
		dispatch({
			type: GENERATE_STRATA_FROM_FILE_POST_SUCCESS,
			data,
		});
}
