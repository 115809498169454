import React from "react";
import { Field, ErrorMessage } from "formik";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";

/**
 * HOT TIP! When testing this form locally, set `ENVIRONMENT=prod`. Otherwise the api
 * will use the BandwidthClientMocker and you will not see real data or behavior for
 * sample questions. (Just be careful.)
 *
 * @param {object} props The props data for the component
 * @param {array<string>} props.sampleOptions List of sample options from the script
 * @param {array<string>} props.selectedSampleOptions List of selected options
 * @param {function} props.onRegistrationQuestionSubmit Callback for saving the options
 */
const BandwidthRegistrationQuestionForm = ({ sampleOptions, selectedSampleOptions, onRegistrationQuestionSubmit }) => {
	const emptyOrMinLength = (minLength) => {
		return Yup.string().test(
			"empty-or-min-length",
			`Selected question must be either empty or ${minLength}+ characters`,
			(value) => value === undefined || value === "" || value.length >= minLength,
		);
	};

	// These questions can be empty, but Bandwidth requires a submitted question to be >=20 characters
	const minLen = 20;
	const regQValidationSchema = Yup.object().shape({
		sample1: Yup.string()
			.required("The first sample question is required")
			.min(20, `Selected question must be ${minLen}+ characters`),
		sample2: emptyOrMinLength(minLen),
		sample3: emptyOrMinLength(minLen),
		sample4: emptyOrMinLength(minLen),
		sample5: emptyOrMinLength(minLen),
	});

	// Fix ErrorMessage display behavior for select Fields.
	// See: https://stackoverflow.com/a/73391832/263900
	const initialTouchedFields = {
		sample1: true,
		sample2: true,
		sample3: true,
		sample4: true,
		sample5: true,
	};

	function onSubmit(values, { setSubmitting }) {
		setSubmitting(true);
		onRegistrationQuestionSubmit(values);
	}

	return (
		<Formik
			enableReinitialize={true}
			initialValues={selectedSampleOptions}
			validationSchema={regQValidationSchema}
			initialTouched={initialTouchedFields}
			onSubmit={onSubmit}
		>
			{({
				isSubmitting,
				values,
				// handleBlur,
				// handleChange,
				// handleReset,
				handleSubmit,
				isValid,
				dirty,
				errors,
			}) => {
				return (
					<Form className="form">
						{/* Loop over all 5 questions */}
						{sampleOptions &&
							Object.keys(sampleOptions).map((qLabel, i) => {
								const qData = sampleOptions[qLabel];
								return (
									<div className="field is-grouped" key={i}>
										<div className="box is-flex-grow-1">
											<div className="control">
												<label htmlFor={qLabel} className="label">
													Sample {i + 1}
												</label>
												<div className="select">
													<Field
														as="select"
														name={qLabel}
														disabled={isSubmitting}
														key={qLabel}
													>
														{qData.map((option, i) => (
															<option key={`${qLabel}-${i}`} value={option.value}>
																{option.questionLabel}
															</option>
														))}
													</Field>
												</div>
												<ErrorMessage
													name={qLabel}
													component="div"
													className="help is-danger"
												/>
												<div className="mt-3">{values[qLabel]}</div>
											</div>
										</div>
									</div>
								);
							})}

						<BulmaButtonProper
							disabled={isSubmitting || !dirty || !isValid}
							loading={isSubmitting}
							type="submit"
							customClass={isValid ? "is-success" : "is-danger"}
						>
							Save
						</BulmaButtonProper>

						{/* Display form values for debugging */}
						{/* <pre>
              Formik state:{" "}
              {JSON.stringify({ isSubmitting, values, dirty, isValid, errors }, null, 2)}
            </pre> */}
					</Form>
				);
			}}
		</Formik>
	);
};

export default BandwidthRegistrationQuestionForm;
