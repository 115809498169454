import React from "react";

import { withAsyncCall } from "../higher-order/AsyncWrapper";
import { fetchUploadProcessingResults, setProcessingResultsChanged } from "../../redux/Recipients/actions";

const UploadProcessingResultsWrapper = (props) =>
	React.Children.map(props.children, (c) => React.cloneElement(c, { ...props }));

const mapStateToProps = (state) => ({
	getUploadURL: state.recipients.getUploadURL,
	confirmUpload: state.recipients.confirmUpload,
	cancelUpload: state.recipients.cancelUpload,
	processingResults: state.recipients.uploadProcessingResults,
	processingResultsChanged: state.recipients.main.processingResultsChanged,
	...state.recipients.main,
});

const asyncMethod = (campaignid) => (dispatch) => {
	dispatch(fetchUploadProcessingResults(campaignid));
	// If we don't reset this we risk the infinite loop of re-fetching
	dispatch(setProcessingResultsChanged(false));
};

export default withAsyncCall(UploadProcessingResultsWrapper, asyncMethod, mapStateToProps);
