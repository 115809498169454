import { combineReducers } from "redux";
import { loadStateReducer } from "../../util/reusableReducers";

const agentsStatus = loadStateReducer({
	AGENTS_STATUS_IS_LOADING: "loading",
	AGENTS_STATUS_HAS_ERROR: "error",
	AGENTS_STATUS_POST_SUCCESS: "success",
});

const agentsLogout = loadStateReducer({
	AGENTS_LOGOUT_IS_LOADING: "loading",
	AGENTS_LOGOUT_HAS_ERROR: "error",
	AGENTS_LOGOUT_POST_SUCCESS: "success",
});

const logoutAll = loadStateReducer({
	LOGOUT_ALL_IS_LOADING: "loading",
	LOGOUT_ALL_HAS_ERROR: "error",
	LOGOUT_ALL_POST_SUCCESS: "success",
});

export default combineReducers({ agentsStatus, agentsLogout, logoutAll });
