import React, { Component } from "react";

/** Functions as a container for forms, and includes on change methods
 * This component doesn't know about the props the child cares about.
 * It just holds the modified values
 */
class CustomForm extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.onInputChange = this.onInputChange.bind(this);
		this.clearData = this.clearData.bind(this);

		this.submit = this.submit.bind(this);
		this.updateState = this.updateState.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (!Object.keys(state).length) {
			return {
				...props.initState,
			};
		}
		return null;
	}

	clearData(e) {
		this.setState(this.initState);
	}

	// Super simple
	onInputChange(e) {
		this.setState({
			dirty: true,
			data: {
				[e.target.name]: e.target.value,
			},
		});
	}

	reduceFormValues(formElements) {
		const arrElements = Array.prototype.slice.call(formElements); //we convert elements/inputs into an array found inside form element
		//we need to extract specific properties in Constraint Validation API using this code snippet
		const formValues = arrElements
			.filter((elem) => elem.name.length > 0)
			.map((x) => {
				const { typeMismatch, patternMismatch } = x.validity;
				const { name, type, value, checked } = x;

				let finalValue = value;
				if (type === "checkbox") {
					finalValue = checked;
				}

				return {
					name,
					type,
					typeMismatch, // we use typeMismatch when format is incorrect(e.g. incorrect email)
					patternMismatch,
					value: finalValue,
					valid: x.checkValidity(),
				};
			})
			.reduce((acc, currVal) => {
				//then we finally use reduce, ready to put it in our state
				const { value, valid, typeMismatch, patternMismatch } = currVal;
				const { fieldName, requiredTxt, formatErrorTxt, patternErrorTxt } = this.state[currVal.name];
				let errMsg;

				// Assign correct error msg
				if (!valid) {
					if (typeMismatch) {
						errMsg = formatErrorTxt;
					}

					if (value === "") {
						errMsg = requiredTxt;
					}

					if (patternMismatch) {
						errMsg = patternErrorTxt;
					}
				}

				//get the rest of properties inside the state object
				//we'll need to map these properties back to state so we use reducer...
				acc[currVal.name] = {
					value,
					valid,
					typeMismatch,
					fieldName,
					requiredTxt,
					formatErrorTxt,
					patternErrorTxt,
					errMsg,
				};
				return acc;
			}, {});
		return formValues;
	}

	validateFields(formValues) {
		return !Object.keys(formValues)
			.map((x) => formValues[x])
			.some((field) => !field.valid);
	}

	updateState() {
		const formElements = this.reduceFormValues(this.form.elements);
		const valid = this.validateFields(formElements);

		this.setState({
			...formElements,
			allFieldsValid: valid,
		});
	}

	submit(e) {
		e.preventDefault();

		const formElements = this.reduceFormValues(this.form.elements);
		const valid = this.validateFields(formElements);

		if (valid) {
			this.props.submit(formElements);
		}

		this.setState({
			...formElements,
			allFieldsValid: valid,
		});
	}

	render() {
		return (
			<form onSubmit={this.submit} ref={(el) => (this.form = el)}>
				{this.props.render({
					state: this.state,
					submit: this.submit,
					updateState: this.updateState,
				})}
			</form>
		);
	}
}

export const txtFieldState = {
	value: "",
	valid: true,
	typeMismatch: false,
	patternMismatch: false,
	errMsg: "", //this is where our error message gets across
};

/**
 * The form-state returned by submit has all sorts of fields we don't need
 * this turns it into a simple objects { key: value }
 * @param { Object } raw The original raw form state
 * @returns The clean form values in an object
 */
export const cleanFormState = (raw) => {
	let result = {};
	for (const k in raw) {
		result[k] = raw[k].value;
	}

	return result;
};

export const ErrorMsg = ({ errorMsg }) => (errorMsg ? <p className="help">{errorMsg}</p> : null);

export default CustomForm;
