import React from "react";
import { filterSample } from "../../util/helper";
import {
	PlusButtonCircle,
	PlusButton,
	MinusButton,
	TimesCircleButton,
	TimesButton,
	UpButton,
	DownButton,
	PasteButton,
	CopyButton,
} from "../generic/RoundButtons/RoundButtons";
import gsm from "gsm";
import AddQuestion from "./AddQuestion";
import SyntaxChecker from "./SyntaxChecker";
import ConnectedMediaSelectDropdown from "../MediaList/ConnectedMediaSelect";

export const QuestionWrapper = ({
	q,
	qIndex,
	list,
	phones,
	clipboard,
	warnings,
	validated,
	displayIndex,
	addQuestion,
	deleteQuestion,
	moveQuestion,
	handleQuestionIdChange,
	handleQuestionTypeChange,
	handleQuestionTextChange,
	handleQuestionMediaChange,
	copyQuestionToClipboard,
	disableUp = false,
	disableDown = false,
	pasteQuestion,
	children,
	lockId,
	lockType,
	lockQuestionText,
	showPanelQuestions,
	questionFilterFunction,
	questionTypeList,
}) => {
	var sampleQuestion = "";
	var sampleAnswers = "";
	if (phones.length > 0 && displayIndex !== undefined) {
		// Here is one of two places where we'll need to have a randomization.
		// Right now we only have the first 5 phone numbers' data loaded to save memory
		// so perhaps we should load more? We don't want to load them all so it's not
		// clear how we'll get a "good" random choice, whatever "good" means.
		const replacements = list[phones[displayIndex]].misc;
		sampleQuestion = filterSample(q.question, replacements);
		if (q.type === "closed") {
			sampleAnswers += `\n[${q.answers
				.map((a) => filterSample(a.text, replacements))
				.filter((a) => a.length)
				.join(", ")}]`;
		}
	} else {
		sampleQuestion = "No Phone List to Sample!";
	}
	var parts = gsm(sampleQuestion);

	return (
		<div key={qIndex} id={`question-${q.id}`}>
			<hr />
			<article className="media">
				<div className="media-content">
					<div className="question-header">
						<input
							type="text"
							className="textarea question-id"
							rows="1"
							value={q.id}
							onChange={(e) => handleQuestionIdChange(qIndex, e)}
							disabled={lockId}
							id={`question_id_input_${qIndex}`}
						/>

						{warnings && validated && <p className="help is-danger">{warnings.localScript[qIndex].id}</p>}
						<select
							name="question-type"
							className="question-type"
							value={q.type}
							onChange={(e) => handleQuestionTypeChange(qIndex, e)}
							disabled={lockType}
						>
							{questionTypeList.map((item) => (
								<option value={item.value}>{item.text}</option>
							))}
						</select>
					</div>
					<textarea
						className="textarea"
						value={q.question}
						onChange={(e) => handleQuestionTextChange(qIndex, e)}
						disabled={lockQuestionText}
					/>
					{warnings && validated && <p className="help is-danger">{warnings.localScript[qIndex].question}</p>}
					<p className="help sample">
						<span className="sample-label">Sample: </span>
						<SyntaxChecker sample={sampleQuestion} /> {sampleAnswers}
					</p>
					<p className={`help segcount ${parts.sms_count > 1 ? "warn" : ""}`}>
						{parts.sms_count} segment
						{parts.sms_count !== 1 ? "s" : ""}
						<span className={`charsleft ${parts.chars_left <= 16 ? "warn" : ""}`}>
							{" "}
							({parts.chars_left} chars left until {parts.sms_count + 1} segments)
						</span>
						<span
							title="Note that some characters will be automatically converted (such as curly quotes to straight quotes)"
							className={`charset ${parts.char_set !== "GSM 03.38" ? "warn" : ""}`}
						>
							{" "}
							[{parts.char_set}]
						</span>
					</p>
					{children}
					<br />
					<ConnectedMediaSelectDropdown
						onChangeHandler={(e) => {
							handleQuestionMediaChange(qIndex, e.target.value);
						}}
						value={q.s160MediaId}
					/>
				</div>
				<div className="media-right">
					<TimesCircleButton
						callback={() => deleteQuestion(qIndex)}
						className="danger"
						classSize="is-medium"
						label="Delete This Question"
						id={`delete-question-button-${q.id}`}
					/>
					<br />
					<UpButton
						callback={() => moveQuestion(qIndex, -1)}
						className={disableUp ? "grey" : "info"}
						classSize="is-medium"
						disabled={disableUp}
						label="Move This Question Up"
						id={`up-question-button-${q.id}`}
					/>
					<br />
					<DownButton
						callback={() => moveQuestion(qIndex, 1)}
						className={disableDown ? "grey" : "info"}
						classSize="is-medium"
						disabled={disableDown}
						label="Move This Question Down"
						id={`down-question-button-${q.id}`}
					/>
					<br />
					<AddQuestion
						addQuestion={addQuestion}
						qid={q.id}
						showAdminQuestionTypes={showPanelQuestions}
						qIndex={qIndex}
					/>
					<br />
					<CopyButton
						callback={() => copyQuestionToClipboard(qIndex)}
						className="primary"
						classSize="is-small"
						label="Copy This Question"
						id={`copy-question-button-${q.id}`}
					/>
					{clipboard && (
						<>
							<br />
							<PasteButton
								callback={() => pasteQuestion(qIndex + 0.5)}
								className="primary"
								classSize="is-small"
								id={`paste-question-button-${q.id}`}
							/>
						</>
					)}
				</div>
			</article>
		</div>
	);
};
