import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { startOptoutExport } from "../../redux/OptoutList/actions";
import { Optout } from "./Optout";

const OptoutContainer = ({ error, loading, startOptoutExport }) => <Optout startOptoutExport={startOptoutExport} />;

const mapStateToProps = (state) => ({
	optoutList: state.optoutList,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			startOptoutExport,
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(OptoutContainer);
