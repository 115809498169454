import React, { Component } from "react";

// import AddUserModalAuthy from "./AuthyCreateUser/AddUserModalAuthy";
// import AddUserModal from "./modals/AddUserModal";
import ChangeUserPermissionsModal from "../components/modals/ChangeUserPermissionsModal";

import { Label } from "./generic/Label";

import { UserFilterLabels } from "../components/UserFilterLabels";
import { USER_ROLES_LOOKUP_TABLE } from "../util/constants";

import { UserCampaignLabels } from "../components/UserCampaignLabels";
import CreateUserV2Form from "./CreateUserV2/CreateUserForm";

const filterCampaign = React.createRef();
const filterRole = React.createRef();
const filterName = React.createRef();

class UserFilters extends Component {
	constructor(props) {
		super(props);

		this.state = {
			changed: false,
			filters: {
				campaigns: [],
				roles: [...USER_ROLES_LOOKUP_TABLE.slice(0, 3), USER_ROLES_LOOKUP_TABLE[4]],
				name: "",
			},
			campaignOptions: [],
			roleOptions: [],
		};

		this.addCampaignFilter = this.addCampaignFilter.bind(this);
		this.filter = this.filter.bind(this);
		this.filterUserId = this.filterUserId.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.addNameFilter = this.addNameFilter.bind(this);
		this.addRoleFilter = this.addRoleFilter.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		var lookup = USER_ROLES_LOOKUP_TABLE;
		// A superuser can create any role.
		// Organization Admins and Campaign Admins can only create lesser roles.
		if (props.permissionlevel !== 0) {
			lookup = lookup.slice(props.permissionlevel + 1);
		}
		let newState = null;
		if (props.campaigns.length > 0) {
			newState = {
				campaignOptions: props.campaigns.map((item, ind) => {
					return (
						<option key={ind} value={item.campaignid}>
							{item.name}
						</option>
					);
				}),
			};
		}

		if (props.userData.userData.users.length > 0) {
			// The roleOptions can only be ...
			newState = {
				...newState, // If there's campaigns add them
				roleOptions: lookup.map((item, ind) => {
					return (
						<option key={ind} value={item}>
							{item}
						</option>
					);
				}),
			};
		}

		return newState;
	}

	componentDidMount() {
		//this.props.refreshOrganizationData();
	}

	filter(item) {
		const campaignids = item.campaignids;
		const role = item.permission;

		if (!this.state.filters.campaigns.length && !this.state.filters.roles.length) {
			return true;
		}

		if (
			this.state.filters.campaigns.length &&
			// this.state.filters.campaigns.filter(id => campaignids.includes(parseInt(id)))
			// .length = campaignids.length
			campaignids.filter((id) => this.state.filters.campaigns.includes(id + "")).length
		) {
			return true;
		}

		// This should be inclusive so it works.
		if (this.state.filters.roles.length && this.state.filters.roles.includes(role)) {
			return true;
		}

		// Add name match
		return false;
	}

	filterUserId(item) {
		const id = item.userid;
		if (id && id.includes(this.state.filters.name)) {
			return true;
		}
	}

	filterOrganizationId(organizationid, item) {
		const id = item.userid;
		if (organizationid == null || item.organizationid == organizationid) {
			return true;
		}
	}

	clearFilters() {
		this.setState({
			filters: {
				campaigns: [],
				roles: [],
				name: "",
			},
		});
	}

	addCampaignFilter() {
		if (this.state.filters.roles.includes(filterCampaign.current.value)) {
			return;
		}

		this.setState({
			filters: {
				...this.state.filters,
				campaigns: [...this.state.filters.campaigns, filterCampaign.current.value],
			},
		});
		filterCampaign.current.value = filterCampaign.current[0].value;
	}

	addRoleFilter() {
		if (this.state.filters.roles.includes(filterRole.current.value)) {
			return;
		}
		this.setState({
			filters: {
				...this.state.filters,
				roles: [...this.state.filters.roles, filterRole.current.value],
			},
		});
		filterRole.current.value = filterRole.current[0].value;
	}

	addNameFilter() {
		this.setState({
			filters: { ...this.state.filters, name: filterName.current.value },
		});
	}

	render() {
		const { campaigns, deleteUser, userDataUpdated, organizationid, permissionlevel, userid } = this.props;
		const { userData, addUserToCampaign, removeUserFromCampaign, updateUserPermissions } = this.props.userData;
		const { campaignOptions, roleOptions } = this.state;
		const list = userData.users
			.filter((user) => this.filter(user))
			.filter((user) => this.filterUserId(user))
			.filter((user) => this.filterOrganizationId(organizationid, user))
			// Maybe map this in getDerivedState
			.map((item, ind) => {
				const userCampaigns = this.props.campaigns.filter((campaign) =>
					item.campaignids.includes(campaign.campaignid),
				);
				return {
					...item,
					name: item.userid,
					// If the logged-in-user is weaker than or equal to the item-user then there's no button to change the role.
					role:
						item.permissionlevel <= permissionlevel ? (
							item.permission
						) : (
							<ChangeUserPermissionsModal
								key={ind}
								roleOptions={roleOptions}
								userRole={item.permission}
								loading={updateUserPermissions.loading}
								userid={item.userid}
								userDataUpdated={userDataUpdated}
							/>
						),
					// If the logged-in-user is stronger than the item-user then that user can be deleted.
					actions: (
						<button
							className="button is-danger"
							disabled={permissionlevel > item.permissionlevel || item.userid == userid}
							onClick={() => {
								deleteUser(item.userid);
							}}
						>
							Delete
						</button>
					),
					campaigns: item.permissionlevel ? (
						<UserCampaignLabels
							userPermissionLevel={item.permissionlevel}
							userDataUpdated={userDataUpdated}
							addUserToCampaign={addUserToCampaign}
							removeUserFromCampaign={removeUserFromCampaign}
							userid={item.userid}
							userorganizationid={item.organizationid}
							campaigns={campaigns}
							userCampaigns={userCampaigns}
						/>
					) : (
						<Label key={`campaign-all-${ind}`} className="is-medium is-rounded is-primary">
							All Campaigns
						</Label>
					),
				};
			});

		const filterCampaignNames = this.state.filters.campaigns.map(
			(id) => campaigns.find((item) => item.campaignid + "" === id).name,
		);
		return (
			<React.Fragment>
				<h1 className="title is-3">User Management</h1>
				<div className="tile is-parent is-vertical box wide">
					<div className="tile is-child ">
						<div className="field is-grouped">
							<CreateUserV2Form
								organizationList={this.props.organizationarray}
								permissionlevel={this.props.permissionlevel}
								addUser={this.props.addUser}
							/>

							<div className="control">
								<div className="select">
									<select ref={filterCampaign} onChange={this.addCampaignFilter}>
										<option>Add Campaign Filter</option>
										{campaignOptions}
									</select>
								</div>
							</div>
							<div className="control">
								<div className="select">
									<select ref={filterRole} onChange={this.addRoleFilter}>
										<option>Add Role Filter</option>
										{roleOptions}
									</select>
								</div>
							</div>
							<div className="control">
								<input
									className="input"
									ref={filterName}
									onChange={this.addNameFilter}
									placeholder="Filter User Id:"
								/>
							</div>
							<a onClick={this.clearFilters} className="button is-danger">
								Clear Filters
							</a>
						</div>
					</div>
					<div className="tile is-child">
						<UserFilterLabels items={[...filterCampaignNames, ...this.state.filters.roles]} />
					</div>
				</div>
				{this.props.children({
					list: list,
				})}
			</React.Fragment>
		);
	}
}

export default UserFilters;
