import React from "react";
import FileSaver from "file-saver";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { BulmaButton } from "../generic/Buttons/BulmaButton";

function downloadData(data, headers) {
	let file = "";

	// Write the headers
	headers.forEach((head) => {
		if (typeof head === "object") {
			file += '"' + head.header + '",';
		} else {
			file += '"' + head + '",';
		}
	});
	file += "\n";

	// Write the data
	data.forEach((item) => {
		headers.forEach((head) => {
			if (typeof head === "object") {
				file += item[head.accessor] !== undefined ? '"' + item[head.accessor] + '"' : '""';
			} else {
				file += item[head] !== undefined ? '"' + item[head] + '"' : '""';
			}
			file += ",";
		});
		file += "\n";
	});

	// Output buffer
	const filename = "campaign_results.csv";
	const blob = new Blob([file], {
		type: "text/csv;charset=utf-8",
	});

	FileSaver.saveAs(blob, filename);
}

export function DownloadAnalyticsData({ data, headers }) {
	return (
		<BulmaButton customClass="is-primary" onClick={() => downloadData(data, headers)}>
			Download&nbsp;
			<FontAwesomeIcon icon={faDownload} />{" "}
		</BulmaButton>
	);
}
