import React, { Component } from "react";
import { connect } from "react-redux";

import { addNotification, clearNotification } from "../../redux/GlobalNotifications/actions";
import { bindActionCreators } from "redux";
import { NotificationFactory } from "./NotificationFactory";

class GlobalNotifications extends Component {
	componentDidMount() {}

	render() {
		return (
			<div className="s160-notif-box">
				{this.props.notifications
					.filter((item) => item.visible)
					.reverse()
					.map((item, i) => (
						<NotificationFactory
							{...item}
							key={`global-notif-${i}`}
							campaigns={this.props.campaigns}
							clear={() => this.props.clearNotification(item)}
						/>
					))}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		notifications: state.globalNotifications.notifications,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addNotification: (notif) => addNotification(notif),
			clearNotification: (notif) => clearNotification(notif),
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNotifications);
