import React from "react";

/**
 * Interface for new components to add themselves to the list.
 * @param {string} strNotificationName
 * @param {Component} componentNotificationType
 * @returns true if added. False if not.
 */
export function addNotificationTypeToFactory(strNotificationName, componentNotificationType) {
	if (NOTIFICATION_TYPES[strNotificationName]) {
		// No duplicates allowed
		return false;
	}

	NOTIFICATION_TYPES[strNotificationName] = componentNotificationType;
	return true;
}

/**
 * The object looks like { stringIdentifyer: NotifComponentType }
 */
const NOTIFICATION_TYPES = {};

export function NotificationFactoryV2(props) {
	if (!NOTIFICATION_TYPES[props.type]) {
		return null;
	}
	const NotifComponent = NOTIFICATION_TYPES[props.type];
	return <NotifComponent {...props} />;
}
