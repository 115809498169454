import React from "react";
import { Notification } from "./Notification";

export const SuccessNotification = ({ condition, children }) => {
	if (condition) {
		return <Notification logLevel="success">{children}</Notification>;
	}

	return null;
};
