import { fetchRequest } from "../../middleware/fetchMiddleware";
import { userRequestedDownload } from "../../actions/vitals";

export const clearAgentsStatus = () => {
	return (dispatch) =>
		dispatch({
			type: "CLEAR_AGENTS_STATUS",
		});
};

export function getAgentsStatus(campaignid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("AGENTS_STATUS", "POST", "/getCurrentAgentsStatus", {
				campaignid,
			}),
		);
	};
}

export function logAgentOut(campaignid, userid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("AGENT_LOGOUT", "POST", "/releaseListAndLogout", {
				campaignid,
				userid,
				broadcast: true,
			}),
		);
	};
}

export function logoutAllAgents(campaignid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("LOGOUT_ALL", "POST", "/logoutAllAgents", {
				campaignid,
			}),
		);
	};
}
