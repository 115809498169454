import React, { Component } from "react";

import List from "../List";

import { deepCopy } from "../../util/helper";
import { LoadingSpinner } from "../LoadingSpinner";

const headers = [
	{
		header: "Phone #",
		title: "Phone Number",
		accessor: "phone",
	},
	{
		header: "Name",
		title: "Name",
		accessor: "name",
	},
];

class RecipientsSampleTable extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static getDerivedStateFromProps(props, state) {
		if (!state.list && !state.headers && props.selectedCampaignData && props.selectedCampaignData.list) {
			const loclist = props.selectedCampaignData.list;
			const phones = Object.keys(loclist);

			if (phones.length <= 0) {
				return null;
			}

			// Get the misc key values from the list
			let misc = loclist[phones[0]].misc;

			// Turn the recipient list Object into a list
			let list = phones.map((item) => {
				return {
					phone: item,
					name: loclist[item].name,
					...loclist[item].misc,
				};
			});

			/**
			 * Any empty value get's set to "-" to clean up formatting.
			 * @param {Object} miscMap Map of the misc vars
			 * @param {Array} list The actual list of recipients
			 * @returns {Array<Object>} The cleaned up list with " - " instead of ""
			 */
			const fillEmptyValuesWithDashes = (miscMap, list) => {
				const miscKeys = Object.keys(miscMap);
				return list.map((item) => {
					let newItem = { ...item };
					miscKeys.forEach((key) => {
						if (newItem[key].trim() === "") {
							newItem[key] = " - ";
						}
					});
					return newItem;
				});
			};

			list = fillEmptyValuesWithDashes(misc, list);

			// Create the headers except for existing ones
			const newHeaders = Object.keys(misc)
				.filter((key) => key !== "phone" || key !== "name")
				.map((item) => ({
					header: item,
					title: item,
					accessor: item,
				}));

			// The final column has only empty cells. So fill those with html spaces to prevent bad formatting.
			const emptyMisc = { ...list[0] };
			Object.keys(emptyMisc).forEach((key) => (emptyMisc[key] = <>&nbsp;</>));

			// ...eeeexcept for the  first cell which show the list remainging list.
			list.push({
				...emptyMisc,
				phone: `(${props.selectedCampaignData.listlength - phones.length} Additional)`,
			});

			return {
				list: list,
				headers: [...headers, ...newHeaders],
			};
		}

		return null;
	}

	// If we change campaigns while on the mainscreen then we need to update the local props.
	componentWillReceiveProps(newProps) {
		this.setState({
			localList: deepCopy(newProps.selectedCampaignData.list),
		});
	}

	render() {
		return (
			<div>
				{this.state.loading && <LoadingSpinner loading={this.state.loading} />}
				{!this.state.list && !this.state.loading && <p>No recipient list uploaded.</p>}
				{this.state.list && this.state.list.length > 1 && (
					<List headers={this.state.headers} list={this.state.list} flip={true} />
				)}

				{this.state.error && <div className="notification is-danger">{this.state.error}</div>}
			</div>
		);
	}
}

export default RecipientsSampleTable;
