import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

/**
 * Sets up a component with an async
 * @param {Component} Component The Wrapped component that needs the async data
 * @param {function} asyncCallback Redux action that starts the ball rolling
 * @param {List} params Still not sure how to deal with this. If the update needs a param then pass it here.
 */
export function withAsyncCall(WrappedComponent, action, mapStateToProps) {
	class AsyncContainer extends Component {
		constructor(props) {
			super(props);

			this.state = {
				changed: false,
			};

			this.setChanged = this.setChanged.bind(this);
			this.changeParams = this.changeParams.bind(this);
		}

		componentDidMount() {
			const { loading, error, initParams } = this.props;
			// console.log("Mount Async Wrapper props:", this.props);
			if (!loading && !error) {
				// If there are init params let's make them the params
				// in case we want to make the same request later without
				// changing them (using the init params)
				//
				// NOTE: This allows the caller to not know or care about
				// what params where initially passed. It can just call
				// setChanged without worry.
				if (initParams) {
					this.setState(
						{
							params: initParams,
						},
						this.props.asyncCallback(initParams),
					);
				} else {
					this.props.asyncCallback(initParams);
				}
			}
		}

		componentDidUpdate() {
			const { loading, error } = this.props;

			if (!loading && !error && this.state.changed) {
				// If params is undefined it should be fine
				//console.log("Update Async Wrapper");
				this.props.asyncCallback(this.state.params);

				this.setState({
					changed: false,
				});
			}
		}

		/**
		 * If the async call requires params then this will update them
		 * @param {*} params Parameters for the async call
		 */
		changeParams(params) {
			this.setState({
				params: params,
				changed: true,
			});
		}

		/**
		 * Set changed to true. Causes HOC to reload data.
		 */
		setChanged() {
			this.setState({
				changed: true,
			});
		}

		/**
		 * Render component wether or not data is ready. Handle all rendering
		 * in sub component.
		 */
		render() {
			return <WrappedComponent setChanged={this.setChanged} changeParams={this.changeParams} {...this.props} />;
		}
	}

	const mapDispatchToProps = (dispatch) => {
		return bindActionCreators(
			{
				asyncCallback: (params) => {
					return action.apply(null, params);
				},
			},
			dispatch,
		);
	};

	return connect(mapStateToProps, mapDispatchToProps)(AsyncContainer);
}
