import React, { Component } from "react";
import PropTypes from "prop-types";

// Needs to be generic. In other words, never specific to this app
class Tabs extends Component {
	constructor(props) {
		super(props);

		// Start with 0
		this.state = {
			current: "",
		};
	}

	componentDidMount() {
		this.setState({
			current: this.props.defaultTab,
		});
	}

	switchTab(newTab) {
		this.setState({ current: newTab });
	}

	render() {
		return (
			<div>
				<div
					className={`tabs is-${this.props.alignment} is-${this.props.size} is-${this.props.style} is-${this.props.rounded ? "toggle-rounded" : ""}`}
				>
					<ul>
						{React.Children.map(this.props.children, (child) => (
							<li
								key={"tab-" + child.props.tabName}
								className={this.state.current === child.props.tabName ? "is-active" : ""}
							>
								{!this.props.disabled && (
									<a onClick={() => this.switchTab(child.props.tabName)}>{child.props.tabName}</a>
								)}
							</li>
						))}
					</ul>
				</div>
				{React.Children.toArray(this.props.children).filter(
					(child) => child.props.tabName === this.state.current,
				)}
			</div>
		);
	}
}

Tabs.defaultProps = {
	alignment: "centered",
	size: "medium",
	style: "",
};

Tabs.propTypes = {
	alignment: PropTypes.string,
	size: PropTypes.string,
	style: PropTypes.string,
};

export default Tabs;
