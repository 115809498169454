import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Redirect, Link } from "react-router-dom";

import { bindActionCreators } from "redux";
import { authPost } from "../../redux/Auth/actions";
import { getCookies, isEmail, isCleanPhone, removeCookieList } from "../../util/helper";

/**
 * Login Modal
 */
class LoginModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: true,
			previousResult: "",
		};

		this.attemptLogin = this.attemptLogin.bind(this);
		this.toggleOpen = this.toggleOpen.bind(this);
	}

	toggleOpen() {
		this.setState({ isOpen: !this.state.isOpen });
	}

	componentDidMount() {
		// Look for cookies
		const { userid, jwt } = getCookies();
		if (
			jwt !== "" &&
			userid !== "" &&
			jwt !== "crap" &&
			userid !== "crap" &&
			jwt !== undefined &&
			userid !== undefined
		) {
			// Get the vitals and issue a success.
		} else {
			removeCookieList(["jwt", "userid"]);
		}
	}

	attemptLogin(e) {
		// Testing authentication.
		// If we authenticate we get a jwt.
		e.preventDefault();
		const adminid = this.adminid.value.trim();

		// ID can be phone, email, or username
		let email, phone, userid;
		if (isEmail(adminid)) {
			email = adminid;
		} else if (isCleanPhone(adminid)) {
			phone = adminid;
		} else {
			userid = adminid;
		}

		const claimedpassword = this.claimedpassword.value.trim();
		if (!this.props.loading) {
			this.props.authPost(userid, phone, email, claimedpassword.trim());
		}
	}

	render() {
		const { userid, jwt, error, loading, message, email, matchedPassword } = this.props.auth;

		if (userid !== "" && jwt !== "" && !error && !loading) {
			return <Redirect to={this.state.from || "/"} />;
		}

		if (matchedPassword && email !== "") {
			return <Redirect to="/authy-login" />;
		}

		return (
			<Modal isOpen={this.state.isOpen} className={"modal" + (this.state.isOpen ? " is-active" : "")}>
				<div className="modal-background" />
				<form onSubmit={this.attemptLogin}>
					<div className="modal-card">
						<header className="modal-card-head">Please Log In</header>
						<section className="modal-card-body">
							<div className="field">
								<label className="label">Admin ID</label>
								<input
									className="input"
									type="text"
									name="ID"
									placeholder="userid / phone / email"
									autoFocus={true}
									ref={(adminid) => {
										this.adminid = adminid;
									}}
								/>
							</div>
							<div className="field">
								<label className="label">Password</label>
								<input
									className="input"
									type="password"
									autoComplete="on"
									name="claimedpassword"
									placeholder="claimedpassword"
									ref={(claimedpassword) => {
										this.claimedpassword = claimedpassword;
									}}
								/>
							</div>

							{error && <div className="notification is-danger">Login Failed: {message}</div>}

							<p className="help">{""}</p>
						</section>
						<footer className="modal-card-foot">
							<button className={"button is-info" + (loading ? " is-loading" : "")} type="submit">
								Log In
							</button>
						</footer>
					</div>
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			authPost,
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(LoginModal);
