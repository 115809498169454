import React from "react";

export default function CardItem({ title = "", className = "", titleTooltip = "", children }) {
	return (
		<div className={`card ${className}`}>
			<header className="card-header" title={titleTooltip}>
				<p className="card-header-title is-centered">
					{titleTooltip ? (
						<span
							style={{
								borderBottom: "0.18rem dotted",
								cursor: "help",
							}}
							title={titleTooltip}
						>
							{title}
						</span>
					) : (
						title
					)}
				</p>
			</header>

			{children}
		</div>
	);
}

export function CardText({ children, classname }) {
	return <div className={`card-content ${classname}`}>{children}</div>;
}

export function CardImage({ children, classname }) {
	return <div className={`card-image py-3 ${classname}`}>{children}</div>;
}

export function CardFooter({ items = [] }) {
	return (
		<div className="card-footer">
			{items.map((text, index) => (
				<div className="card-footer-item" key={index}>
					{text}
				</div>
			))}
		</div>
	);
}
