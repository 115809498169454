// Displays the analytics via AnalyticsTable.
// This includes the checkboxes which manage the analytics as well as the download of those analytics.
// This shows organizations, campaigns, and agents that the user has access to.
// These are retrieved from the variables organizations, campaigns, users which come via props from AnalyticsContainer.

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { updateUserAnalyticsCheckboxConfig } from "../../redux/Users/actions";
import { vitalsDataPost } from "../../actions/vitals";
import { FieldVisibilityTable } from "./FieldVisibilityTable";
import Tabs from "../tabs/NewTabs";

import { HEADERS, CHECKBOX_VISIBILITY_TABLE_DEFAULTS } from "./constants";
import AnalyticsTable from "./AnalyticsTable";
import AnalyticsOverview from "./AnalyticsOverview.container";
class Analytics extends Component {
	constructor(props) {
		super(props);

		this.state = {
			localCheckboxConfig: props.analyticscheckboxconfig || CHECKBOX_VISIBILITY_TABLE_DEFAULTS,
			dirty: false,
		};
		this.handleClick = this.handleClick.bind(this);
		this.update = this.update.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.analyticscheckboxconfig && !state.localCheckboxConfig) {
			return {
				localCheckboxConfig: props.analyticscheckboxconfig,
			};
		}
		return null;
	}

	handleClick(e) {
		var acc = { ...this.state.localCheckboxConfig };
		acc[e.target.name] = e.target.checked;

		this.setState({ localCheckboxConfig: acc });
	}

	update() {
		// Could this be done in Redux?
		this.props.updateUserAnalyticsCheckboxConfig(this.state.localCheckboxConfig).then(() => {
			this.props.vitalsDataPost();
			this.setState({ localCheckboxConfig: {} });
		});
	}

	render() {
		const {
			tabs,
			selectedOrganizationId,
			selectedCampaignId,
			scope,
			showSnapshots,
			showFieldTable,
			showClipboardButton,
			verticalView,
			headerOrder,
			showCampaignOverview,
		} = this.props;

		let fieldVisibilityTable;
		if (showFieldTable) {
			fieldVisibilityTable = (
				<FieldVisibilityTable
					handleClick={this.handleClick}
					update={this.update}
					headers={HEADERS}
					checkboxes={this.state.localCheckboxConfig}
				/>
			);
		}

		// Map through the tabs and create a new array of AnalyticsTable components. These will always be shown
		// After, if showCampaignOverview is true and we're displaying campaign info, add the overview tab to the beginning of the list
		const tabsToShow = tabs.map((tab) => (
			<AnalyticsTable
				key={"tab-container-" + tab.name}
				dataName={tab.dataName}
				tabName={tab.name}
				analyticscheckboxconfig={this.state.localCheckboxConfig}
				scope={scope}
				headers={HEADERS}
				organizationid={selectedOrganizationId}
				campaignid={selectedCampaignId}
				// Date range picker
				showSnapshots={showSnapshots}
				showClipboardButton={showClipboardButton}
				verticalView={verticalView}
				headerOrder={headerOrder}
			>
				{fieldVisibilityTable}
			</AnalyticsTable>
		));

		if (showCampaignOverview && scope !== "organization")
			// Add the overview tab to the beginning of the list
			tabsToShow.unshift(
				<AnalyticsOverview
					key={"tab-container-dashboard"}
					tabName="overview"
					data={this.props.data}
					scope={scope}
					campaignid={selectedCampaignId}
				/>,
			);

		return (
			<div className="analytics">
				<div className="tile is-ancestor">
					<div className="tile is-parent is-vertical is-12">
						<div className="tile is-child " />
						<Tabs
							defaultTab={showCampaignOverview ? "overview" : tabs[0].name}
							size="medium"
							style="boxed"
							alignment="left"
						>
							{tabsToShow}
						</Tabs>
					</div>
				</div>
			</div>
		);
	}
}

Analytics.defaultProps = {
	showSnapshots: true,
	showFieldTable: true,
	showClipboardButton: true,
	verticalView: true,
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateUserAnalyticsCheckboxConfig,
			vitalsDataPost,
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(Analytics);
