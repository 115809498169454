import { fetchRequest } from "../middleware/fetchMiddleware";

export const ORG_IS_LOADING = "ORG_IS_LOADING";
export const ORG_HAS_ERROR = "ORG_HAS_ERROR";
export const ORG_GET_SUCCESS = "ORG_GET_SUCCESS";

export function addOrganizationPost(organizationname) {
	return (dispatch, getState) => {
		const params = {
			organizationname: organizationname,
			jwt: getState().auth.jwt,
		};

		return dispatch(fetchRequest("ADD_ORGANIZATION", "POST", "/addorganization", params));
	};
}

export function orgIsLoading(isLoading) {
	return (dispatch) => {
		dispatch({
			type: ORG_IS_LOADING,
			isLoading: isLoading,
		});
	};
}

export function orgHasError(hasError) {
	return (dispatch) => {
		dispatch({
			type: ORG_HAS_ERROR,
			hasError: hasError,
		});
	};
}

export function orgSuccess(orgs) {
	return (dispatch) => {
		dispatch({
			type: ORG_GET_SUCCESS,
			organizations: orgs,
		});
	};
}

export function getCampaignDataDefaults(orgid) {
	return (dispatch, getState) => {
		return dispatch(fetchRequest("CAMPAIGN_DATA_DEFAULTS", "GET", `/organization/${orgid}/defaults`));
	};
}

export function updateCampaignDataDefaultsPost(orgid, costPerAgentHour, costPerSMS, currency) {
	return (dispatch, getState) => {
		return dispatch(
			fetchRequest("UPDATE_CAMPAIGN_DATA_DEFAULTS", "POST", `/organization/${orgid}/defaults`, {
				costPerAgentHour,
				costPerSMS,
				currency,
				organizationid: orgid,
			}),
		);
	};
}

export function deleteCampaignDataDefaults(orgid) {
	return (dispatch) => {
		return dispatch(fetchRequest("DEL_CAMPAIGN_DATA_DEFAULTS", "DELETE", `/organization/${orgid}/defaults`));
	};
}

export function clearOrgDefaultsData() {
	return (dispatch) =>
		dispatch({
			type: "CLEAR_DEFAULTS_DATA",
		});
}
