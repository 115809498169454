import { ANALYTICS_IS_LOADING, ANALYTICS_HAS_ERROR, ANALYTICS_DATA_SUCCESS, CLEAR_ANALYTICS_DATA } from "./actions";
import { loadStateReducer } from "../../util/reusableReducers";
import { combineReducers } from "redux";

const initialState = {
	data: {},
	final_data: {},
	loading: false,
	error: false,
};

const mainReducer = (state = initialState, action) => {
	switch (action.type) {
		case ANALYTICS_IS_LOADING:
			return {
				...state,
				loading: true,
				error: false,
			};
		case ANALYTICS_HAS_ERROR:
			return {
				...state,
				error: action.error,
				loading: false,
			};
		case ANALYTICS_DATA_SUCCESS:
			let newData = { [action.key]: action.data };

			return {
				...state,
				error: false,
				loading: false,
				data: { ...state.data, ...newData },
			};
		case CLEAR_ANALYTICS_DATA:
			return {
				...state,
				data: {},
			};
		case "CLEAR_DATA":
			return initialState;
		default:
			return state;
	}
};

const snapshots = loadStateReducer({
	SNAPSHOTS_IS_LOADING: "loading",
	SNAPSHOTS_HAS_ERROR: "error",
	SNAPSHOTS_POST_SUCCESS: "success",
});

export default combineReducers({
	current: mainReducer,
	snapshots: snapshots,
});
