import React from "react";
import Analytics from "./AnalyticsTab/Analytics";

const visibleFields = {
	campaignid: true,
	name: true,
	closed: true,
	web_closed: true,
	agentHours: true,
	start: true,
	total_segments: true,
	outgoing_segments: true,
	currentspend: true,
	open: true,
	assigned: true,
	contact: true,
	engaged: true,
	terminated: true,
	4700: true,
	4720: true,
	4750: true,
	4770: true,
};

const TABS = [{ name: "campaigns", dataName: "campaign" }];

const HEADER_ORDER = [
	"campaignid",
	"name",
	"closed",
	"web_closed",
	"agentHours",
	"start",
	"total_segments",
	"outgoing_segments",
	"currentspend",
	"open",
	"assigned",
	"contact",
	"engaged",
	"terminated",
	"4700",
	"4720",
	"4750",
	"4770",
];

function ActiveCampaignAnalyticsPage(props) {
	return (
		<div>
			<h3 className="title has-text-centered">Active Campaigns Results</h3>
			<Analytics
				tabs={TABS}
				active={"active"}
				analyticscheckboxconfig={visibleFields}
				scope="active"
				showSnapshots={false}
				showFieldTable={false}
				// showClipboardButton={false}
				verticalView={false}
				headerOrder={HEADER_ORDER}
			/>
		</div>
	);
}

export default ActiveCampaignAnalyticsPage;
