import React from "react";
import { faCheckSquare, faSquare, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Checkbox = ({ checked, description }) => (
	<div className={checked ? "has-text-success" : ""}>
		<FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} /> {description}
	</div>
);

export const WarningCheckbox = ({ description }) => (
	<div>
		<FontAwesomeIcon icon={faExclamationTriangle} className="has-text-warning" /> {description}
	</div>
);
