import React, { Component } from "react";
import Modal from "react-modal";

import { deepCopy, filterSample } from "../../util/helper";
// import { saveCampaignName } from "../../redux/SelectedCampaign/actions";

/**
 * Prompt
 */
class PromptEditorModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			// I'll use prompt but it can be prompt as well
			localPrompt: deepCopy(this.props.prompt),
			dirty: false,
		};

		this.toggleOpen = this.toggleOpen.bind(this);
		this.addQuestion = this.addQuestion.bind(this);
		this.deleteQuestion = this.deleteQuestion.bind(this);
		this.save = this.save.bind(this);
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		// debugger;
		if (
			nextProps.selectedCampaignIndex !== prevState.selectedCampaignIndex ||
			(nextProps.prompt[0] !== prevState.localPrompt[0] && !prevState.dirty) ||
			nextProps.subject !== prevState.subject
		) {
			return {
				localPrompt: nextProps.prompt,
				selectedCampaignIndex: nextProps.selectedCampaignIndex,
				dirty: false,
				subject: nextProps.subject,
			};
		}

		return null;
	}

	componentDidMount() {
		if (localStorage.hasOwnProperty("dirty") && localStorage.getItem("dirty") === "true")
			this.hydrateStateWithLocalStorage();
	}

	hydrateStateWithLocalStorage() {
		// for all items in state
		for (let key in this.state) {
			// if the key exists in localStorage
			if (localStorage.hasOwnProperty(key)) {
				// get the key's value from localStorage
				let value = localStorage.getItem(key);

				// parse the localStorage string and setState
				try {
					console.log(key, "  :   ", value);
					value = JSON.parse(value);
					this.setState({ [key]: value });
				} catch (e) {
					// handle empty string
					this.setState({ [key]: value });
				}
			}
		}
	}

	toggleOpen() {
		this.setState({
			open: !this.state.open,
		});

		localStorage.setItem("open", !this.state.open);
	}

	save() {
		if (this.state.dirty) {
			this.props.updatePrompt(this.state.localPrompt);
			this.setState({
				dirty: false,
			});
		}

		// Delete storage when data is saved
		localStorage.setItem("localPrompt", "");
		localStorage.setItem("dirty", false);
	}

	handleQuestionTextChange(i, event) {
		let lp = deepCopy(this.state.localPrompt);
		lp[i].question = event.target.value;
		this.setState({
			localPrompt: lp,
			dirty: true,
		});
		if (event.target.value.length > 160) {
			event.target.style.backgroundColor = "#ee0000";
		} else {
			event.target.style.backgroundColor = "#ffffff";
		}

		localStorage.setItem("dirty", true);
		localStorage.setItem("localPrompt", JSON.stringify(lp));
	}

	addQuestion(i) {
		let prompt = deepCopy(this.state.localPrompt);
		const emptyQuestion = {
			// defaults
			question: "",
		};
		prompt.splice(i + 0.5, 0, emptyQuestion);
		this.setState({
			localPrompt: prompt,
			dirty: true,
		});
		//debugger;

		localStorage.setItem("dirty", true);
		localStorage.setItem("localPrompt", JSON.stringify(prompt));
	}

	deleteQuestion(qIndex) {
		let prompt = deepCopy(this.state.localPrompt);
		prompt.splice(qIndex, 1);
		this.setState({
			localPrompt: prompt,
			dirty: true,
		});

		localStorage.setItem("dirty", true);
		localStorage.setItem("localPrompt", JSON.stringify(prompt));
	}

	render() {
		const { list, active } = this.props;
		const phones = Object.keys(list);

		let varsAvailable = "";
		if (phones.length > 0) {
			if (list[phones[0]].misc !== null) {
				var keys = Object.keys(list[phones[0]].misc);
				varsAvailable = "{" + keys.join("}, {") + "}";
			}
		}

		return (
			<div className="button-container">
				<button onClick={this.toggleOpen} className="button is-info">
					{this.state.localPrompt.length ? "Edit " : "Create "}
					{this.props.subject}
				</button>

				<Modal isOpen={this.state.open} className={"modal" + (this.state.open ? " is-active" : "")}>
					<div className="modal-background" onClick={this.toggleOpen} />
					<div className="modal-card">
						<header className="modal-card-head">Edit {this.props.subject}</header>
						<section className="modal-card-body">
							<div className="prompt-editor-note">
								<strong>Available variables: </strong>
								<span className="variable-list">{varsAvailable ? varsAvailable : "[none]"}</span>
							</div>
							<article className="media">
								<div className="media-content" />
								<div className="media-right">
									<button
										className="button is-rounded is-outlined is-success"
										onClick={() => this.addQuestion(-0.5)}
									>
										<span className="has-text-weight-semibold">+</span>
									</button>
								</div>
							</article>
							{this.state.localPrompt.map((q, qIndex) => {
								var sample = "";
								if (phones.length > 0 && this.props.displayIndex !== undefined) {
									// Here is one of two places where we'll need to have a randomization.
									// Right now we only have the first 5 phone numbers' data loaded to save memory
									// so perhaps we should load more? We don't want to load them all so it's not
									// clear how we'll get a "good" random choice, whatever "good" means.
									const replacements = list[phones[this.props.displayIndex]].misc;
									sample = filterSample(q.question, replacements);
								} else {
									sample = "No Phone List to Sample!";
								}
								return (
									<div key={qIndex}>
										<article className="media">
											<figure className="media-left">{qIndex + 1}</figure>
											<div className="media-content">
												<div className="field">
													<div className="control">
														<textarea
															className="textarea"
															value={q.question}
															onChange={(e) => this.handleQuestionTextChange(qIndex, e)}
														/>
														<p className="help">Sample: {sample}</p>
													</div>
												</div>
											</div>
											<div className="media-right">
												<button
													className="button is-rounded is-outlined is-danger"
													onClick={() => this.deleteQuestion(qIndex)}
												>
													<span className="has-text-weight-semibold">-</span>
												</button>

												<br />

												<button
													className="button is-rounded is-outlined is-success"
													onClick={() => this.addQuestion(qIndex + 0.5)}
												>
													<span className="has-text-weight-semibold">+</span>
												</button>
											</div>
										</article>
									</div>
								);
							})}
						</section>
						<footer className="modal-card-foot">
							<button className="button is-info" onClick={this.save}>
								Save & Close
							</button>
							<button className="button" onClick={this.toggleOpen}>
								Cancel
							</button>
							<button className="button push-right" onClick={this.props.shiftDisplayIndex}>
								Randomize Sample
							</button>
						</footer>
					</div>
				</Modal>
			</div>
		);
	}
}

export default PromptEditorModal;
