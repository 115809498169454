import { loadStateReducer } from "../util/reusableReducers";
import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

const initialState = {
	loading: false,
	error: false,
};

function mainReducer(state = initialState, action) {
	switch (action.type) {
		// DELETE THIS TOO?
		case "BILLING_POST_SUCCESS":
			return {
				...state,
				loading: false,
				data: action.data.data,
			};
		case "CHARGE_HISTORY_POST_SUCCESS":
			return {
				...state,
				chargeHistory: {
					...state.chargeHistory,
					data: action.data.data,
					loading: false,
				},
			};
		case "CURRENT_SPEND_POST_SUCCESS":
			return {
				...state,
				currentSpend: {
					...state.currentSpend,
					value: action.data.data.currentSpendAmount,
					loading: false,
				},
			};
		case "CLEAR_DATA":
			return initialState;
		default:
			return state;
	}
}

const chargeHistoryStateReducer = loadStateReducer({
	CHARGE_HISTORY_IS_LOADING: "loading",
	CHARGE_HISTORY_HAS_ERROR: "error",
});

const applyChargeStateReducer = loadStateReducer({
	APPLY_CHARGE_IS_LOADING: "loading",
	APPLY_CHARGE_HAS_ERROR: "error",
	APPLY_CHARGE_POST_SUCCESS: "success",
});

const currentSpendReducer = loadStateReducer({
	CURRENT_SPEND_IS_LOADING: "loading",
	CURRENT_SPEND_HAS_ERROR: "error",
});

export default reduceReducers(
	combineReducers({
		applyCharge: applyChargeStateReducer,
		chargeHistory: chargeHistoryStateReducer,
		currentSpend: currentSpendReducer,
	}),
	mainReducer,
);
