import { fetchRequest } from "../../middleware/fetchMiddleware";

export function getMediaList(campaignid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("MEDIA", "POST", "/displayMediaFilesForCampaign", {
				campaignid,
			}),
		);
	};
}
