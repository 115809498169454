import React from "react";
import CurrentSpendField from "./CurrentSpendField";

export const OrganizationMyAccount = ({ organizationname, organizationid }) => (
	<div>
		<div className="field">
			Organization Name
			<label className="label">{organizationname}</label>
		</div>
		<div className="field">
			Primary Contact
			<label className="label">No contact</label>
		</div>
		<div className="field">
			Spend Limit
			<label className="label">$2000</label>
		</div>
		<div className="field">
			Current Amount Spent
			<label className="label">
				$ <CurrentSpendField initParams={[organizationid]} />
			</label>
		</div>
	</div>
);
