import { loadStateReducer } from "../util/reusableReducers";
import { combineReducers } from "redux";

const initialState = {};

function mainReducer(state = initialState, action) {
	switch (action.type) {
		case "UPDATE_USER_DOWNLOAD_REQUESTS":
			let requestedDownload = [action.campaignid];
			if (state.data && state.data.requestedDownload) {
				requestedDownload = [...state.data.requestedDownload, action.campaignid];
			}
			return {
				...state,
				data: {
					...state.data,
					requestedDownload: requestedDownload,
				},
			};
		case "CLEAR_DATA":
			return initialState;
		default:
			return state;
	}
}

const vitalsLoadStateReducer = loadStateReducer({
	VITALS_IS_LOADING: "loading",
	VITALS_HAS_ERROR: "error",
	VITALS_POST_SUCCESS: "success",
});

export default (state, action) => {
	return vitalsLoadStateReducer(mainReducer(state, action), action);
};
