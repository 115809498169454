import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "react-modal";
import { BulmaButton } from "../generic/Buttons/BulmaButton";
import { addOrganizationPost } from "../../actions/organizations";
import { vitalsDataPost } from "../../actions/vitals";
import { bindActionCreators } from "redux";

class AddOrganizationModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			valid: false,
			message: "Requires Organization Name",
		};
		this.addOrganization = this.addOrganization.bind(this);
		this.toggleOpen = this.toggleOpen.bind(this);
		this.checkValid = this.checkValid.bind(this);
	}

	toggleOpen() {
		this.setState({ open: !this.state.open });
	}

	checkValid() {
		if (this.organizationname.value === "") {
			this.setState({
				valid: false,
				message: "Requires Organization Name",
			});
			return;
		}
		if (this.props.organizationarray.map((a) => a.organizationname).indexOf(this.organizationname.value) > -1) {
			this.setState({
				valid: false,
				message: "Duplicate Organization Name",
			});
			return;
		}

		this.setState({
			valid: true,
			message: "",
		});
	}

	addOrganization() {
		this.props.addOrganizationPost(this.organizationname.value).then(() => this.props.vitalsDataPost());
	}

	render() {
		return (
			<>
				<BulmaButton customClass="is-success" title="Add organization" onClick={this.toggleOpen}>
					Add Organization
				</BulmaButton>
				<Modal isOpen={this.state.open} className={"modal" + (this.state.open ? " is-active" : "")}>
					<div className="modal-background" onClick={this.toggleOpen} />
					<div className="modal-card">
						<header className="modal-card-head">Add Organization</header>
						<section className="modal-card-body">
							<label className="label">Organization Name</label>

							<div className="control">
								<input
									className="input"
									name="copyScript"
									type="organizationname"
									ref={(organizationname) => {
										this.organizationname = organizationname;
									}}
									onChange={this.checkValid}
								/>
							</div>

							<br />
							{this.state.message && <div className="notification is-danger">{this.state.message}</div>}
						</section>
						<footer className="modal-card-foot">
							<button
								className="button is-info"
								onClick={this.addOrganization}
								disabled={!this.state.valid}
							>
								Create Organization
							</button>
							<button className="button is-danger" onClick={this.toggleOpen}>
								Cancel
							</button>
						</footer>
					</div>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			addOrganizationPost: (organizationname) => addOrganizationPost(organizationname),
			vitalsDataPost,
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(AddOrganizationModal);
