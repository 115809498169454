import React from "react";
import { CloseEnded } from "./CloseEnded";
import { OpenEnded } from "./OpenEnded";
import { Closing } from "./Closing";
import { Terminating } from "./Terminating";
import { PanelOptIn } from "./PanelOptIn";
import { ClosePanelOptIn } from "./ClosePanelOptIn";
import { Intro } from "./Intro";

export const Question = (props) => {
	const selectByType = (type) => {
		switch (type) {
			case "closed":
				return <CloseEnded {...props} />;
			case "closing":
				return <Closing {...props} />;
			case "terminating":
				return <Terminating {...props} />;
			case "panel":
				return <PanelOptIn {...props} />;
			case "panel_close":
				return <ClosePanelOptIn {...props} />;
			case "intro":
				return <Intro {...props} />;
			case "open":
			default:
				return <OpenEnded {...props} />;
		}
	};

	return selectByType(props.q.type);
};
