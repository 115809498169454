import React, { Component } from "react";
import Modal from "react-modal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { updateLogins } from "../../redux/SelectedCampaign/actions";

import { createPassword, deepCopy } from "../../util/helper";

class GenerateLoginsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};

		this.generateLogins = this.generateLogins.bind(this);
		this.toggleOpen = this.toggleOpen.bind(this);
	}

	toggleOpen() {
		this.setState({ isOpen: !this.state.isOpen });
	}

	/**
	 * Generate logins to append to or replace the current list.
	 * @param {bool} replace
	 */
	generateLogins(replace) {
		var logins = [];
		var sindex = replace ? 0 : this.props.existingLogins.length;

		for (var i = 0; i < this.howManyLogins.value; i++) {
			var id = "login-" + this.props.campaignid + "-" + (sindex + i + 1);
			var password = createPassword();
			logins.push({ id: id, password: password, phonelist: [] });
		}

		this.props.updateLogins(logins, this.props.campaignid, !replace);
		this.toggleOpen();
	}

	render() {
		return (
			<>
				<a className="button is-light is-fullwidth" onClick={this.toggleOpen}>
					Generate Logins
				</a>

				<Modal isOpen={this.state.isOpen} className={"modal" + (this.state.isOpen ? " is-active" : "")}>
					<div className="modal-background" onClick={this.toggleOpen} />

					<div className="modal-card">
						<header className="modal-card-head">Generate Agent Logins</header>
						<section className="modal-card-body">
							<label className="label">How Many</label>
							<input
								type="text"
								style={{ fontSize: "16pt" }}
								ref={(howManyLogins) => {
									this.howManyLogins = howManyLogins;
								}}
							/>
						</section>
						<footer className="modal-card-foot">
							<button className="button is-info" onClick={() => this.generateLogins(true)}>
								Replace Current
							</button>

							<button className="button is-success" onClick={() => this.generateLogins(false)}>
								Append to Current
							</button>

							<button className="button" onClick={this.toggleOpen}>
								Cancel
							</button>
						</footer>
					</div>
				</Modal>
			</>
		);
	}
}
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateLogins: (newLogins, campaignid, append) => updateLogins(newLogins, campaignid, append),
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(GenerateLoginsModal);
