import React from "react";
import PropTypes from "prop-types";

/**
 * A leaf component that displays the media obejects for a campaign as
 * options in a selection dropdown.
 */
export const MediaSelectDropdown = ({ mediaObject, onChangeHandler, value }) => (
	<select className="select" onChange={onChangeHandler} disabled={mediaObject.length === 0} defaultValue={value}>
		<option>-- No Media --</option>
		{Object.keys(mediaObject).map((k, i) => {
			const item = mediaObject[k];
			return (
				<option value={k} key={`media-${item.media_name}-${i}`}>
					{item.media_name}
				</option>
			);
		})}
	</select>
);

MediaSelectDropdown.propTypes = {
	mediaObject: PropTypes.objectOf(PropTypes.object).isRequired,
	onChangeHandler: PropTypes.func.isRequired,
};
