import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { LaunchApproval } from "./LaunchApproval";
import { launchCampaign } from "../../redux/SelectedCampaign/actions";

class LaunchApprovalTools extends Component {
	render() {
		return (
			<div className="tile is-parent is-vertical">
				<h4 className="title is-4 has-text-centered">CAMPAIGN LAUNCH APPROVAL</h4>
				<div className="tile is-parent">
					<div className="tile is-child">
						{this.props.active === "prelaunch" && (
							<div className="field">
								<label className="label">Launch has been requested.</label>
							</div>
						)}
						{["active", "deactivated"].includes(this.props.active) && (
							<div className="field">
								<label className="label">Campaign is {this.props.active}</label>
							</div>
						)}
						{["notyet", "sandbox"].includes(this.props.active) && (
							<div className="field">
								<label className="label">Campaign is not yet active</label>
							</div>
						)}
						<br />
					</div>
					<div className="tile is-child is-right">
						<LaunchApproval
							{...this.props}
							active={this.props.active === "prelaunch"}
							launchCampaign={this.props.launchCampaign}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			launchCampaign: () => launchCampaign(),
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(LaunchApprovalTools);
