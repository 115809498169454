import React from "react";
import ConfirmActionModal from "../modals/ConfirmActionModal";
import PropTypes from "prop-types";

export const S160BoolCampaignOptions = ({
	selectedCampaignId,
	optionDescription,
	actionCallback,
	enabled,
	...props
}) => {
	return (
		<div>
			{optionDescription} : &nbsp;
			{enabled ? "enabled" : "disabled"}
			&nbsp;
			<ConfirmActionModal
				itemName={`campaign ${selectedCampaignId}`}
				actionName={`${enabled ? "Turn Off" : "Turn On"}`}
				buttonClass={`button is-${enabled ? "danger" : "success"}`}
				actionCallback={actionCallback}
				{...props}
			/>
		</div>
	);
};

S160BoolCampaignOptions.propTypes = {
	selectedCampaignId: PropTypes.number,
	enabled: PropTypes.bool,
};
