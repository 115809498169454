// Manages the top bar, side bar, and appropriate contents, so:
// TopBar
// SideBar
// and the following depending on the route:
//
// route = campaigns:     CollapsibleSideBar, MainScreenContainer
// route = analytics:     AnalyticsContainer
// route = twilio:        TwilioContainer
// route = billing:       BillingContainer
// route = organizations: OrganizationsContainer
// route = users:         UsersContainer

import React, { Component } from "react";
import { Switch, Route, IndexRedirect } from "react-router-dom";

import TopBar from "../components/TopBar/TopBar";
import { Organization } from "./Organization";
// import BillingContainer from "../containers/BillingContainer";
import UsersContainer from "../containers/UsersContainer";
import OrganizationsContainer from "../containers/OrganizationsContainer";
import CampaignScreenContainer from "./CampaignScreen/CampaignScreenContainer";
import { Sidebar } from "./OrgCampaignListSidebar/Sidebar";
import AddCampaignModal from "../components/modals/AddCampaignModal";

import { Welcome } from "./Welcome";
import PanelContainer from "./Panel/PanelContainer";
import TextToWebContainer from "./TextToWeb/TextToWebContainer";
import SidebarContainer from "./OrgCampaignListSidebar/SidebarContainer";
// import FilterList from "./generic/FilterList";
import FilterList from "./generic/FilterList/FilterList";
import { FilterListInput } from "./generic/FilterList/FilterListInput";
import { FilterListToggleShowClosedCampaignsButton } from "./FilterListToggleShowClosedCampaignsButton";
import OptoutContainer from "./OptoutScreen/OptoutContainer";
import ActiveCampaignAnalyticsPage from "./ActiveCampaignAnalyticsPage";
import ConnectedAggregatorSettingsPage from "./AggregatorSettingsPage/ConnectedAggregatorSettingsPage";
import { FilteredComponentList } from "./generic/FilteredComponentList";

const PAGE_PATHS = {
	users: "/admin/users",
	billing: "/admin/billing",
	twilio: "/admin/twilio",
	orgManagement: "/admin/organizations",
	applyCharge: "/admin/apply-charge",
	panel: "/admin/panel",
	t2w: "/admin/t2w",
	optout: "/admin/optout-list",
	activeCampaigns: "/admin/active-campaigns",
	aggregatorSettings: "/admin/aggregator-settings",
};

const URL_PREFIX = "/dashboard";

class DCM extends Component {
	// Renders the top bar, the side bar and switches on the route to display
	// the correct contents according to which side bar item is chosen.
	render() {
		const {
			userid,
			auth,
			campaigns,
			jwt,
			location,
			match,
			selectedCampaignIndex,
			history,
			dropdownElements,
			pages,
			tools,
			orgHeaders,
		} = this.props;
		// Note: Only show the sidebar if the user has campaigns.
		// A user with no campaigns can log in, change password and log out, but sees nothing.
		const vitalsData = this.props.vitals.data.data;

		const showCampaignPage = pages.includes("campaigns");
		const showOrganizationsPage = pages.includes("organizations");
		const addCampaignButton = tools.includes("ADD_CAMPAIGN");
		const showOrganizationSelect = tools.includes("EDIT_CAMPAIGN_ORG");

		return (
			<React.Fragment>
				<TopBar
					userid={userid}
					history={history}
					match={match}
					selectedCampaign={selectedCampaignIndex}
					dropdownElements={dropdownElements}
				/>

				<div className="main-area">
					<div className="columns full-height">
						<FilterList
							list={campaigns}
							filterKey={["active"]}
							controlComponent={FilterListToggleShowClosedCampaignsButton}
							excludeValue={true}
							render={(statusRenderProps) => (
								<FilterList
									list={statusRenderProps.list}
									filterKey={["name", "campaignid"]}
									placeholder="Filter campaigns"
									controlComponent={FilterListInput}
									render={(renderProps) => (
										<SidebarContainer
											organizations={vitalsData.organizationarray}
											campaigns={renderProps.list}
											campaignsFullLength={campaigns.length}
											render={(renderState) => {
												return (
													<Sidebar
														vitalsData={vitalsData}
														campaignsByOrganization={renderState.campaignsByOrganization}
														campaigns={renderProps.list}
														selectedCampaignId={selectedCampaignIndex}
														showSearchResults={
															statusRenderProps.list.length !== renderProps.list.length
														}
														match={match}
														location={location}
														history={history}
														singleOrgPage={showOrganizationsPage}
														showAddCampaignButton={addCampaignButton}
														showOrganizationSelect={showOrganizationSelect}
													>
														<div className="field-group is-inline">
															{renderProps.searchBar}
														</div>
														{statusRenderProps.searchBar}
													</Sidebar>
												);
											}}
										/>
									)}
								/>
							)}
						/>
						<div className="column has-background-white is-10">
							<div className="notification has-background-white">
								<Switch>
									{showOrganizationsPage && (
										<Route
											path={`${URL_PREFIX}/organizations/:id`}
											render={(route) => (
												<Organization
													vitalsData={vitalsData}
													auth={auth}
													headers={orgHeaders}
													selectedOrganizationId={parseInt(route.match.params.id)}
													campaigns={campaigns.filter(
														(c) => c.organizationid === parseInt(route.match.params.id),
													)}
													{...route}
												/>
											)}
											props={this.props}
										/>
									)}
									{showCampaignPage && (
										<Route
											path={`${URL_PREFIX}/campaigns/:id`}
											render={(props) => {
												// TODO: There seems to be no reason why this can't happen above.

												// If there are no campaigns then a SU,OA should get an add campaign button, a CA should get nothing.
												// Well, a SU will always have (all) campaigns.
												if (campaigns.length == 0) {
													if (addCampaignButton) {
														return (
															<div>
																<AddCampaignModal
																	organizationArray={vitalsData.organizationarray}
																	showOrganizationSelect={showOrganizationSelect}
																	history={history}
																/>
															</div>
														);
													} else {
														return (
															<div className="tile is-ancestor is-vertical box">
																No Campaigns Available - Please talk to your
																organization administrator!
															</div>
														);
													}
												}

												const currentCampaign = campaigns.find(
													(item) => parseInt(props.match.params.id) === item.campaignid,
												);

												let newCampaignId = selectedCampaignIndex;
												if (currentCampaign && currentCampaign.campaignid !== newCampaignId) {
													newCampaignId = currentCampaign.campaignid;
												}

												// Otherwise we get both the CollapsibleSidebar (shows the campaign as a giant button, etc.) and the MainScreenContainer.
												return (
													<CampaignScreenContainer
														campaignName={currentCampaign.name}
														selectedCampaignId={newCampaignId}
														jwt={jwt}
														campaigns={campaigns}
														vitalsData={vitalsData}
														auth={auth}
														tools={tools}
														{...props}
													/>
												);
											}}
										/>
									)}

									<FilteredComponentList
										includeValuesList={dropdownElements.map(
											(item) => `${URL_PREFIX}${item.to.toLocaleLowerCase()}`,
										)}
										filterProp="path"
									>
										<Route
											path={`${URL_PREFIX}${PAGE_PATHS.users}`}
											render={(props) => {
												return (
													<UsersContainer
														campaigns={campaigns}
														permissionlevel={vitalsData.permissionlevel}
														organizationarray={vitalsData.organizationarray}
														userid={auth.userid}
														{...props}
													/>
												);
											}}
										/>
										{/* TODO: REMOVE/REUSE CODE ASSOCIATED WITH THIS OLD BILLING ADMIN PAGE (AKA "Pricing")
                     <Route
                      path={`${URL_PREFIX}${PAGE_PATHS.billing}`}
                      render={(props) => {
                        return campaigns.length == 0 ? (
                          <div>No Campaigns</div>
                        ) : (
                          <BillingContainer
                            campaigns={campaigns}
                            initParams={[campaigns[0].campaignid]}
                          />
                        );
                      }}
                    /> */}
										<Route
											path={`${URL_PREFIX}${PAGE_PATHS.orgManagement}`}
											render={(props) => {
												return (
													<OrganizationsContainer
														campaigns={campaigns}
														organizationarray={vitalsData.organizationarray}
														userid={auth.userid}
														showEditOrganizationButtons={tools.includes(
															"EDIT_ORGANIZATION",
														)}
														{...props}
													/>
												);
											}}
										/>
										<Route path={`${URL_PREFIX}${PAGE_PATHS.panel}`} component={PanelContainer} />
										<Route path={`${URL_PREFIX}${PAGE_PATHS.t2w}`} component={TextToWebContainer} />
										<Route path={`${URL_PREFIX}${PAGE_PATHS.optout}`} component={OptoutContainer} />
										<Route
											path={`${URL_PREFIX}${PAGE_PATHS.activeCampaigns}`}
											component={ActiveCampaignAnalyticsPage}
										/>
										<Route
											path={`${URL_PREFIX}${PAGE_PATHS.aggregatorSettings}`}
											component={ConnectedAggregatorSettingsPage}
										/>
									</FilteredComponentList>

									<Route render={Welcome} />
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default DCM;
