import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getAnalyticsData, clearAnalyticsData } from "../../redux/Analytics/actions";
import { LoadingSpinner } from "../LoadingSpinner";
import SnapshotControlsContainer from "../AnalyticsSnapshots/SnapshotControlsContainer";

import List from "../List";

import { AnalyticsValuesInputClipboard } from "./AnalyticsValuesInputClipboard";
import { BulmaButton } from "../generic/Buttons/BulmaButton";

import { sortObjByName } from "../../util/helper";
import { DownloadAnalyticsData } from "./DownloadAnalyticsData";
import { CAMPAIGNID_HEADER } from "./constants";

const ANALYTICS_SCOPE_CONFIG_MAP = {
	campaign: "campaignid",
	organization: "organizationid",
	active: "active",
};

/**
 * Scope: active, organization, campaign, user
 * groupby: userid, campaignid, organization, none
 * identifierValue: useridValue, campaignidValue, organizationidValue, none
 *
 * Instead of useing dataName and selecting the data in here. Let's just
 * pass the correct data in. That way there's much less generic crap
 *
 */
class AnalyticsTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			identifierValue: null,
		};
	}

	componentDidMount() {
		const { scope } = this.props;
		// Initialize component with correct data
		const value = this.props[ANALYTICS_SCOPE_CONFIG_MAP[scope]];
		this.setState({
			identifierValue: value,
		});
	}

	componentWillUnmount() {
		this.props.clearAnalyticsData();
	}

	componentDidUpdate() {
		const { dataName, data, loading, scope } = this.props;
		const { identifierValue } = this.state;

		if (data[dataName] === undefined && !loading && identifierValue) {
			// Pass the list in so we can fill the missing records in
			this.getData();
		}
	}

	getData = () => {
		const { scope, dataName } = this.props;
		const { identifierValue } = this.state;
		return this.props.getAnalyticsData(dataName, scope, identifierValue);
	};

	render() {
		const {
			data,
			dataName,
			analyticscheckboxconfig,
			showSnapshots,
			showClipboardButton,
			verticalView,
			headerOrder,
		} = this.props;

		let filteredHeaders = [...this.props.headers];

		let snapshotControls = null;
		if (dataName === "campaign") {
			if (showSnapshots) {
				snapshotControls = (
					<SnapshotControlsContainer
						clearAnalyticsData={this.props.clearAnalyticsData}
						getData={this.getData}
					/>
				);
			}

			filteredHeaders = [CAMPAIGNID_HEADER, ...filteredHeaders];
		}

		// Only keep the headers we want.
		filteredHeaders = filteredHeaders.filter(function (i) {
			return analyticscheckboxconfig[i.accessor] == true;
		});

		if (headerOrder) {
			filteredHeaders = filteredHeaders.sort((h1, h2) => {
				return headerOrder.indexOf(h1.accessor) - headerOrder.indexOf(h2.accessor);
			});
		}

		let clipboardButton = null;
		if (data[dataName] && showClipboardButton) {
			clipboardButton = <AnalyticsValuesInputClipboard data={data[dataName]} />;
		}

		return (
			<div>
				<div>
					{snapshotControls}
					<LoadingSpinner loading={this.props.loading} />
					{data[dataName] && (
						<List list={data[dataName].sort(sortObjByName)} headers={filteredHeaders} flip={verticalView} />
					)}
					<br />
					<BulmaButton
						customClass="is-light"
						loading={this.props.loading}
						onClick={this.props.clearAnalyticsData}
					>
						Refresh
					</BulmaButton>
					<DownloadAnalyticsData headers={this.props.headers} data={data[dataName]} />

					{clipboardButton}
				</div>
				<br />
				{this.props.children}
			</div>
		);
	}
}

AnalyticsTable.defaultProps = {
	showSnapshots: true,
	showClipboardButton: true,
	verticalView: true,
};

const mapStateToProps = (state) => ({
	data: state.analytics.current.data,
	loading: state.analytics.current.loading,
	error: state.analytics.current.error,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAnalyticsData,
			clearAnalyticsData,
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsTable);
