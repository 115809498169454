import React, { Component } from "react";
import PropTypes from "prop-types";

/*

campaignid: 209
created_on: "2021-02-17 13:39:52.096676"
status: "asdf"
options:
    remove_opt_outs: false,
    merge_to_existing: false

results:
    actual_rows_added: 5200
    duplicates_removed: 0
    opt_outs: 0
    total_rows_in_file: 5200
*/

/**
 *
 */
export const ProcessedResults = ({
	selectedCampaignId,
	actualRowsAdded,
	duplicatesRemoved,
	optOuts,
	totalRowsInFile,
	status,
	newActualRowsAdded,
	newDuplicatesRemoved,
	newOptOuts,
	newTotalRowsInFile,
	options,
}) => {
	return (
		<div className="content">
			{status === "failed" && "Previous list details: "}
			<br />
			<table>
				<tr>
					<th>info</th>
					<th>Raw File rows</th>
					<th>Duplicates</th>
					<th>Opt-outs</th>
					<th>Actual rows</th>
					<th>Merge with existing</th>
					<th>Remove Opt-outs</th>
				</tr>
				{actualRowsAdded && (
					<tr>
						<td>Existing rows</td>
						<td>{actualRowsAdded}</td>
						<td>{duplicatesRemoved}</td>
						<td>{optOuts}</td>
						<td>{totalRowsInFile}</td>
						<td></td>
						<td></td>
					</tr>
				)}
				{options && (
					<tr>
						<td>
							<b>New file rows</b>
						</td>
						<td>
							<b>{newActualRowsAdded}</b>
						</td>
						<td>
							<b>{newDuplicatesRemoved}</b>
						</td>
						<td>
							<b>{newOptOuts}</b>
						</td>
						<td>
							<b>{newTotalRowsInFile}</b>
						</td>
						<td>
							<b>
								<input
									className="checkbox"
									type="checkbox"
									disabled
									checked={options.merge_to_existing}
								/>
							</b>
						</td>
						<td>
							<b>
								<input
									className="checkbox"
									type="checkbox"
									disabled
									checked={options.remove_opt_outs}
								/>
							</b>
						</td>
					</tr>
				)}
			</table>
		</div>
	);
};

ProcessedResults.propTypes = {
	change: PropTypes.func,
};
