import { fetchRequest } from "../../middleware/fetchMiddleware";
import { FETCH_CLEAR_ERROR_MESSAGE } from "../FetchResponseManager/actions";
import { setCampaignDataUpdated } from "../SelectedCampaign/actions";

export const GET_BANDWIDTH_REGISTRATION = "GET_BANDWIDTH_REGISTRATION";
export const GET_ALL_BANDWIDTH_REGISTRATIONS = "GET_ALL_BANDWIDTH_REGISTRATIONS";
export const BANDWIDTH_BRANDS = "BANDWIDTH_BRANDS";
export const REGISTER_OUTGOING_LINES = "REGISTER_OUTGOING_LINES";
export const REGISTER_CAMPAIGN = "REGISTER_CAMPAIGN";
export const GET_CAMPAIGN_REGISTRATION = "GET_CAMPAIGN_REGISTRATION";
export const UPDATE_BANDWIDTH_REGISTRATION = "UPDATE_BANDWIDTH_REGISTRATION";
export const CREATE_BANDWIDTH_REGISTRATION = "CREATE_BANDWIDTH_REGISTRATION";
export const ASSIGN_BANDWIDTH_REGISTRATION_TO_CAMPAIGN = "ASSIGN_BANDWIDTH_REGISTRATION_TO_CAMPAIGN";
export const UNASSIGN_BANDWIDTH_REGISTRATION_FROM_CAMPAIGN = "UNASSIGN_BANDWIDTH_REGISTRATION_TO_CAMPAIGN";
export const CREATE_NEW_BANDWIDTH_REGISTRATION = "CREATE_NEW_BANDWIDTH_REGISTRATION";
export const GET_BW_REGISTRTIONS_ASSIGNED_TO_CAMPAIGN = "GET_BANDWIDTH_REGISTRTIONS_ASSIGNED_TO_CAMPAIGN";
export const DELETE_BANDWIDTH_REGISTRATION = "DELETE_BANDWIDTH_REGISTRATION";
export const PURCHASE_BANDWIDTH_LINES = "PURCHASE_BANDWIDTH_LINES";
export const RELEASE_BANDWIDTH_LINES = "RELEASE_BANDWIDTH_LINES";
export const LINK_REGISTRATION = "LINK_REGISTRATION";

/**
 * Wrapper to refresh the registration data after actions that change them
 *
 * @param {*} action The action that will be dispatched before the refresh
 * @param {*} args Allows user to pass args to refresh so that we can refresh selective data. E.G.
 * args = [false, null]  ===> activeOnly=false , camapignid=null
 * or to get only active registrations
 * args = [true, null]  ===> activeOnly=true , camapignid=null
 * or only a campaign
 * args = [false, 99]  ===> activeOnly=false , camapignid=99
 * @returns Promise
 */
function refreshBandwidthRegistrationsAfterAction(action, args = [false, null]) {
	return (dispatch) => {
		return dispatch(action).then(() => dispatch(getAllBandwidthRegistrations.apply(null, args)));
	};
}

export function releaseBandwidthLinesFromSettings(bandwidthSettingsId) {
	return refreshBandwidthRegistrationsAfterAction(
		fetchRequest(
			RELEASE_BANDWIDTH_LINES,
			"DELETE",
			`/aggregator/settings/${bandwidthSettingsId}/numbers`, // FIXME: This endpoint returns 500
		),
	);
}

export function purchaseBandwidthLines(bandwidthSettingsId, numberOfLines) {
	return refreshBandwidthRegistrationsAfterAction(
		fetchRequest(
			PURCHASE_BANDWIDTH_LINES,
			"PUT",
			`/aggregator/settings/${bandwidthSettingsId}/numbers`, // BUG: Why does purchasing lines fix the empty data??
			{
				amount: Number(numberOfLines),
			},
		),
	);
}

export function deleteBandwidthRegistration(bwSettingsId) {
	return refreshBandwidthRegistrationsAfterAction(
		fetchRequest(DELETE_BANDWIDTH_REGISTRATION, "DELETE", `/aggregator/settings/${bwSettingsId}/registration`),
	);
}

export function getBandwidthRegistrationsAssignedToCampaign(campaignid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest(
				GET_BW_REGISTRTIONS_ASSIGNED_TO_CAMPAIGN,
				"GET",
				`/aggregator/settings?campaignid=${campaignid}`,
			),
		);
	};
}

export function createNewBandwidthRegistration(data) {
	return refreshBandwidthRegistrationsAfterAction(
		fetchRequest(CREATE_NEW_BANDWIDTH_REGISTRATION, "PUT", "/aggregator/settings/submitRegistration", data),
	);
}

export function unassignBandwidthRegistration(bwSettingsId, campaignId) {
	return refreshBandwidthRegistrationsAfterAction(
		fetchRequest(
			UNASSIGN_BANDWIDTH_REGISTRATION_FROM_CAMPAIGN,
			"DELETE",
			`/aggregator/settings/${bwSettingsId}/campaign/${campaignId}`, // TODO: Make this endpoint. Currently, the endpoint unassigns one campaign at a time
		),
	);
}

export function assignBandwidthRegistrationToCampaign(bwSettingsId, campaignId, refreshArgs = {}) {
	// The args is an object so there's no confusion on which id is which
	return refreshBandwidthRegistrationsAfterAction(
		fetchRequest(
			ASSIGN_BANDWIDTH_REGISTRATION_TO_CAMPAIGN,
			"PUT",
			`/aggregator/settings/${bwSettingsId}/campaign/${campaignId}`,
		),
		refreshArgs,
	);
}

export function createBandwidthRegistration(data) {
	return refreshBandwidthRegistrationsAfterAction(
		fetchRequest(CREATE_BANDWIDTH_REGISTRATION, "POST", "/aggregator/registration?type=bandwidth", data),
	);
}

/**
 *
 * @param {int} bwSettingsId The ID of the agregator settings (not the bandwidth registration id)
 * @param {object} data Data matching the full Bandwidth registration object
 * @param {boolean} withBWRefresh Toggle to re-fetch registration data from Bandwidth after it is updated
 * @returns
 */
export function updateBandwidthRegistration(bwSettingsId, data, withBWRefresh = false) {
	return (dispatch) => {
		return dispatch(
			refreshBandwidthRegistrationsAfterAction(
				fetchRequest(
					UPDATE_BANDWIDTH_REGISTRATION,
					"POST",
					`/aggregator/settings/${bwSettingsId}/registration`, //FIXME: this endpoint returns a 500 `AttributeError: 'BandwidthClientMocker' object has no attribute 'update_registration'`
					data,
				),
			),
		).then(() => {
			// This is necessary when we re-display the registration data after updating it (e.g. in Settings > Bandwidth Settings)
			if (withBWRefresh) {
				return dispatch(getBandwidthRegistrationById(bwSettingsId));
			}
		});
	};
}

export function linkExistingRegistration(registrationId) {
	return refreshBandwidthRegistrationsAfterAction(
		fetchRequest(LINK_REGISTRATION, "POST", "/aggregator/settings/addRegistration", {
			registration_id: registrationId,
		}),
	);
}

export function getAllBandwidthRegistrations(activeOnly = false, campaignid = null) {
	let searchOptions = "";
	if (activeOnly || campaignid !== null) {
		searchOptions = "?";
		searchOptions += activeOnly ? "status=REGISTRATION_AVAILABLE" : "";
		searchOptions += campaignid !== null ? `campaignid=${campaignid}` : "";
	}

	return (dispatch) =>
		dispatch(fetchRequest(GET_ALL_BANDWIDTH_REGISTRATIONS, "GET", `/aggregator/settings${searchOptions}`)).then(
			(data) => data,
		);
}

/*
 * This function is used to get the registration data for a specific registration
 *
 * @param {*} bwSettingsId The id of the settings items of the registration
 */

export function getBandwidthRegistrationById(bwSettingsId) {
	return (dispatch) =>
		dispatch(
			fetchRequest(GET_BANDWIDTH_REGISTRATION, "GET", `/aggregator/settings/${bwSettingsId}/registration`),
		).then((data) => data.campaign);
}

export function linkRecipientsToOutgoingLines(campaignid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("LINK_RECIPIENTS", "POST", "/bandwidth/linkRecipientsToOutgoingLines", {
				campaignid: campaignid,
			}),
		).then(() => {
			dispatch(setCampaignDataUpdated(true));
		});
	};
}

export function registerBandwidthOutgoingLines(campaignid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest(REGISTER_OUTGOING_LINES, "POST", "/bandwidth/registerPhones", {
				campaignid: campaignid,
			}),
		);
	};
}

export function releaseBandwidthLines(campaignid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("RELEASE_LINES", "POST", "/releaseLinesFromSubAccount", {
				campaignid: campaignid,
			}),
		);
	};
}

export function registerBandwidthCampaign(campaignid, registrationPayload) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("BANDWIDTH_CAMPAIGN_REGISTRATION", "POST", "/bandwidth/registerCampaign", {
				campaignid: campaignid,
				...registrationPayload,
			}),
		).then(() => {
			dispatch(setCampaignDataUpdated(true));
		});
	};
}

export function getBrands() {
	return (dispatch) => {
		return dispatch(fetchRequest(BANDWIDTH_BRANDS, "POST", "/bandwidth/brands", {}));
	};
}

export function clearRegisterLinesErrorMsg() {
	return (dispatch) => {
		return dispatch({
			type: FETCH_CLEAR_ERROR_MESSAGE,
			requestName: REGISTER_OUTGOING_LINES,
		});
	};
}

export function getBandwidthRegistration(campaignid) {
	return (dispatch) => {
		return dispatch(fetchRequest(GET_CAMPAIGN_REGISTRATION, "POST", `/bandwidth/${campaignid}/registration`, {}));
	};
}

export function updateBandwidthCampaignRegistration(campaignid, registrationPayload) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("UPDATE_BANDWIDTH_CAMPAIGN_REGISTRATION", "POST", "/bandwidth/updateCampaign", {
				campaignid: campaignid,
				...registrationPayload,
			}),
		).then(() => {
			dispatch(setCampaignDataUpdated(true));
		});
	};
}
