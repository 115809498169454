import React from "react";
/**
 * Returns a filtered list of it's own children based on whether they are included in it's render list
 * @param {Array} includeValuesList the valid values for the filter prop. Will render if value is included in list.
 * @param {*} filterProp the prop whose value will determine if child is rendered or not.
 */
export const FilteredComponentList = ({ children, includeValuesList, filterProp }) => {
	const childrenArray = React.Children.toArray(children);
	return childrenArray.filter((item) => includeValuesList.includes(item.props[filterProp]));
};
