import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ModalContainer, { ModalBody, ModalHeader, ModalFooter } from "./ModalContainer";

import { validateNameString } from "../../util/helper";
import { saveCampaignName } from "../../redux/SelectedCampaign/actions";

//----------------------------------------------------------------------------------------------------
// RenameModal
//
// The modal for renaming the campaign.
// The rename modal receives the following props:
//
// updateCampaign       - Update a campaign.
// selectedCampaignData - The data associated to the current campaign.
// show                 - Whether the modal should be visible or not.
// toggleRenameModal    - To toggle visibility of the modal.

class RenameModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			campaignName: this.props.name,
			open: false,
			valid: true,
			loading: false,
		};

		this.adminChangedName = this.adminChangedName.bind(this);
		this.toggleOpen = this.toggleOpen.bind(this);
		this.saveCampaignName = this.saveCampaignName.bind(this);
	}
	s;

	toggleOpen() {
		this.setState({
			open: !this.state.open,
		});
	}

	adminChangedName(event) {
		this.setState({
			campaignName: event.target.value,
			valid: validateNameString(event.target.value, this.props.campaigns, this.props.campaignid),
		});
	}

	saveCampaignName() {
		if (this.state.valid && !this.state.loading) {
			this.props.saveCampaignName(this.state.campaignName);

			this.setState({ loading: true });
		}
	}

	render() {
		const { campaignName, buttonClass } = this.props;
		return (
			<ModalContainer name={"Rename"} customButtonClass={buttonClass}>
				{(modalState) => (
					<React.Fragment>
						<ModalHeader>
							Rename Campaign: &nbsp;
							<strong>{campaignName}</strong>
						</ModalHeader>
						<ModalBody>
							<div className="field">
								<label className="label">New Name</label>
								<div className="control">
									<input
										className="input"
										type="text"
										onChange={this.adminChangedName}
										value={this.state.campaignName}
									/>
								</div>
								<p className={"help" + (this.state.valid ? " is-success" : " is-danger")}>
									{this.state.valid ? "OK" : "invalid name"}
								</p>
							</div>
						</ModalBody>
						<ModalFooter {...modalState}>
							<a
								className={"button is-info " + (this.state.loading && "is-loading")}
								onClick={this.saveCampaignName}
							>
								Save
							</a>
						</ModalFooter>
					</React.Fragment>
				)}
			</ModalContainer>
		);
	}
}
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			saveCampaignName: (newName) => saveCampaignName(newName),
		},
		dispatch,
	);
export default connect(null, mapDispatchToProps)(RenameModal);
