import React from "react";
import { Notification } from "./Notification";

export const WarningNotification = ({ condition, children }) => {
	if (condition) {
		return <Notification logLevel="warning">{children}</Notification>;
	}

	return null;
};
