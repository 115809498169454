import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updateCampaignFetchData } from "../../redux/SelectedCampaign/actions";
import ConfirmActionModal from "../modals/ConfirmActionModal";

function ToggleNewConversations({ campaignid, assignOpenRecipients, updateCampaignFetchData }) {
	const modalMessage = assignOpenRecipients
		? // COMBAK: Make sure this message is what we actually want to display
			"Are you sure you want to disable new conversations? This will prevent texters from initiating new conversations and only allow them to respond to existing conversations. This will also disable quotas for this campaign."
		: "Are you sure you want to enable new conversations? This will allow texters to initiate new conversations and will enable quotas for this campaign.";

	return (
		<ConfirmActionModal
			title="Confirm Action"
			message={modalMessage}
			actionName={`${assignOpenRecipients ? "Disable" : "Enable"} New Conversations`}
			buttonClass={`button ${assignOpenRecipients ? "is-danger" : "is-primary"} is-fullwidth`}
			actionCallback={() =>
				updateCampaignFetchData(campaignid, {
					assign_open_recipients: !assignOpenRecipients,
				})
			}
		/>
	);
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			updateCampaignFetchData,
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(ToggleNewConversations); // Exporting the connected component
