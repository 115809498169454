import React from "react";
import PropTypes from "prop-types";

export const BulmaButtonProper = ({ loading, error, onClick, customClass, children, ...props }) => (
	<>
		<button className={`button ${customClass} ${loading ? "is-loading" : ""}`} onClick={onClick} {...props}>
			{children}
		</button>
		{error && <p className="help">An error occurred trying to complete this action</p>}
	</>
);

BulmaButtonProper.propTypes = {
	customClass: PropTypes.string,
	loading: PropTypes.bool,
	error: PropTypes.bool,
};
