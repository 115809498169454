import React from "react";

import AnalyticsOverviewCards from "./AnalyticsOverview.cards";

import List from "../List";
import { LoaderAndErrorPrompt } from "../higher-order/ComponentWithLoadingAndError";

const ANALYTICS_OVERVIEW_HEADERS = [
	{
		header: "Metric",
		accessor: "metric",
	},
	{
		header: "Value",
		accessor: "value",
	},
];

export default function AnalyticsOverview({ data = null, loading = false, error = null }) {
	const {
		closed: textCompletes,
		web_closed: webCompletes,
		start: numberOfAttempted,
		contact: totalRecipients,
		percentcomplete,
		percentwebcomplete,
		percentt2wconversion,
		percentstarted,
		percentengaged,
		percentterminated,
		percenterrors,
		total_errors,
		total_segments,
		agentHours,
		smsperagenthour,
		cost_per_complete,
		currentSpend,
		spendLimit,
	} = data || {};

	// The webMetrics that are removed from the overview for non-TextToWeb surveys
	const webMetics = {
		webCompletes: "Completes (web)",
		percentwebcomplete: "% Complete (web)",
		percentt2wconversion: "% Converted (text > web)",
	};

	// Analytics rows for the overview tab
	const overviewData = !data
		? []
		: [
				{ metric: "Completes (text)", value: textCompletes },
				{ metric: webMetics.webCompletes, value: webCompletes },
				{ metric: "Attempted", value: numberOfAttempted },
				{ metric: "Total Segments", value: total_segments },
				{ metric: "Agent Hour #", value: agentHours },
				{ metric: "% Engage", value: percentengaged },
				{ metric: "% Terminate", value: percentterminated },
				{ metric: "% Complete (text)", value: percentcomplete },
				{
					metric: webMetics.percentwebcomplete,
					value: percentwebcomplete,
				},
				{
					metric: webMetics.percentt2wconversion,
					value: percentt2wconversion,
				},
				{ metric: "% Started", value: percentstarted },
				{ metric: "Total Recipients", value: totalRecipients },
				{ metric: "SMS per Agent Hour", value: smsperagenthour },
				{ metric: "Total Spend", value: currentSpend },
				{ metric: "Total errors", value: total_errors },
				{ metric: "Spend per complete", value: cost_per_complete },
			];

	// Remove web-related data on non-TextToWeb surveys
	const isTextToWeb = webCompletes > 0;
	let filteredListData = overviewData;
	if (!isTextToWeb) {
		filteredListData = overviewData.filter((obj) => !Object.values(webMetics).includes(obj.metric));
	}

	return (
		<div className="analytics-overview">
			<LoaderAndErrorPrompt loading={loading} error={error}>
				<AnalyticsOverviewCards
					isTextToWeb={isTextToWeb}
					percentcomplete={percentcomplete}
					percentwebcomplete={percentwebcomplete}
					currentSpend={currentSpend}
					spendLimit={spendLimit}
					percentstarted={percentstarted}
					percentengaged={percentengaged}
					percenterrors={percenterrors}
				/>
				<div className="columns">
					<div className="column is-half is-offset-one-quarter">
						<List
							isNarrow={false}
							list={filteredListData}
							headers={ANALYTICS_OVERVIEW_HEADERS}
							className="fullwidth-body"
						/>
					</div>
				</div>
			</LoaderAndErrorPrompt>
		</div>
	);
}
