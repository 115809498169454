// This is the button that shows the campaign within a row of campaigns.
// If the user for this row is either a SU or OA then the button has no delete function.

import React from "react";

import RemoveUserFromCampaign from "./modals/RemoveUserFromCampaign";
import AddUserToCampaignModal from "./modals/AddUserToCampaignModal";

import { Labels } from "./generic/Labels";
import { Label } from "./generic/Label";
import { USER_ROLES_LOOKUP_TABLE, USER_ROLES } from "../util/constants";

/**
 * NOTE: Since this component deals with Other users permissionlevel, it's fine to reference it
 * directly.
 */
export const UserCampaignLabels = ({
	userPermissionLevel,
	campaigns,
	userCampaigns,
	addUserToCampaign,
	removeUserFromCampaign,
	...other
}) => {
	const userPermissionString = USER_ROLES_LOOKUP_TABLE[userPermissionLevel];
	// NOTE: This looks incorrect! I believe it's meant to also be campaign and callcenter
	// const showRemoveButton = userPermissionLevel == 2 || userPermissionLevel == 3;
	const showButtons =
		userPermissionString == USER_ROLES.campaignAdmin || userPermissionString == USER_ROLES.callCenterAdmin;

	return (
		<div>
			<Labels className="is-pulled-left is-marginless">
				{userCampaigns.map((item, i) => (
					<Label key={i} className="is-medium is-rounded is-info">
						{item.name}
						{showButtons && (
							<RemoveUserFromCampaign
								campaignName={item.name}
								campaignid={item.campaignid}
								loading={removeUserFromCampaign.loading}
								{...other}
							/>
						)}
					</Label>
				))}
			</Labels>
			{showButtons && (
				<span className="is-pulled-right">
					<AddUserToCampaignModal
						userPermissionLevel={userPermissionLevel}
						userCampaigns={userCampaigns}
						campaigns={campaigns}
						loading={addUserToCampaign.loading}
						{...other}
					/>
				</span>
			)}
		</div>
	);
};
