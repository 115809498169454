import { fetchRequest } from "../middleware/fetchMiddleware";

export function vitalsDataPost() {
	return (dispatch, getState) => {
		return dispatch(
			fetchRequest("VITALS", "POST", "/retrieveAllVitals", {
				userid: getState().auth.userid,
			}),
		);
	};
}

export function deleteOrganization(orgid) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("DELETE_ORG", "POST", "/deleteorganization", {
				organizationid: orgid,
			}),
		);
	};
}

export function renameOrganization(orgid, newName) {
	return (dispatch) => {
		return dispatch(
			fetchRequest("RENAME_ORG", "POST", "/renameorganization", {
				organizationid: orgid,
				organizationname: newName,
			}),
		);
	};
}

export function userRequestedDownload(campaignid) {
	return (dispatch) => {
		dispatch({ type: "UPDATE_USER_DOWNLOAD_REQUESTS", campaignid });
	};
}
