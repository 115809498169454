import { loadStateReducer } from "../../util/reusableReducers";
import { NAVIGATION_PREFIX } from "./types";

const navigationReducer = loadStateReducer({
	[NAVIGATION_PREFIX + "_IS_LOADING"]: "loading",
	[NAVIGATION_PREFIX + "_HAS_ERROR"]: "error",
	[NAVIGATION_PREFIX + "_GET_SUCCESS"]: "success",
});

export default navigationReducer;
