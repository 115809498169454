import React from "react";

import RenameModal from "./modals/RenameModal";
import CopyCampaignModal from "./modals/CopyCampaignModal";
import ConfirmActionModal from "./modals/ConfirmActionModal";
import PauseResumeCampaignModal from "./modals/PauseResumeCampaignModal";
import CampaignResultsModal from "./CampaignResults/CampaignResultsModal";
import { Checkbox, WarningCheckbox } from "./generic/Checklist/Checkbox";
import { campaignReadyStates } from "../util/helper";
export const CampaignTools = ({
	name,
	campaignid,
	campaigns,
	active,
	history,
	jwt,
	vitals,
	exports: fileExports,

	requestCampaignLaunch,
	deleteCampaign,
	deactivateCampaign,
	enterSandboxMode,
	exitSandboxMode,
	setCampaignArchiveStatus,

	startdate,
	enddate,
	subtwiliosid,
	script,
	prompt,
	login,
	listlength,
	billingData,
}) => {
	// Set campaignReady values
	let campaignReady = campaignReadyStates({
		script,
		prompt,
		login,
		listlength,
	});
	let campaignSpendStatus;
	let campaignSpendPercentage;
	if (billingData) {
		campaignSpendPercentage = Math.round(
			(billingData.campaignCurrentTotalSpend / billingData.campaignSpendLimit) * 100,
		);
		campaignSpendStatus =
			billingData.campaignCurrentTotalSpend > billingData.campaignSpendLimit ? "is-danger" : "is-success";
	}

	// Set the status message
	let statusElement = null;
	const dateFormatOpts = {
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	};
	switch (active) {
		case "notyet":
			statusElement = <span>Not Yet Launched</span>;
			break;
		case "sandbox":
			statusElement = <span className="has-text-primary">Sandbox Mode</span>;
			break;
		case "prelaunch":
			statusElement = <span className="has-text-success">Launch Approval Pending</span>;
			break;
		case "active":
			const startDateStr = new Date(startdate).toLocaleString("en-US", dateFormatOpts);
			statusElement = (
				<>
					<span className="has-text-success">Launched </span>on {startDateStr}{" "}
				</>
			);
			break;
		case "paused":
			statusElement = <span className="has-text-danger">Paused</span>;
			break;
		case "deactivated":
			const endDateStr = new Date(startdate).toLocaleString("en-US", dateFormatOpts);
			statusElement = (
				<>
					<span className="has-text-danger">Closed </span>on {endDateStr}{" "}
				</>
			);
			break;
		case "archive":
			statusElement = (
				<>
					<span className="has-text-danger">Ready to Archive</span>
				</>
			);
			break;
		case "archived":
			statusElement = <>Archived</>;
			break;
		default:
			statusElement = null;
			break;
	}

	return (
		<React.Fragment>
			<div className="tile is-child">
				<h1 className="title is-4 has-text-centered">Campaign Status: {statusElement}</h1>
			</div>

			<div className="tile is-parent">
				{/* Spend Status + Pre-launch Checklist */}
				<div className="tile is-parent is-vertical">
					{billingData && active !== "notyet" && active !== "prelaunch" && (
						<div className="tile is-child">
							<div>
								<label className="label">Budget Status</label>
								<div className="billing-status">
									<p className="">{campaignSpendPercentage}% of budget spent</p>
									<progress
										className={`progress ${campaignSpendStatus}`}
										value={billingData.campaignCurrentTotalSpend}
										max={billingData.campaignSpendLimit}
									></progress>
								</div>
							</div>
						</div>
					)}

					<div className="tile is-child">
						<div>
							<label className="label ">Pre-launch Checklist</label>
							<div className="mb-5">
								<Checkbox
									checked={script && script.length > 0}
									description={<strong>Create Script</strong>}
								/>
								<Checkbox
									checked={prompt && prompt.length > 0}
									description={<strong>Create Prompts</strong>}
								/>
								<Checkbox
									checked={login && login.length > 0}
									description={<strong>Create Agent Logins</strong>}
								/>
								<Checkbox checked={listlength} description={<strong>Create Recipients</strong>} />
								{listlength === 1 && (
									<WarningCheckbox description={`Only ${listlength} recipient has been uploaded`} />
								)}
								{1 < listlength && listlength < 10 && (
									<WarningCheckbox description={`Only ${listlength} recipients have been uploaded`} />
								)}
							</div>
						</div>

						<div className="buttons">
							{active === "notyet" && (
								<React.Fragment>
									<ConfirmActionModal
										itemName={name}
										actionName="launch campaign"
										actionCallback={requestCampaignLaunch}
										buttonClass={`button is-success ${!campaignReady.includes("launch") ? " is-outlined" : ""}`}
										disabled={!campaignReady.includes("launch")}
									/>
									<ConfirmActionModal
										itemName={name}
										actionName="Enter sandbox mode"
										actionCallback={enterSandboxMode}
										buttonClass={`button is-info ${!campaignReady.includes("sandbox") ? " is-outlined" : ""}`}
										disabled={!campaignReady.includes("sandbox")}
									/>
								</React.Fragment>
							)}
							{active === "sandbox" && (
								<React.Fragment>
									<ConfirmActionModal
										itemName={name}
										actionName="launch campaign"
										actionCallback={requestCampaignLaunch}
										buttonClass="button is-success is-outlined"
										disabled={true}
									/>
									<ConfirmActionModal
										itemName={name}
										actionName="Exit sandbox mode"
										actionCallback={exitSandboxMode}
										buttonClass="button is-info"
									/>
								</React.Fragment>
							)}
							{active === "prelaunch" && (
								<button className="button is-success is-outlined" disabled>
									...Launch Approval Pending
								</button>
							)}
						</div>
					</div>
				</div>

				<div className="tile is-child">
					{/* UN-ARCHIVE CAMPAIGN*/}
					{active === "archive" && (
						<div className="buttons">
							<div className="box">
								<label className="label mb-5">
									<span className="has-text-danger">Archiving Tomorrow at 3am</span>
								</label>
								<p className="mb-3">
									Campaigns cannot be un-archived. Analytics and recipient lists are{" "}
									<strong>not available </strong>
									for archived campaigns. If you are uncertain if this campaign should be archived,
									please cancel archiving and contact our team.
								</p>
								<button
									className={"button is-warning"}
									onClick={() => setCampaignArchiveStatus("deactivated")}
								>
									Cancel Archiving
								</button>
							</div>
						</div>
					)}

					<div className="buttons">
						<label className="label mb-5">Campaign Actions</label>

						{/* ARCHIVE CAMPAIGN */}
						{active === "deactivated" && (
							<button
								className={"button is-warning is-fullwidth"}
								onClick={() => setCampaignArchiveStatus("archive")}
							>
								Schedule for Archival
							</button>
						)}

						{/* END/PAUSE CAMPAIGN */}
						{(active === "active" || active === "paused") && (
							<>
								<ConfirmActionModal
									itemName={name}
									actionName="end campaign"
									actionCallback={deactivateCampaign}
									buttonClass="button is-danger is-fullwidth"
								/>
								<PauseResumeCampaignModal
									name={name}
									campaignid={campaignid}
									status={active}
									subtwiliosid={subtwiliosid}
								/>
							</>
						)}

						{active === "notyet" && (
							<RenameModal
								buttonClass="button is-light is-fullwidth"
								campaigns={campaigns}
								campaignid={campaignid}
								campaignName={name}
							/>
						)}

						<CopyCampaignModal
							campaignName={name}
							organizations={vitals.organizationarray}
							buttonClass="button is-light is-fullwidth"
						/>
						{active !== "notyet" && (
							<CampaignResultsModal
								selectedCampaignId={campaignid}
								useExisting={false}
								fileExports={fileExports}
								buttonClass="button is-warning is-fullwidth"
							/>
						)}

						{active !== "active" && (
							<ConfirmActionModal
								itemName={name}
								actionName="delete campaign"
								actionCallback={() => {
									deleteCampaign(history);
								}}
								buttonClass="button is-danger is-outlined is-fullwidth"
							/>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
