import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { BulmaButton } from "../generic/Buttons/BulmaButton";

const saveFieldsToClipboard = (data) => {
	navigator.permissions
		.query({ name: "clipboard-write" })
		.then((result) => {
			if (result.state == "granted" || result.state == "prompt") {
				let a = createStringFromData(data);

				navigator.clipboard.writeText(a).then(
					function (e) {
						/* clipboard successfully set */
						console.log("Copied to clipboard!!!", e);
					},
					function () {
						/* clipboard write failed */
						console.log("Failed!!!");
					},
				);
			}
		})
		.catch((error) => {
			console.error("Error checking clipboard permissions:", error);
			alert("Your browser does not support clipboard operations.");
		});
};

const createStringFromData = (data) => {
	let fields = ["closed", "agentHours", "start", "total_segments", "outgoing_segments", "optedin"];
	return fields.reduce(
		(t, f, i) => `${t}${data.reduce((r, item, i) => `${r}${item[f]}${i === data.length - 1 ? "\n" : "\t"}`, "")}`,
		"",
	);
};

export const AnalyticsValuesInputClipboard = ({ data }) => {
	return (
		<BulmaButton customClass="is-info" onClick={() => saveFieldsToClipboard(data)}>
			Copy field values to clipboard&nbsp;
			<FontAwesomeIcon icon={faCopy} />
		</BulmaButton>
	);
};

AnalyticsValuesInputClipboard.propTypes = {
	data: PropTypes.array,
};
