import React, { Component } from "react";

import ModalContainer from "../modals/ModalContainer";

export function withLoadingAndError(WrappedComponent) {
	return class extends Component {
		render() {
			const { loading, error } = this.props;

			return (
				<div style={{ overflow: "hidden" }}>
					{loading && (
						<div
							style={{
								backgroundColor: "rgba(300, 300, 300, 0.7)",
								width: "calc(100% - 20px)",
								height: "calc(100% - 20px)",
								position: "absolute",
								zIndex: 50,
							}}
						>
							<div
								className="loader "
								style={{
									margin: "0 auto",
									width: "100px",
									height: "100px",
									position: "fixed",
									left: "50%",
									top: "50%",
									zIndex: 50,
								}}
							/>
						</div>
					)}

					<WrappedComponent {...this.props} />
				</div>
			);
		}
	};
}

export class LoaderAndErrorPrompt extends Component {
	render() {
		const { loading, error, children } = this.props;
		if (error) {
			return (
				<ModalContainer open={true} customButtonClass="is-hidden">
					{(modalState) => <div className="notification is-danger">Something went wrong!</div>}
				</ModalContainer>
			);
		}
		if (loading) {
			return (
				<div
					style={{
						backgroundColor: "rgba(300, 300, 300, 0.7)",
						width: "100%",
						height: "100%",
						position: "absolute",
						zIndex: 50,
					}}
				>
					<div
						className="loader "
						style={{
							margin: "0 auto",
							width: "100px",
							height: "100px",
							position: "fixed",
							left: "50%",
							top: "50%",
							zIndex: 50,
						}}
					/>
				</div>
			);
		}

		return this.props.children;
	}
}
