import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Billing from "./Billing";

class BillingContainer extends Component {
	constructor(props) {
		super(props);
		// see PropTypes below
	}

	render() {
		const { userPermissions, selectedCampaignData, billingData, loading, error } = this.props;

		if (error) {
			return <div>Error loading billing data.</div>;
		}
		return (
			<Billing
				loading={loading}
				billingData={billingData}
				userPermissions={userPermissions}
				selectedCampaignData={selectedCampaignData}
			/>
		);
	}
}

BillingContainer.propTypes = {
	selectedCampaignData: PropTypes.object.isRequired,
	userPermissions: PropTypes.array,
	billingData: PropTypes.object,
	loading: PropTypes.bool,
	error: PropTypes.bool,
};

const mapStateToProps = function (state) {
	return {
		billingData: state.selectedCampaign.billingData.data,
		loading: state.selectedCampaign.billingData.loading,
		error: state.selectedCampaign.billingData.error,
	};
};

export default connect(mapStateToProps)(BillingContainer);
