import React from "react";
import { getAllBandwidthRegistrations, getBrands } from "../../redux/Bandwidth/actions";
import { withAsyncCall } from "../higher-order/AsyncWrapper";
// import { BandwidthRegistrationForm } from "./BandwidthRegistrationForm";
import { LoaderAndErrorPrompt } from "../higher-order/ComponentWithLoadingAndError";
import BandwidthRegistrationFormV2 from "./BandwidthRegistrationFormV2";

const BandwidthRegistrationFormWithBrands = (props) => {
	return (
		<LoaderAndErrorPrompt {...props}>
			{props.brands && <BandwidthRegistrationFormV2 {...props} brands={props.brands} />}
		</LoaderAndErrorPrompt>
	);
};

const mapStateToProps = (state) => ({
	loading: state.bandwidth.brands.loading,
	error: state.bandwidth.brands.error,
	brands: state.bandwidth.brands.data,
	registrations: state.bandwidth.getAllBandwidthRegistrations.data,
});

export default withAsyncCall(BandwidthRegistrationFormWithBrands, getBrands, mapStateToProps);
