import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CampaignTools } from "./CampaignTools";

import { requestCampaignLaunch, closeCampaign, setCampaignArchiveStatus } from "../redux/SelectedCampaign/actions";

function Tools({
	campaigns,
	selectedCampaignData,
	selectedCampaignId,
	billingData,
	saveCampaignName,
	deleteCampaign,
	closeCampaign,
	requestCampaignLaunch,
	enterSandboxMode,
	exitSandboxMode,
	history,
	auth,
	vitals,
	setCampaignArchiveStatus,
}) {
	return (
		<div className="tile is-ancestor">
			<div className="tile is-vertical is-parent">
				<CampaignTools
					campaigns={campaigns}
					deleteCampaign={deleteCampaign}
					requestCampaignLaunch={requestCampaignLaunch}
					deactivateCampaign={closeCampaign}
					saveCampaignName={saveCampaignName}
					enterSandboxMode={enterSandboxMode}
					exitSandboxMode={exitSandboxMode}
					setCampaignArchiveStatus={setCampaignArchiveStatus}
					history={history}
					jwt={auth.jwt}
					vitals={vitals}
					billingData={billingData}
					{...selectedCampaignData}
				/>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			requestCampaignLaunch: () => requestCampaignLaunch(),
			closeCampaign: () => closeCampaign(),
			setCampaignArchiveStatus: (status) => setCampaignArchiveStatus(status),
		},
		dispatch,
	);

const mapStateToProps = function (state) {
	return {
		billingData: state.selectedCampaign.billingData.data,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
