import React, { Component } from "react";
import { connect } from "react-redux";
import ModalContainer, { ModalHeader, ModalBody, ModalFooter } from "../modals/ModalContainer";
import { bindActionCreators } from "redux";
import { startCampaignResultsDownload, getUrlAndDownloadFile } from "../../redux/GlobalNotifications/actions";
import List from "../List";
import { ExistingExportLink } from "./ExistingExportLink";

const EXISTING_EXPORTS_HEADERS = [
	{
		header: "File",
		title: "File",
		accessor: "last_results_filename",
	},
	{
		header: "Created On",
		title: "Export Created On",
		accessor: "last_results_timestamp",
	},
	{
		header: "",
		title: "Download",
		accessor: "link",
	},
];

class CampaignResultsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			list: [],
			filterOpen: false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (!state.list.length && props.fileExports) {
			return { list: props.fileExports };
		}

		return null;
	}

	processList = (list) => {
		return list.map(this.addDownloadLinkToExportItem);
	};

	addDownloadLinkToExportItem = (item, i) => {
		return {
			...item,
			link: (
				<ExistingExportLink callback={() => this.props.getUrlAndDownloadFile(this.props.selectedCampaignId)} />
			),
		};
	};

	updateDownloadOptions = (optionKey) => (e) => {
		this.setState({ [optionKey]: e.target.checked });
	};

	render() {
		const { selectedCampaignId, useExisting, fileExports } = this.props;
		const loading = false;
		// const {fileExports} = this.state.fileExports;

		return (
			<ModalContainer name={"Campaign Results"} customButtonClass="button is-light is-fullwidth">
				{(modalState) => (
					<React.Fragment>
						<ModalHeader>Campaign Results</ModalHeader>
						<ModalBody>
							<div>
								Processing campaign results can take several minutes to complete. A notification will
								pop up when the file is ready for download.
								{fileExports && fileExports.length > 0 && (
									<>
										Existing exports
										<br />
										<List
											headers={EXISTING_EXPORTS_HEADERS}
											list={this.processList(this.state.list)}
										/>
									</>
								)}
								<div className="control">
									<input
										type="checkbox"
										className="checkbox"
										checked={this.state.filterOpen}
										onChange={this.updateDownloadOptions("filterOpen")}
									/>
									<label>&nbsp;Include only attempted records.</label>
								</div>
							</div>
						</ModalBody>
						<ModalFooter {...modalState}>
							{useExisting && (
								<a
									className={"button is-info" + (loading ? " is-loading" : "")}
									onClick={() => {
										this.props.getUrlAndDownloadFile(selectedCampaignId);
										modalState.toggleOpen();
									}}
								>
									Download File
								</a>
							)}
							<a
								className={"button is-info" + (loading ? " is-loading" : "")}
								onClick={() => {
									this.props.startCampaignDownload(selectedCampaignId, this.state.filterOpen);
									modalState.toggleOpen();
								}}
							>
								Start Export
							</a>
						</ModalFooter>
					</React.Fragment>
				)}
			</ModalContainer>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			startCampaignDownload: (campaignid, filterOpen) => startCampaignResultsDownload(campaignid, filterOpen),
			getUrlAndDownloadFile: (campaignid, useExisting) => getUrlAndDownloadFile(campaignid, useExisting),
		},
		dispatch,
	);

CampaignResultsModal.defaultProps = {
	useExisting: false,
	fileExports: [],
};

export default connect(undefined, mapDispatchToProps)(CampaignResultsModal);
