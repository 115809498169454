import React from "react";
import SimpleNotification from "./NotificationTypes/SimpleNotification";
import CampaignPausedNotification from "./NotificationTypes/CampaignPausedNotification";
import TextToWebReadyNotification from "./NotificationTypes/TextToWebReadyNotification";
import CampaignResultsReadyNotification from "./NotificationTypes/CampaignResultsReadyNotification";
import OutgoingLinesTransfer from "./NotificationTypes/OutgoingLinesTransfer";
import UploadEvent from "./NotificationTypes/UploadEvent";
import UpdateCampaignDataError from "./NotificationTypes/UpdateCampaignDataError";
import OptoutDownloadReadyNotification from "./NotificationTypes/OptoutDownloadReadyNotification";
import ErrorMessageNotification from "./NotificationTypes/ErrorMessageNotification";
import RegistrationUpdateNotification from "./NotificationTypes/RegistrationUpdateNotification";

export const NotificationFactory = (props) => {
	switch (props.type) {
		case "SIMPLE_MESSAGE":
			return <SimpleNotification {...props} />;
		case "DOWNLOAD":
			return <CampaignResultsReadyNotification {...props} />;
		case "T2W_EXPORT":
			return <TextToWebReadyNotification {...props} />;
		case "OPTOUT_EXPORT":
			return <OptoutDownloadReadyNotification {...props} />;
		case "CAMPAIGN_PAUSED":
			return <CampaignPausedNotification {...props} />;
		case "OUTGOING_LINES_TRANSFER":
			return <OutgoingLinesTransfer {...props} />;
		case "UPLOAD":
			return <UploadEvent {...props} />;
		case "UPDATE_CAMPAIGN_DATA_ERROR":
			return <UpdateCampaignDataError {...props} />;
		case "ERROR_MESSAGE":
			return <ErrorMessageNotification {...props} />;
		case "REGISTRATION_UPDATE":
			return <RegistrationUpdateNotification {...props} />;
		default:
			return null;
	}
};
