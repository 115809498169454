import { combineReducers } from "redux";
import { loadStateReducer } from "../../util/reusableReducers";
import {
	GENERATE_STRATA_FROM_FILE_HAS_ERROR,
	GENERATE_STRATA_FROM_FILE_IS_LOADING,
	GENERATE_STRATA_FROM_FILE_POST_SUCCESS,
} from "./types";

const quotasFileParseReducer = loadStateReducer({
	[GENERATE_STRATA_FROM_FILE_IS_LOADING]: "loading",
	[GENERATE_STRATA_FROM_FILE_HAS_ERROR]: "error",
	[GENERATE_STRATA_FROM_FILE_POST_SUCCESS]: "success",
});

export default combineReducers({
	quotasFileParse: quotasFileParseReducer,
});
