// Simply get's the settings data for a specific campaign and passes it down
import React from "react";
import { connect } from "react-redux";

import BandwidthTools from "./BandwidthTools";
import { bindActionCreators } from "redux";

import {
	assignBandwidthRegistrationToCampaign,
	getAllBandwidthRegistrations,
	unassignBandwidthRegistration,
	getBandwidthRegistrationById,
	updateBandwidthRegistration,
} from "../../redux/Bandwidth/actions";

function ConnectedSettingsData({ script, campaignid, bw_registration_id, ...props }) {
	// Since we only want the available registrations, pass true
	const getAvailableRegistrations = () => {
		return props.getAllBandwidthRegistrations(true);
	};

	const getAssignedRegistration = () => {
		const res = props.getAllBandwidthRegistrations(false, campaignid);
		return res;
	};

	// Fetch registration data from Bandwidth for assigned registration
	const getAssignedRegDataFromBandwidth = (settingsId) => {
		return props.getBandwidthRegistrationById(settingsId);
	};

	return (
		<BandwidthTools
			{...props}
			script={script}
			campaignid={campaignid}
			bwSettingsId={bw_registration_id}
			getAvailableRegistrations={getAvailableRegistrations}
			getAssignedRegistration={getAssignedRegistration}
			assignedRegDataFromBandwidth={props.selectedAggregatorRegData} // the data for the assigned registration, fetched from Bandwidth API
			getAssignedRegDataFromBandwidth={getAssignedRegDataFromBandwidth} // fn to dispatch action to fetch assigned registration data from Bandwidth API
			postAssignedRegDataFromBandwidth={props.updateBandwidthRegistration} // fn to dispatch action to update registration data
		/>
	);
}

const mapStateToProps = (state) => {
	const { getAllBandwidthRegistrations } = state.bandwidth;
	const assignedAggregatorRegData = state.bandwidth.getBandwidthRegistrationById.data
		? state.bandwidth.getBandwidthRegistrationById.data.campaign
		: null;
	const script = state.selectedCampaign.selectedCampaign.selectedCampaignData.script;
	const sampleRecipients = state.selectedCampaign.selectedCampaign.selectedCampaignData.list;

	return {
		getRegState: getAllBandwidthRegistrations,
		selectedAggregatorRegData: assignedAggregatorRegData,
		script,
		sampleRecipients: Object.values(sampleRecipients),
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAllBandwidthRegistrations,
			assignBandwidthRegistrationToCampaign,
			unassignBandwidthRegistration,
			getBandwidthRegistrationById,
			updateBandwidthRegistration,
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedSettingsData);
