import React from "react";

import GenerateLoginsModal from "../modals/GenerateLoginsModal";
import List from "../List";
import LogoutAllModal from "./LogoutAllModal";
import FilterList from "../generic/FilterList/FilterList";
import { FilterListInput } from "../generic/FilterList/FilterListInput";
import { campaignReadyStates } from "../../util/helper";

const headers = [
	{
		header: "",
		title: "Status",
		accessor: "status",
	},
	{
		header: "User ID",
		title: "User ID",
		accessor: "id",
	},
	{
		header: "Password",
		title: "Password",
		accessor: "password",
	},
	{
		header: "Login",
		title: "Login",
		accessor: "login",
	},
	{
		header: "Actions",
		title: "actions",
		accessor: "actions",
	},
	{
		header: "Outgoing last 10m",
		title: "Outgoing in the last 10 minutes",
		accessor: "outgoing",
	},
	{
		header: "Incoming last 10m",
		title: "Incoming in the last 10 minutes",
		accessor: "incoming",
	},
];

export const Agents = ({
	active,
	login,
	selectedCampaignId,
	changeParams,
	numberOfLoggedInAgents,
	selectedCampaignData,
}) => {
	const campaignRunning = active === "active" || active === "sandbox";

	return (
		<>
			<div className="tile is-child">
				<h1 className="title is-4 has-text-centered">AGENT TOOLS</h1>
			</div>

			<FilterList
				list={login}
				filterKey={["id"]}
				filterPredicate={(item, query) => String(item.props.userid).toLocaleLowerCase().includes(query)}
				placeholder="Filter agent logins"
				controlComponent={FilterListInput}
				render={(renderProps) => (
					<>
						<div class="tile is-parent">
							{
								// If campaign is not running, only show Agent count and explaination text
								!campaignRunning && (
									<div class="tile is-child colum is-8">
										<div class="mr-2">
											<label
												className={`label mb-5 ${login.length > 0 ? "has-text-success" : ""}`}
											>
												Agent Logins Generated: {login.length}
											</label>

											{
												// Display message after logins are generated
												login.length > 0 && (
													<p>
														Login credentials are displayed when campaign is running or in
														"Sandbox" mode.
													</p>
												)
											}
											{(() => {
												switch (active) {
													case "notyet":
														return (
															<p className="mt-5">
																The current campaign status is: Not Yet Launched
															</p>
														);
													case "prelaunch":
														return (
															<p className="mt-5">
																The current campaign status is: Awaiting Launch Approval
															</p>
														);
													case "paused":
														return (
															<p className="mt-5">
																The current campaign status is: Paused
															</p>
														);
												}
											})()}
										</div>
									</div>
								)
							}

							{campaignRunning && (
								<div class="tile is-child colum is-8">
									<div className="mr-2" style={{ maxWidth: "500px" }}>
										{renderProps.searchBar}
									</div>
									<br />
									<p>Agents logged in: {numberOfLoggedInAgents}</p>
									<br />
								</div>
							)}

							<div class="tile is-child colum is-4">
								<div class="buttons">
									{active !== "deactivated" && (
										<GenerateLoginsModal existingLogins={login} campaignid={selectedCampaignId} />
									)}

									{
										// only show Refresh & Logout buttons when campaign is ready
										campaignRunning && (
											<>
												<a
													className="button is-fullwidth is-light"
													onClick={() => changeParams([selectedCampaignId])}
												>
													Refresh
												</a>

												<LogoutAllModal
													campaignid={selectedCampaignId}
													refreshCallback={changeParams}
												/>
											</>
										)
									}
								</div>
							</div>
						</div>

						{campaignRunning && (
							<div class="column is-12">
								<List headers={headers} list={renderProps.list} />
							</div>
						)}
					</>
				)}
			/>
			<div className="buttons is-right"></div>
		</>
	);
};
