import React from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ExistingExportLink = ({ callback }) => (
	<a onClick={callback}>
		<FontAwesomeIcon icon={faDownload} />
	</a>
);
