import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Redirect, Link } from "react-router-dom";

import { bindActionCreators } from "redux";
import { verifyToken, sendSMSToken } from "../../redux/Auth/actions";
import { getCookies, removeCookieList, isEmail } from "../../util/helper";

/**
 * Login Modal
 */
class AuthyLoginModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: true,
			previousResult: "",
		};

		this.attemptLogin = this.attemptLogin.bind(this);
		this.toggleOpen = this.toggleOpen.bind(this);
		this.requestSMSToken = this.requestSMSToken.bind(this);
	}

	toggleOpen() {
		this.setState({ isOpen: !this.state.isOpen });
	}

	componentDidMount() {
		// Look for cookies
		const { userid, jwt } = getCookies();
		if (
			jwt !== "" &&
			userid !== "" &&
			jwt !== "crap" &&
			userid !== "crap" &&
			jwt !== undefined &&
			userid !== undefined
		) {
			// Get the vitals and issue a success.
			// this.props.vitalsDataPost(userid);
		} else {
			removeCookieList(["jwt", "userid"]);
		}
	}

	toggleTokenLengthWarning = (show) => {
		this.setState({
			warning: show ? "Invalid token length" : undefined,
		});
	};

	attemptLogin(e) {
		// Testing authentication.
		// If we authenticate we get a jwt.
		e.preventDefault();
		let email, userid;

		// This way the login that isn't used will simply be undefined
		if (isEmail(this.login.value)) email = this.login.value;
		else userid = this.login.value;

		email = this.props.auth.email;

		const token = this.token.value;

		if (token.length !== 7) {
			this.toggleTokenLengthWarning(true);
			return;
		}

		if (!this.props.loading) {
			this.props.verifyToken(email, userid, token);
			this.toggleTokenLengthWarning(false);
		}
	}

	requestSMSToken(e) {
		// Testing authentication.
		// If we authenticate we get a jwt.
		e.preventDefault();
		let email, userid;

		// This way the login that isn't used will simply be undefined
		if (isEmail(this.login.value)) email = this.login.value;
		else userid = this.login.value;

		if (!this.props.loading) {
			this.props.sendSMSToken(email, userid);
		}
	}

	render() {
		const { userid, email, matchedPassword, jwt, error, loading, message, errMsg } = this.props.auth;

		if (!matchedPassword || !email || email === "") {
			return <Redirect to={"/login"} />;
		}

		if (userid !== "" && jwt !== "" && !error && !loading) {
			return <Redirect to={this.state.from || "/"} />;
		}

		return (
			<Modal isOpen={this.state.isOpen} className={"modal" + (this.state.isOpen ? " is-active" : "")}>
				<div className="modal-background" />
				<form onSubmit={this.attemptLogin}>
					<div className="modal-card">
						<header className="modal-card-head">Log In with Authy</header>
						<section className="modal-card-body">
							<div className="field">
								<label className="label">Userid or Email</label>
								<input
									className="input"
									type="text"
									name="email"
									placeholder={email}
									disabled
									ref={(login) => {
										this.login = login;
									}}
								/>
							</div>
							<div className="field">
								<label className="label">Token</label>
								<input
									className="input"
									type="text"
									pattern="\d*"
									maxLength={7}
									name="token"
									placeholder="authy token"
									autoFocus={true}
									ref={(token) => {
										this.token = token;
									}}
								/>
							</div>

							{error && <div className="notification is-danger">Login Failed: {errMsg}</div>}

							{this.state.warning && <p className="help is-danger">{this.state.warning}</p>}
						</section>
						<footer className="modal-card-foot">
							<button className={"button is-info" + (loading ? " is-loading" : "")} type="submit">
								Log In
							</button>
							<button
								className={"button is-info" + (loading ? " is-disabled" : "")}
								type="button"
								onClick={this.requestSMSToken}
							>
								Send SMS Token
							</button>
							<p className="help has-text-success">
								<Link to="/login" className="has-text-success">
									Password Login
								</Link>
							</p>
						</footer>
					</div>
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			verifyToken,
			sendSMSToken: (email, userid) => sendSMSToken(email, userid),
		},
		dispatch,
	);

export default connect(null, mapDispatchToProps)(AuthyLoginModal);
