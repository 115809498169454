const initialState = {
	notifications: [],
	lastId: 0,
};

function mainReducer(state = initialState, action) {
	switch (action.type) {
		case "ADD_NOTIFICATION":
			const id = state.lastId + 1;
			return {
				...state,
				notifications: [...state.notifications, { ...action.notification, id: id }],
				lastId: id,
			};
		case "CLEAR_NOTIFICATION":
			let notifications = [...state.notifications];
			let index = notifications.findIndex((n) => n.id === action.notification.id);

			notifications.splice(index, 1, action.notification);
			return { ...state, notifications: notifications };
		default:
			return state;
	}
}

export default mainReducer;
