import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Quotas } from "./Quotas";
import {
	retrieveQuotasData,
	updateQuotasData,
	generateStrataTable,
	clearQuotasData,
} from "../../redux/SelectedCampaign/actions";
import { DEFAULT_HEADERS, getSortFunction, STRATA_ID_HEADER } from "./quotasUtil";

class QuotasContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			headers: [...DEFAULT_HEADERS],
			localData: [],
			checkboxes: [],
			dirty: false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (state.dirty) {
			return null;
		}

		if (
			(state.update || props.fields.length > 0 || state.previousId !== props.selectedCampaignId) &&
			props.data &&
			!props.loading &&
			!props.error
		) {
			const quotas = props.data.data;
			let data = [...quotas.data];

			return {
				// At some point we'll need the to check the saved values first.
				checkboxes: props.fields.map((f) => ({
					name: f,
					value: quotas ? quotas.fields.includes(f) : false,
				})),
				localData: data,
				localTotals: quotas.totals,
				localFields: quotas.fields,
				headers: [
					STRATA_ID_HEADER,
					...quotas.fields.map((f) => ({
						header: f,
						title: f,
						accessor: f,
					})),
					...DEFAULT_HEADERS,
				],
				update: false,
				previousId: props.selectedCampaignId,
				updateLock: props.data.updating,
			};
		}

		if (props.error) {
			return {
				checkboxes: props.fields.map((f) => ({
					name: f,
					value: false,
				})),
				localData: [],
				headers: [...DEFAULT_HEADERS],
			};
		}

		return {
			checkboxes: props.fields.map((f) => ({
				name: f,
				value: false,
			})),
		};
	}

	componentDidMount() {
		const { data, loading, error, retrieveQuotasData, selectedCampaignId } = this.props;

		if (!loading && !error && (!data || selectedCampaignId !== data.data.campaignid)) {
			retrieveQuotasData(selectedCampaignId);
		}
	}

	componentDidUpdate() {
		const { data, loading, error, retrieveQuotasData, selectedCampaignId } = this.props;

		if (!loading && !error && (!data || selectedCampaignId !== data.data.campaignid)) {
			retrieveQuotasData(selectedCampaignId);
		}
	}

	componentWillUnmount() {
		this.setState({
			headers: [...DEFAULT_HEADERS],
			localData: [],
			checkboxes: [],
			dirty: false,
			update: false,
		});
	}

	onCheckboxChange = (e, index) => {
		const newCheckboxes = this.state.checkboxes;
		newCheckboxes[index].value = e.target.checked;
		this.setState({ checkboxes: newCheckboxes, dirty: true });
	};

	onValueChange = (e, index, accessor) => {
		const newData = this.state.localData;
		newData[index][accessor] = parseFloat(e.target.value);
		this.setState({ localData: newData, dirty: true });
	};

	onTotalsTargetChange = (e) => {
		this.setState({
			localTotals: {
				...this.state.localTotals,
				target_complete: parseFloat(e.target.value),
			},
			dirty: true,
		});
	};

	processData = () => {
		const { localData: data, localTotals: totals } = this.state;
		const isDeactivatedCampaign = this.props.active === "deactivated";
		const length = data.length;
		if (!length) {
			return data;
		}

		const totalCompletes = totals.closed;
		return data.map((item, index) => {
			let currentProportion = Math.round((item.closed / totalCompletes) * 100);

			return {
				...item,
				currProp: (isFinite(currentProportion) ? currentProportion : 0).toLocaleString(2) + "%",
				target_complete: isDeactivatedCampaign ? (
					<span>{item.target_complete}</span>
				) : (
					<input
						value={item.target_complete}
						type="number"
						onChange={(e) => this.onValueChange(e, index, "target_complete")}
					/>
				),
				assignment_weight: isDeactivatedCampaign ? (
					<span>{item.assignment_weight}</span>
				) : (
					<>
						<input
							value={item.assignment_weight}
							type="number"
							step="0.01"
							onChange={(e) => this.onValueChange(e, index, "assignment_weight")}
						/>
						&nbsp;%
					</>
				),
			};
		});
	};

	processCompletes = () => {
		const { localData: data, localTotals: totals } = this.state;
		const isDeactivatedCampaign = this.props.active === "deactivated";

		if (!totals) return;

		return {
			...totals,
			assignment_weight: data.reduce((total, item) => total + item.assignment_weight, 0),
			target_complete: isDeactivatedCampaign ? (
				<span>{totals.target_complete}</span>
			) : (
				<input value={totals.target_complete} type="number" onChange={this.onTotalsTargetChange} />
			),
		};
	};

	generateStrataWrapper = () => {
		const fields = this.state.checkboxes.filter((item) => item.value).map((item) => item.name);

		if (fields.length === 0) return false;

		this.props
			.generateStrataTable(
				this.props.selectedCampaignId,
				this.state.checkboxes.filter((item) => item.value).map((item) => item.name),
			)
			.then(() => {
				this.props.retrieveQuotasData(this.props.selectedCampaignId);
			})
			.then(() => {
				this.setState({
					update: true,
					dirty: false,
				});
			});
	};

	update = () => {
		const data = {
			data: this.state.localData,
			fields: this.state.checkboxes.filter((item) => item.value).map((item) => item.name),
			totals: this.state.localTotals,
		};

		this.props
			.updateQuotasData(this.props.selectedCampaignId, data)
			.then(() => {
				this.props.retrieveQuotasData(this.props.selectedCampaignId);
			})
			.then(() => {
				this.setState({
					update: true,
					dirty: false,
				});
			});
	};

	clearStrataTable = () => {
		this.props
			.updateQuotasData(this.props.selectedCampaignId)
			.then(() => {
				this.props.retrieveQuotasData(this.props.selectedCampaignId);
			})
			.then(() => {
				this.setState({
					update: true,
					dirty: false,
				});
			});
	};

	setFieldAll = (fieldName, newValue = 0) => {
		const { localData } = this.state;
		const newData = this.state.localData.map((item) => ({
			...item,
			[fieldName]: newValue,
		}));

		this.setState({ localData: newData, dirty: true });
	};

	render() {
		return (
			<Quotas
				active={this.props.active}
				headers={this.state.headers}
				checkboxes={this.state.checkboxes}
				strataTable={this.processData()}
				fields={this.state.localFields}
				recipientListLength={this.props.recipientListLength}
				totals={this.processCompletes()}
				onCheckboxChange={this.onCheckboxChange}
				generateStrataTable={this.generateStrataWrapper}
				update={this.update}
				clearStrataTable={this.clearStrataTable}
				loading={this.props.loading}
				error={this.props.error}
				errorMessage={this.props.errorMessage}
				errorCode={this.props.errorCode}
				refresh={this.props.clearQuotasData}
				trgtPropWarning={this.state.trgtPropWarning}
				setFieldAll={this.setFieldAll}
				updateLock={this.state.updateLock}
				userPermissions={this.props.userPermissions}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	const { error, errorMessage, errorCode, loading, data } = state.selectedCampaign.quotas;
	return {
		recipientListLength: state.selectedCampaign.selectedCampaign.selectedCampaignData.listlength,
		// error: state.selectedCampaign.quotas.error,
		// errorCode: state.selectedCampaign.quotas.errorCode,
		// errorMessaage: state.selectedCampaign.quotas.errorCode,
		// loading: state.selectedCampaign.quotas.loading,
		// data: state.selectedCampaign.quotas.data,
		error,
		errorCode,
		errorMessage,
		loading,
		data,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			retrieveQuotasData: (campaignid) => retrieveQuotasData(campaignid),
			updateQuotasData: (campaignid, data) => updateQuotasData(campaignid, data),
			generateStrataTable: (campaignid, selectedFields) => generateStrataTable(campaignid, selectedFields),
			clearQuotasData: () => clearQuotasData(),
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(QuotasContainer);
