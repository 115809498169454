import React from "react";
import { QuestionWrapper } from "./QuestionWrapper";

export const Terminating = ({ ...props }) => {
	const { q, handleCheckboxChange, qIndex, disablePanelCheckbox = true, showPanelQuestions = false } = props;
	return (
		<QuestionWrapper {...props}>
			{showPanelQuestions && (
				<label className="checkbox" disabled={disablePanelCheckbox}>
					<input
						type={"checkbox"}
						checked={q.gotopanel}
						name="gotopanel"
						disabled={disablePanelCheckbox}
						onChange={(e) => handleCheckboxChange(qIndex, e)}
					/>
					Route to Panel Opt In
				</label>
			)}
		</QuestionWrapper>
	);
};
