import { combineReducers } from "redux";
import { loadStateReducer } from "../../util/reusableReducers";

const initialState = {
	loading: false,
	error: false,
};

function mainReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
	}
}

const downloadPanel = loadStateReducer({
	DOWNLOAD_PANEL_IS_LOADING: "loading",
	DOWNLOAD_PANEL_HAS_ERROR: "error",
	DOWNLOAD_PANEL_POST_SUCCESS: "success",
});

export default combineReducers({ downloadPanel, mainReducer });
