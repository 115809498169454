import React, { Component } from "react";
// import "bulma/css/bulma.css";

/**
 * A generic list component that uses Bulma columns and displays items
 *
 *  TODO: Ideally the list would work like this:
 *    <List
 *      headers={ ["name", "age", {"controls" : <Buttons>}]}
 *      list={[{name: "james", age: 11, controls: ["edit", "delete"]]}
 *    />
 *
 *  ALSO TODO: allow for summation rows
 *
 * Props:
 * list, {array} The list of items to display. Each item should be an object with keys to match the columns
 * headers, {array} List of headers to display
 */

/**
 * @typedef {Object} ListHeader
 * @property {string} header - The header text to display
 * @property {string} accessor - The key in the item object to display
 */

/**
 * A generic list component that uses Bulma columns and displays items
 * @prop {array} list - The list of items to display. Each item should be an object with keys to match the columns
 * @prop {ListHeader[]} headers - List of headers to display
 * @prop {boolean} flip - Whether to flip the table
 * @prop {string} id - Optional id for the table
 * @prop {string} className - Optional class name for the table
 * @prop {function} sort - Optional sort function for the table
 */

class List extends Component {
	constructor(props) {
		super(props);

		this.state = {
			headers: [],
		};
	}

	static defaultProps = {
		capitalizeHeaders: false,
		headers: [],
		list: [],
		flip: false,
		className: "",
		id: null,
		isNarrow: true,
	};

	setSortFunction = (fnc) => {
		this.setState({
			sortFnc: fnc,
		});
	};

	filterHeaders = (headers) => {
		// Filter out headers with different names and same accessors, or if the header doesn't have an accessor
		// Only the first header with a unique accesssor will be added. Also make the first letter of `header.header` uppercase
		const filteredHeaders = new Map();
		for (const header of headers) {
			if (!header.accessor || filteredHeaders.get(header.accessor)) continue;

			const isHeaderValid = typeof header.header == "string" || typeof header.header == "number";
			const headerLabel = String(header.header);
			if (this.props.capitalizeHeaders)
				header.header = isHeaderValid ? headerLabel[0].toUpperCase() + headerLabel.slice(1) : "";
			else header.header = isHeaderValid ? headerLabel : "";

			filteredHeaders.set(header.accessor.toLowerCase(), header);
		}
		return Array.from(filteredHeaders.values());
	};

	render() {
		const { list, headers, flip, id, className, isNarrow } = this.props;

		// Filter out headers with different names and same accessors. Only the first header with a unique accesssor will be added
		const filteredHeaders = this.filterHeaders(headers);

		if (this.state.sortFnc) {
			list.sort(this.state.sortFnc);
		}

		/*
     	If the table is flipped `finalHeaderCells` will stay empty. The headers are added at the beginnning of each row instead
		*/
		let finalRows = [];
		if (flip) {
			// This map consists of `header.accessor` as keys, and an array of `td` or `th` elements as their value
			const rowMap = new Map();
			filteredHeaders.forEach((header, i) => {
				const onClickCallback = header.sort ? () => this.setSortFunction(header.sort) : header.onClick;

				// Insert a `th` at the beginning of the row instead of adding the headers to the thead if the table is flipped horizontally
				rowMap.set(header.accessor, [
					createTableCell({
						content: onClickCallback ? <a onClick={onClickCallback}>{header.header}</a> : header.header,
						className: "sticky",
						key: i,
						thInstead: true,
					}),
				]);
			});

			list.forEach((item, listIdx) => {
				filteredHeaders.forEach((header, headerIdx) => {
					rowMap.get(header.accessor).push(
						createTableCell({
							content: item[header.accessor],
							key: `${listIdx}-${headerIdx}`,
						}),
					);
				});
			});

			for (const [key, cells] of rowMap) {
				finalRows.push(<tr key={`${key}-row`}>{cells}</tr>);
			}
		} else {
			const headerCells = filteredHeaders.map((header, i) => {
				const onClickCallback = header.sort ? () => this.setSortFunction(header.sort) : header.onClick;
				return createTableCell({
					content: onClickCallback ? <a onClick={onClickCallback}>{header.header}</a> : header.header,
					key: i,
					thInstead: true,
				});
			});
			finalRows.push(<tr key="headers">{headerCells}</tr>);
			finalRows.push(
				...list.map((item, listIdx) => {
					const cells = filteredHeaders.map((header, headerIdx) => {
						return createTableCell({
							content: item[header.accessor],
							key: `${listIdx}-${headerIdx}`,
						});
					});
					return <tr key={listIdx}>{cells}</tr>;
				}),
			);
		}
		return (
			<div className="table-outer">
				<table
					className={`table is-bordered is-striped ${
						isNarrow ? "is-narrow" : ""
					} is-hoverable is-fullwidth ${className}`}
					id={id}
				>
					<tbody>{finalRows}</tbody>
				</table>
			</div>
		);
	}
}

/**
 * @param {Object} args
 * @param {String|JSX.Element} args.content Content to be rendered inside the cell
 * @param {String} args.className Class name for the cell
 * @param {String} args.key React key for the cell
 * @param {Boolean} args.thInstead Whether to create a `th` element instead of a `td` element
 * @returns {JSX.Element} The table cell
 */
function createTableCell({ content = "", className = "", key = null, thInstead = false }) {
	if (content === null) {
		content = "";
	}

	if (thInstead) {
		return (
			<th key={key} className={className}>
				{content}
			</th>
		);
	}
	return (
		<td key={key} className={className}>
			{content}
		</td>
	);
}

export default List;
