import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SnapshotControls } from "./SnapshotControls";
import { LoaderAndErrorPrompt } from "../higher-order/ComponentWithLoadingAndError";
import { retrieveAnalyticsSnapshots, selectSnapshotRange } from "../../redux/Analytics/actions";

class SnapshotControlsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			start: "start",
			end: "current",
			range: true,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.data) {
			return {
				convertedSnapshotDates: props.data.map((item) => new Date(item.timestamp).toLocaleDateString("en-US")),
			};
		}

		return null;
	}

	componentDidMount() {
		const { data, loading, error } = this.props;
		if (!data && !loading && !error) {
			// Pass the list in so we can fill the missing records in
			this.props.retrieveAnalyticsSnapshots();
		}
	}

	onChange = (e) => {
		this.setState(
			{
				[e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
			},
			() => console.log("New State", this.state),
		);
	};

	applyChange = (e) => {
		let start = this.state.start;

		// If not selecting a range then automatically use the previous timestamp as the start date
		if (!this.state.range) {
			start = this.props.data[this.props.data.length - 1].timestamp;
			if (this.state.end === "current") {
				// Use the last snapshot as the cutoff
				start = this.props.data[this.props.data.length - 1].timestamp;
				console.log("Use last timestamp", start);
			} else {
				// Use the timestamp right before the current one
				start =
					this.props.data[this.props.data.findIndex((item) => item.timestamp === this.state.end)].timestamp;
				console.log("Use previous timestamp", this.props.data, this.state.end);
			}
		}

		console.log("start", start);
		// console.log("end", end);

		// Just use regular analytics data
		if (start === "start" && this.state.end === "current") {
			console.log("Cleared analytics data from Snapshots.");
			return this.props.clearAnalyticsData();
		}

		// If we need current data fetch it then continue
		if (this.state.end === "current") {
			// First get current analytics data
			console.log("Get data from snapshots.");
			return this.props.getData().then(() => {
				console.log("Process data from snapshots from Snapshots.");
				// When that's done we have updated data in the state
				return this.props.selectSnapshotRange(start, this.state.end);
			});
		}

		return this.props.selectSnapshotRange(start, this.state.end);
	};

	render() {
		return (
			<LoaderAndErrorPrompt {...this.props}>
				{this.props.data && (
					<SnapshotControls
						{...this.props}
						convertedSnapshotDates={this.state.convertedSnapshotDates}
						applyChange={this.applyChange}
						onChange={this.onChange}
						range={this.state.range}
					/>
				)}
			</LoaderAndErrorPrompt>
		);
	}
}

const mapStateToProps = (state) => state.analytics.snapshots;

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			retrieveAnalyticsSnapshots: () => retrieveAnalyticsSnapshots(),
			selectSnapshotRange: (start, end) => selectSnapshotRange(start, end),
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotControlsContainer);
