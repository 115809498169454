import React from "react";
import PropTypes from "prop-types";

const JsonEditor = ({ handleChange, script }) => (
	<form>
		<textarea onChange={handleChange} style={{ height: "1000px", width: "100%" }}>
			{JSON.stringify(script, null, 2)}
		</textarea>
	</form>
);

JsonEditor.prototypes = {
	handleChange: PropTypes.func.isRequired,
	script: PropTypes.object.isRequired,
};

export default JsonEditor;
